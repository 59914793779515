import { getUserDataRequest } from 'api/wizard';
import PageActivityIndicator from 'components/common/PageActivityIndicator';
import ErrorModal from 'components/ErrorModal';
import IntegrationsErrorModal from 'components/IntegrationsErrorModal';
import { STORAGE_KEY } from 'helpers/constants';
import { useFetchEmployerData } from 'hooks/useFetchEmployerData';
import { useGlobalStore } from 'hooks/useGlobalStore';
import { USER_SETTINGS } from 'interfaces/settings';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ActionTypes as SettingsActionTypes } from 'reducers/settings';
import { getDataSync, saveStateToStorage } from 'services/asyncStorage';
import { v4 } from 'uuid';
import { SITE } from '../../site.config';
import {
  signInRequest,
  signInViaEmailRequest,
  signOutRequest,
  signUpRequest,
} from '../api/auth';
import { __legacyMakeRequest } from '../api/common';
import {
  getData,
  getHousehold,
  integrations,
  updateHousehold,
} from '../api/selectsmart';
import { useAPI } from '../api/useAPI';
import { normalizeSmartSelectDataFromServer } from '../helpers/common';
import { HouseholdActionTypes } from '../helpers/stateUtil';
import { AuthSignInResponse } from '../interfaces/auth';
import {
  CommonActionTypes,
  Nullable,
  SerializedStore,
} from '../interfaces/common';
import { ActionTypes as AuthActionTypes } from '../reducers/auth';
import { api } from '../selectsmart-api/api';
import {
  getSessionTokenData,
  saveSessionTokenInStorage,
} from '../services/auth';
import { msg } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { useMaybeLingui } from 'hooks/useMaybeLingui';
import { useApp } from 'context/app';
import { useCurrentLocale } from 'I18nCustomProvider';

export type SignInResult = {
  success: boolean;
  lastLocation?: string;
  error?: string | MessageDescriptor;
};

export type SignUpResult = {
  success: boolean;
  error?: string | MessageDescriptor;
};

export const AuthenticationContext = createContext<any>(null);

export const AuthenticationProvider = ({ children }: PropsWithChildren) => {
  const {
    globalState,
    dispatch,
    setupStoreFromStorage,
    initialized: stateInitialized,
  } = useGlobalStore();

  // Set user's language preference
  const { setLocale, localeIsValid, currentLocale } = useCurrentLocale();

  const app = useApp();

  const [criticalAuthError, setCriticalAuthError] =
    useState<Nullable<string | MessageDescriptor>>(null);
  const [integrationsError, setIntegrationsError] = useState(false);

  const { fireRequest: getGuideData } = useAPI(getData);
  const { fireRequest: getHouseholdData } = useAPI(
    getHousehold,
    undefined,
    false,
    (data) => {
      if (data) {
        dispatch({
          type: HouseholdActionTypes.SET_HOUSEHOLD,
          payload: data,
        });
      }
    },
  );
  const { fetchEmployerData } = useFetchEmployerData();
  const [isLoading, updateLoading] = useState(true);
  const initialized = React.useRef(false);

  const initializeUserLocale = useCallback(
    ({ locale }: { locale: string | null }) => {
      // Set user's language preference
      if (locale && localeIsValid(locale)) {
        setLocale(locale);
      }
    },
    [setLocale, localeIsValid],
  );

  const signInFinalize = async (url: string, isSignUp = false) => {
    const { data, error } = await __legacyMakeRequest<AuthSignInResponse>(
      `${url}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (data && !error) {
      const sessionData = {
        session_token: data.session_token,
        expires_at: data.expires_at,
        renew_by: data.renew_by,
      };
      saveSessionTokenInStorage(sessionData);

      // set session in new react-query api
      api.setSession(sessionData);

      dispatch({
        type: AuthActionTypes.SETUP_USER_DATA,
        payload: {
          user: data.user,
          email: data.user.email,
        },
      });

      initializeUserLocale(data.user);

      if (isSignUp) {
        await updateHousehold({
          state_of_residence:
            globalState.apiState.household.state_of_residence ||
            globalState.auth.metadata.default_state_of_residence,
          employer_id: globalState.auth.metadata.employer_id,
          disclosure_accepted_at:
            globalState.apiState.household.disclosure_accepted_at ||
            new Date().toISOString(),
        });
      }
    }
    return { data, error };
  };

  const clearUserData = () => {
    dispatch({
      type: AuthActionTypes.SETUP_USER_DATA,
      payload: {
        user: null,
      },
    });
    localStorage.removeItem(STORAGE_KEY);
  };

  const runIntegrations = async () => {
    try {
      const integrationsResult = await integrations();
      if (!integrationsResult.ok) {
        return false;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Integrations error', e);
      return false;
    }
    return true;
  };

  const signInByToken = async (token: string): Promise<SignInResult> => {
    const response = await signInRequest({
      app_id: SITE.APP_ID,
      client_id: SITE.CLIENT_ID,
      token: token,
    });

    if (response.error) {
      setCriticalAuthError(response.error?.error?.message);
      return {
        success: false,
      };
    }

    if (response.data?.next_step === 'finalize') {
      const finalizeResponse = await signInFinalize(response.data?.next_url);

      if (finalizeResponse?.error) {
        setCriticalAuthError(finalizeResponse.error?.error?.message);
        return {
          success: false,
        };
      } else if (finalizeResponse.data) {
        const integrationsSuccess = await runIntegrations();
        if (!integrationsSuccess) {
          setIntegrationsError(true);
          return {
            success: false,
          };
        }
        // Fetch guide data
        const guideData = await fetchGuideData();
        if (!guideData) {
          setCriticalAuthError(msg`Error fetching guide data`);
          return {
            success: false,
          };
        }
        return {
          success: true,
          lastLocation: guideData.last_location,
        };
      }
    }
    return {
      success: true,
    };
  };

  const signInViaEmail = async (
    email: string,
    reCaptchaToken: string,
    isInvited?: boolean,
  ): Promise<SignInResult> => {
    if (!email) {
      return {
        success: false,
        error: msg`Email or username required`,
      };
    }
    const { error, ok } = await signInViaEmailRequest({
      app_id: SITE.APP_ID,
      client_id: SITE.CLIENT_ID,
      email,
      reCaptchaToken,
    });

    if (!ok) {
      let errorMessage;
      if (error) {
        if (
          (error.error?.type === 'InvalidCredentials' ||
            error.error?.type === 'PaymentRequiredError') &&
          !isInvited
        ) {
          errorMessage = msg`This service is only available through sponsoring employers. Please use the link provided by your employer.`;
        } else if (
          error.error?.type === 'SchemaValidationError' &&
          reCaptchaToken
        ) {
          errorMessage = msg`We had trouble verifying that you weren’t a bot. Please reload this page and try again.`;
        } else {
          errorMessage = error.error?.message;
        }
      } else {
        errorMessage = msg`An unknown signin error occurred`;
      }

      return { success: false, error: errorMessage };
    }

    return { success: true };
  };

  const initializeGuideData = useCallback(
    async (data: any) => {
      if (data) {
        const storageData = getDataSync(STORAGE_KEY);
        const settings = getDataSync(USER_SETTINGS);
        const parsedData: SerializedStore = JSON.parse(storageData || '{}');
        const normalizedData = normalizeSmartSelectDataFromServer(data, {
          ...parsedData,
          settings: JSON.parse(settings || `{}`),
        });
        dispatch({
          type: CommonActionTypes.SETUP_STORE,
          payload: normalizedData,
        });

        // Initialize preferred frequency to pay period if not set
        if (!settings) {
          const client: any = Object.values(data?.people || {}).find(
            (p: any) => p.role === 'client',
          );
          if (client) {
            const payFrequency = data?.incomes?.[client.id]?.pay_frequency;
            if (payFrequency) {
              dispatch({
                type: SettingsActionTypes.SET_FREQUENCY,
                payload: {
                  frequency: payFrequency,
                  isPaycheck: true,
                },
              });
            }
          }
        }

        await fetchEmployerData();
        await getHouseholdData({});
      }
    },
    [dispatch, fetchEmployerData, getHouseholdData],
  );

  const fetchGuideData = async () => {
    const response = await getGuideData({});
    if (response.ok) {
      await initializeGuideData(response.data);
      return response.data;
    }
    return null;
  };

  const signOutUser = async (session_token: string) => {
    const response = await signOutRequest(session_token);

    await saveSessionTokenInStorage(null);

    dispatch({
      type: CommonActionTypes.RESET_STORE,
    });

    return response;
  };

  const signUp = async (
    email: string,
    username?: string,
  ): Promise<SignUpResult> => {
    const { invite_code } = globalState.auth;

    // Force user's locale preference to the invite code locale if it exists
    // or the current browser locale (they changed the locale on the welcome screen)
    const userLocale = globalState.auth.metadata.locale || currentLocale;

    const body = {
      app_id: SITE.APP_ID,
      client_id: SITE.CLIENT_ID,
      invite_code: invite_code as string,
      ...(userLocale ? { locale: userLocale } : {}),
    };

    let response;
    if (username != null && !email) {
      response = await signUpRequest({
        ...body,
        username: username,
      });
    } else {
      response = await signUpRequest({
        ...body,
        email: email,
      });
    }

    if (response.error?.error?.type === 'DuplicateEmail') {
      return {
        success: false,
        error: msg`User already exists with email`,
      };
    }

    const { data, error } = response;
    if (!error && data?.next_step === 'finalize') {
      const response = await signInFinalize(data.next_url, true);
      if (response.error) {
        return {
          success: false,
          error: response.error?.message,
        };
      }
      if (response.data?.user) {
        await runIntegrations();
        await fetchGuideData();
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          error: msg`Error creating new user account`,
        };
      }
    } else if (error) {
      return {
        success: false,
        error: msg`Email address not found. Please use the specific link provided by your employer to access ${app.name}`,
      };
    }
    return {
      success: false,
      // Unknown error
    };
  };

  useEffect(() => {
    async function getUser() {
      const sessionData = await getSessionTokenData();
      if (sessionData?.session_token) {
        const response = await getUserDataRequest(sessionData.session_token);
        if (response.error?.error?.type === 'UnauthorizedError') {
          clearUserData();
          updateLoading(false);
        }
        if (response.data) {
          const payload = {
            user: response.data,
            metadata: {},
          };
          const storageData = getDataSync(STORAGE_KEY);
          if (storageData) {
            let parsedData: SerializedStore;
            try {
              parsedData = JSON.parse(storageData);
              if (parsedData) {
                payload['metadata'] = parsedData.auth?.metadata as any;
              }
            } catch (error) {
              // eslint-disable-next-line no-console
              console.warn(error);
            }
          }

          dispatch({
            type: AuthActionTypes.SETUP_USER_DATA,
            payload,
          });

          initializeUserLocale(payload.user);

          await fetchGuideData();
          updateLoading(false);
        } else {
          updateLoading(false);
        }
      } else {
        clearUserData();
        updateLoading(false);
      }
    }
    async function checkTokenExpireDate() {
      const sessionData = await getSessionTokenData();
      if (
        !sessionData ||
        (sessionData &&
          new Date(sessionData.expires_at).getTime() < new Date().getTime())
      ) {
        saveSessionTokenInStorage(null);
        clearUserData();
      }
    }

    (async () => {
      await checkTokenExpireDate();
      await getUser();
    })();
  }, []);

  useEffect(() => {
    async function trackVisitor() {
      if (!globalState.auth.visitor_id) {
        const storageData = getDataSync(STORAGE_KEY);
        if (storageData) {
          const {
            auth: { visitor_id },
          } = JSON.parse(storageData);
          if (visitor_id) {
            dispatch({
              type: AuthActionTypes.SETUP_USER_DATA,
              payload: {
                visitor_id,
              },
            });

            return;
          }
        }

        const generatedVisitorId = v4();
        dispatch({
          type: AuthActionTypes.SETUP_USER_DATA,
          payload: {
            visitor_id: generatedVisitorId,
          },
        });
        await saveStateToStorage({
          ...globalState,
          auth: {
            ...globalState.auth,
            visitor_id: generatedVisitorId,
          },
        });
      }
    }
    if (stateInitialized) {
      trackVisitor();
    }
  }, [globalState, dispatch, stateInitialized]);

  useEffect(() => {
    if (!initialized.current && !isLoading) {
      if (!globalState.auth.user) {
        setupStoreFromStorage();
      }
      initialized.current = true;
    }
  }, [isLoading, globalState.auth.user, setupStoreFromStorage]);

  const { _ } = useMaybeLingui();

  return (
    <AuthenticationContext.Provider
      value={{
        signUp,
        signInByToken,
        signInViaEmail,
        signOutUser,
        authError: criticalAuthError,
        user: globalState?.auth?.user,
        isAuthenticated: !!globalState?.auth?.user,
      }}>
      {isLoading ? (
        <PageActivityIndicator
          description={_(msg`Authorizing guidance...`)}
          positionKey="global"
        />
      ) : criticalAuthError ? (
        <ErrorModal
          error={_(criticalAuthError)}
          open={!!criticalAuthError}
          onClose={() => {
            // Todo: clear?
            //clearUserData();
            window.location.href = '/';
          }}
        />
      ) : integrationsError ? (
        <IntegrationsErrorModal
          open={integrationsError}
          onClose={() => setIntegrationsError(false)}
        />
      ) : (
        <>{children}</>
      )}
    </AuthenticationContext.Provider>
  );
};
