/* eslint-disable lingui/no-unlocalized-strings */
import { RootState } from '../components/store/Store';
import { serializeStoreToStorage } from '../helpers/common';
import { STORAGE_KEY } from '../helpers/constants';

export function getDataSync(key: string) {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    throw new Error('failed to get item');
  }
}

export async function storeData(key: string, value: object | string | null) {
  try {
    const stringifiedValue =
      typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, stringifiedValue);
  } catch (e) {
    throw new Error('failed to save item');
  }
}

export async function saveStateToStorage(state: RootState, serialize = true) {
  const serializedState = serialize ? serializeStoreToStorage(state) : state;
  await storeData(STORAGE_KEY, serializedState);
}
