/* eslint-disable lingui/no-unlocalized-strings */
import {
  getSessionTokenData,
  saveSessionTokenInStorage,
} from '../services/auth';
import { removeKeys, isExcludedRoute } from 'helpers/common';
import { RouteNames } from 'RouteNames';
import { loadDebugOptions } from 'hooks/useDebug';
import { getTestHeaders } from 'helpers/tests';

let RETRIES = 0;
const MAX_RETRIES = 3;

export type APIResponse<Data> =
  // success response
  | {
      ok: true;
      data: Data;
      error: null;
      status?: number;
    }
  // failure response
  | {
      ok: false;
      data: null;
      error: any;
      status?: number;
    };

type RequestConfigBase = {
  url: string;
  includeAuthToken?: boolean;
  abortSignal?: AbortSignal;
};

type RequestWithoutBodyConfig = RequestConfigBase & {
  body?: never;
  method: 'GET';
};

type RequestWithBodyConfig = RequestConfigBase & {
  body: any;
  method: 'POST' | 'PUT' | 'DELETE' | 'PATCH';
};

const buildRequestOptions = async ({
  method,
  abortSignal,
  includeAuthToken,
  body,
}: RequestWithBodyConfig | RequestWithoutBodyConfig) => {
  let authToken = null;
  if (includeAuthToken) {
    const sessionData = await getSessionTokenData();
    authToken = sessionData?.session_token;
  }

  const headers: {
    Accept: string;
    'content-type': string;
    Authorization?: string;
    'X-On-Date'?: string;
    'X-Test-Id'?: string;
  } = {
    Accept: 'application/json',
    'content-type': 'application/json',
    // eslint-disable-next-line lingui/no-unlocalized-strings
    ...(authToken && { Authorization: `Bearer ${authToken}` }),
  };

  // Custom x-on-date header
  const debugOptions = loadDebugOptions();
  if (
    debugOptions &&
    !!debugOptions.customOnDate &&
    debugOptions.customOnDateEnabled
  ) {
    headers['X-On-Date'] = debugOptions.customOnDate;
  } else if (window.AUTOMATED_TEST_ONDATE) {
    headers['X-On-Date'] = window.AUTOMATED_TEST_ONDATE;
  }

  const testId = await getTestHeaders(body);
  if (testId) {
    headers['X-Test-Id'] = testId;
  }

  body = removeKeys(body, ['source', 'source_id']);

  return {
    method,
    headers,
    ...(body && method !== 'GET' && { body: JSON.stringify(body) }),
    ...(abortSignal && { signal: abortSignal }),
  };
};
const sleep = (m: number) => new Promise((resolve) => setTimeout(resolve, m));

const renewSessionToken = () => {
  if (!isExcludedRoute(window.location.pathname.slice(1))) {
    saveSessionTokenInStorage(null);
    window.location.href = `/${RouteNames.Welcome}`;
  }
};

const excludeFromSessionRenewal = [
  'selectsmart/household',
  'selectsmart/integrations',
];

export async function makeRequest<Response>(
  config: RequestWithBodyConfig | RequestWithoutBodyConfig,
): Promise<APIResponse<Response | null>> {
  const sessionData = await getSessionTokenData();
  if (
    !sessionData?.session_token ||
    new Date(sessionData?.expires_at) < new Date()
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      // eslint-disable-next-line lingui/no-unlocalized-strings
      'Session token expired. Skipping request.',
      config.url,
      config,
    );

    if (!excludeFromSessionRenewal.some((sub) => config.url.includes(sub))) {
      renewSessionToken();
    }

    return {
      ok: true,
      data: null,
      error: null,
    };
  } else {
    try {
      const options = await buildRequestOptions(config);
      const response = await fetch(config.url, options);
      const responseJSON = await response.json();

      if (response.ok) {
        RETRIES = 0;
        return {
          ok: true,
          data: responseJSON,
          error: null,
        };
      } else {
        if (
          (response.status === 401 || response.status === 403) &&
          !response.url.includes('household')
        ) {
          if (RETRIES < MAX_RETRIES) {
            RETRIES += 1;
            await sleep(1000);
            return await makeRequest(config);
          } else {
            RETRIES = 0;
            renewSessionToken();
          }
        }
        return {
          ok: false,
          error: responseJSON || true,
          data: null,
          status: response.status,
        };
      }
    } catch (error) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      throw new Error('Make Request Error');
    }
  }
}

export async function __legacyMakeRequest<Response>(
  url: string,
  params?: RequestInit,
): Promise<APIResponse<Response>> {
  try {
    const response = await fetch(url, params);
    const responseJSON = await response.json();
    if (response.ok) {
      return {
        ok: true,
        data: responseJSON,
        error: null,
      };
    } else {
      // in some cases there is error === null
      return {
        ok: false,
        error: responseJSON || true,
        data: null,
      };
    }
  } catch (error) {
    return {
      ok: false,
      error,
      data: null,
    };
  }
}
