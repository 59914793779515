import React from 'react';
import { RootState, initialState, MergedActions } from './Store';
import { Contribution } from 'interfaces/retirement';

export interface GlobalContextType {
  globalState: RootState;
  sortedPeopleIds: string[];
  initialized: boolean;
  getContribution: (id: string, contribution: Contribution) => number;
  setupStoreFromStorage(): void;
  spouseId?: string;
  spouseGenericLabel: string;
  personLabel: (
    personId: string | null | undefined,
    options?: { useGenericLabel?: boolean },
  ) => string;
  dispatch: React.Dispatch<MergedActions>;
}

export const GlobalContext = React.createContext<GlobalContextType>({
  globalState: initialState,
  initialized: false,
  sortedPeopleIds: [],
  getContribution: () => 0,
  spouseGenericLabel: '',
  personLabel: () => '',
  setupStoreFromStorage: () => null,
  dispatch: () => null,
});
