// Requires crypto.subtle, but this should only be used in playwright
// tests where we have control over the browser version
async function sha256(source: string) {
  const sourceBytes = new TextEncoder().encode(source);
  const digest = await crypto.subtle.digest('SHA-256', sourceBytes);
  const resultBytes = [...new Uint8Array(digest)];
  return resultBytes.map((x) => x.toString(16).padStart(2, '0')).join('');
}

export const getTestHeaders = async (body: any) => {
  let testId = null;
  if (window.AUTOMATED_TEST_ENV) {
    testId = `${window.harContextLocation || 'unknown'}-${
      window.harContextCheckpoint || 0
    }`;
    // Add body data hash
    if (body) {
      const dataHash = await sha256(JSON.stringify(body));
      testId = `${testId}-${dataHash}`;
    }
  }
  return testId;
};
