import { msg } from '@lingui/macro';
import { md } from 'content/types';
import { GuidanceMode } from 'selectsmart-api';

export default {
  title: msg`Sorry, this is probably not the tool for you.`,
  intro: ({
    guidance_mode,
    appName,
  }: {
    guidance_mode: GuidanceMode | null;
    appName: string;
  }) =>
    guidance_mode === 'midyear-update'
      ? md(msg`${appName} is designed to help people optimize their saving strategy
        between retirement, emergencies, and health-related savings and spending
        accounts.`)
      : md(msg`${appName} is designed to help people decide which employer-sponsored
        health plan best fits their needs and to optimize saving for retirement.
        If you are enrolled in Medicare, congratulations, you already have a
        health plan, and you are probably closing in on retirement (if not
        already retired).`),
};
