import { Tracker, defaultPageViewPath, defaultPageViewTitle } from './tracker';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

function gtag(..._args: any[]) {
  window.dataLayer = window.dataLayer ?? [];
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export class GA4Tracker implements Tracker {
  private eventConfig: { [key: string]: any } = {};

  constructor(private options: { measurementId: string }) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${options.measurementId}`;
    script.async = true;
    document.head.insertBefore(script, document.head.firstChild);
    gtag('js', new Date());
    gtag('config', this.options.measurementId, {
      send_page_view: false,
    });
  }

  private internalTrack(params: { [key: string]: any }) {
    // use externalId as userId when present (e.g. Voya Party ID)
    const { externalId, ...rest } = params;
    const paramsWithProperUserId = {
      ...rest,
      userId: externalId || rest.userId,
      event: rest.event,
    };
    const { event, ...paramsSansEvent } = paramsWithProperUserId;

    gtag('event', event, paramsSansEvent);
  }

  config(params: { [key: string]: any }): void {
    Object.assign(this.eventConfig, params);
  }

  pageView(params: { path?: string; title?: string; [key: string]: any }) {
    this.internalTrack({
      ...this.eventConfig,
      event: 'page_view',
      path: params.path ?? defaultPageViewPath(),
      title: params.title ?? defaultPageViewTitle(),
      ...params,
    });
  }

  track({
    action,
    category,
    label,
    value,
    ...rest
  }: {
    action: string;
    category: string;
    label: string;
    value?: number;
    [key: string]: any;
  }) {
    this.internalTrack({
      ...this.eventConfig,
      event: 'event',
      category,
      action,
      label,
      value,
      ...rest,
    });
  }
}
