import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  title: () =>
    msg`Just a little more information will help us tailor personalized guidance just for you.`,
  intro: () =>
    md(msg`
The more accurate your estimates are on this screen, the better we can personalize our guidance for your needs.`),

  whyDoWeNeedThis: md(
    msg`Providing an income range helps us estimate tax savings across different benefit combinations.
    ${'_lf'}
Telling us your HSA balances can show how different plan options could perform in a difficult year.`,
  ),

  callout: md(msg`
HSA accounts can be a great way to save for future healthcare use and your retirement,  providing three types of tax advantages:
* If you’re eligible to contribute to an HSA, contributions may be pre-tax and could save you money by lowering your taxable income.
* HSA balances, if invested, grow tax-free.
* There are no taxes or penalties when using HSA funds to pay for qualified medical expenses — even after retirement.`),
};
