import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  title: msg`Welcome to Next Dollar Guidance`,
  intro:
    md(msg`Congratulations on taking the next step in financial security. In order to help you estimate how much and where to save that next dollar, you will:
${'_lf'}
 * Learn how to best take advantage of your Health Savings Accounts
 * Calculate how much to set aside for emergency savings
 * Discover what retirement strategies fit your lifestyle
${'_lf'}
This information will not be shared, and you can opt out at any time.
    `),
  callout: msg`Your privacy is important to us and the information provided will not be shared.`,
};
