import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  title: msg`Are you planning anything out of the ordinary for healthcare next year?`,
  intro: md(
    msg`We know you don’t have a crystal ball, but factoring in planned medical expenses (like having a baby or a scheduled surgery) will help us determine the best benefit options to help you cover the cost of those next year-specific needs.`,
  ),

  calloutTitle: msg`Heads up`,
  callout: md(
    msg`To protect your privacy, the numbers shown here are pre-filled based on statistical data
    associated with the ages of the members of your household and **usage categories** selected
    on the [previous screen](/average-usage). Make sure to update these levels for any planned
    medical expenses in the upcoming year.`,
  ),
  whyDoWeNeedThis: md(
    msg`Your answers help us estimate your healthcare costs for the upcoming plan year. 
    No pressure - it’s okay to use your best guess on how your healthcare use may go up. 
    If you have questions about any of the healthcare items, click the question 
    marks below for details. Like the information you provided in your typical year, 
    we use this to determine upcoming out-of-pocket costs for you and your household’s 
    healthcare needs.`,
  ),
};
