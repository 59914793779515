import { useGlobalStore } from 'hooks/useGlobalStore';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Api, api } from './api';
import { useGetEligibleBenefitTypes } from './sdk/selectsmartComponents';

// the ApiContext defaults to the api singleton we want to use
// in the deployed app, but we introduce a context to make it easy
// to stub/mock for testing
const ApiContext = React.createContext<Api>(api);

// Wrapper to wait for auth to be available before calling protected endpoints
const useHasSession = () => {
  const [hasSession, setHasSession] = useState(false);

  useEffect(() => {
    if (api.getSession()) {
      setHasSession(true);
    }

    const handleEvent = () => {
      if (api.getSession()) {
        setHasSession(true);
      } else {
        setHasSession(false);
      }
    };

    api.addOnSessionDataSetListener(handleEvent);

    return () => {
      api.removeOnSessionDataSetListener(handleEvent);
    };
  }, []);

  return { hasSession };
};

export function useBenefitTypes() {
  const { hasSession } = useHasSession();
  const {
    globalState: {
      apiState: {
        household: { clientId },
      },
    },
  } = useGlobalStore();

  return useGetEligibleBenefitTypes({}, { enabled: hasSession && !!clientId });
}

export function useBundlesSummary(bundlesId: string, bundleKey: string) {
  const api = useContext(ApiContext);
  return useQuery({
    queryKey: ['bundles', bundlesId, bundleKey],
    queryFn: () => api.getBundlesSummary(bundlesId, bundleKey),
  });
}
