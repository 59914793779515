import { ApiStatus, Dictionary } from './common';
import {
  Customizer,
  ExtendedPlanSummary,
  SummaryBenefitItem,
  SummaryResponseBundle,
} from './summary';

export type Bundles = {
  id?: string;
  status?: ApiStatus | null;
  selected_key?: string | null;
  bundles: Dictionary<SummaryResponseBundle>;
  benefits: Dictionary<SummaryBenefitItem>;
  customizer?: Customizer;
  contribution_limits: any | null;
  periodization_factors: any | null;
  submitted: boolean;
  customized?: any[] | null;
  plan_summaries: {
    [benefitId: string]: { [use: string]: ExtendedPlanSummary };
  };
  contribution: any;
  spouse_ineligible_plans: any;
  insurances: Dictionary<SummaryBenefitItem>;
};

export const bundlesInitialState = {
  insurances: {},
  submitted: false,
  benefits: {},
  bundles: {},
  contribution: {},
  selected_key: null,
  customizer: { decision_order: [], decision_tree: {} },
  contribution_limits: null,
  customized: null,
  status: null,
  spouse_ineligible_plans: {},
};
