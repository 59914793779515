import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  title: msg`Let’s get acquainted.`,
  intro: () =>
    md(msg`Let’s talk about you. We automatically include you in our guidance and
        can add any family members you want to consider incorporating into your
        plan.`),
  callout: msg`Your privacy is important to us and the information provided will not be shared.`,
};
