import React, { createContext, PropsWithChildren, useState } from 'react';
import { getDataSync } from 'services/asyncStorage';
import { FEATURES, SITE } from '../../site.config';

// Only use local storage if dev tools is enabled
const localStorageFeatures = () => {
  if (SITE.ENABLE_DEVELOPER_TOOLS) {
    const features = getDataSync('features');
    return features ? JSON.parse(features) : {};
  }
  return {};
};

export const featureKeys = [
  'IS_DEMO_SITE',
  'DISABLE_DEFAULT_SIGNUP_REDIRECT',
  'DISABLE_PRIVACY_MODAL',
  'ENABLE_CUSTOM_BUNDLES',
  'ENABLE_RETURNING_USER',
  'ENUMERATED_CONTENT_REVIEW',
  'PREMIUM_DISPLAY',
  'ENABLE_EVENT_TRACKING_DEBUG',
] as const;
export type Feature = typeof featureKeys[number];

export type FeaturesContextType = {
  features: Record<Feature, boolean | undefined>;
  toggleFeature: (key: Feature, enabled: boolean) => void;
  isEnabled: (key: Feature) => boolean;
};

export const FeaturesContext = createContext<FeaturesContextType | null>(null);

export const FeaturesProvider = ({ children }: PropsWithChildren) => {
  const [features, setFeatures] = useState<
    Record<Feature, boolean | undefined>
  >({
    ...FEATURES,
    ...localStorageFeatures(),
  });

  const toggleFeature = (key: Feature, enabled: boolean) => {
    // Don't store in local storage if dev tools is disabled
    if (SITE.ENABLE_DEVELOPER_TOOLS) {
      // Only set the specific flag that was toggled
      const newLocalStorage = { ...localStorageFeatures(), [key]: enabled };
      localStorage.setItem('features', JSON.stringify(newLocalStorage));
    }

    setFeatures((prev) => ({
      ...prev,
      [key]: enabled,
    }));
  };

  const isEnabled = (key: Feature) => {
    return features[key] ?? false;
  };

  return (
    <FeaturesContext.Provider
      value={{
        features,
        toggleFeature,
        isEnabled,
      }}>
      {children}
    </FeaturesContext.Provider>
  );
};

export const useFeatures = () => {
  const context = React.useContext(FeaturesContext);
  if (!context) {
    throw new Error(
      // eslint-disable-next-line lingui/no-unlocalized-strings
      'useFeatures must be used within a FeaturesProvider',
    );
  }
  return context;
};
