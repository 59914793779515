import { site } from '../../site.config';

type PostMessage = {
  contentHeight?: number;
  navigate?: string;
};
const usePostMessage = () => {
  return {
    postMessage: (key: keyof PostMessage, value: number | string | boolean) => {
      // In this case, benefitfocus needs this at the top level of a postMessage.
      if (site === 'benefitfocus' && key === 'contentHeight') {
        window.parent.postMessage({ contentHeight: value }, '*');

        // Else all of our updates will be nested under the `guidance` property for easier consumption.
      } else {
        window.parent.postMessage({ guidance: { [key]: value } }, '*');
      }
    },
  };
};
export default usePostMessage;
