import { ErrorModal } from 'components/siteComponents';
import Content from 'content/Content';
import * as React from 'react';
import { SITE } from '../../site.config';
import contentValues from 'content/values';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

export interface Props {
  open: boolean;
  onClose?: () => void;
}

const IntegrationsErrorModal: React.FC<Props> = ({ open, onClose }) => {
  const { _ } = useLingui();
  return (
    <ErrorModal
      open={open}
      onRetry={() => {
        window.location.reload();
      }}
      onClose={onClose}
      persistent={SITE.ID === 'benefitfocus'}
      title={_(contentValues.errors.defaultModalTitle)}
      preferCancel
      error={
        <Content noGutter path={(o) => o.errors.integrationsModalMessage} />
      }
      cancelButtonLabel={_(msg`Continue`)}
    />
  );
};

export default IntegrationsErrorModal;
