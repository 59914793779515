import { useLingui } from '@lingui/react';
import { Typography } from '@mui/material';
import React, { ReactNode, useMemo } from 'react';
import { Markdown } from './Markdown';
import { ContentLeaf, RenderOptions } from './types';
import content, { defaults } from './values';

type StaticContent = {
  path: (c: typeof defaults) => ContentLeaf;
  data?: undefined;
  testID?: string;
};
type DynamicContent<T> = {
  path: (c: typeof defaults) => (data: T) => ContentLeaf;
  data: T;
  testID?: string;
};

type Props<T> = (StaticContent | DynamicContent<T>) & RenderOptions;

const Content = <T extends any | undefined>({
  path,
  data,
  testID,
  ...options
}: Props<T>) => {
  const { _ } = useLingui();

  const contentRendered = useMemo(() => {
    if (!path) {
      return null;
    }
    const render: (c: ContentLeaf) => ReactNode = (c) => {
      if (!c) {
        return null;
      }

      const isString = typeof c === 'string';
      const isLinguiMessage = 'id' in c;
      const isMarkdown = !isString && !isLinguiMessage && c.type === 'markdown';

      // Value is either a string/message or a markdown object
      const value = isMarkdown ? c.value : c;

      // Rendered value could be string or lingui message
      // If it has an id property, it's a lingui message
      const renderedValue =
        'id' in value
          ? _(value.id, { ...(value.values || {}), _lf: '\n' })
          : typeof value === 'string'
          ? value
          : '';

      if (isMarkdown) {
        return (
          <Markdown testID={testID} {...options}>
            {renderedValue}
          </Markdown>
        );
      } else {
        return options?.inline ? (
          <>{renderedValue}</>
        ) : (
          <Typography
            data-testid={testID}
            variant={options?.typographyVariant || 'body1'}
            gutterBottom={!options?.noGutter}>
            {renderedValue}
          </Typography>
        );
      }
    };

    const leaf = path(content);

    if (data && typeof leaf === 'function') {
      if (!data) {
        return null;
      }
      return render(leaf(data));
    } else if (!data && typeof leaf !== 'function') {
      return render(leaf);
    } else {
      return null;
    }
  }, [path, data, _, testID, options]);

  return <>{contentRendered}</>;
};

export default Content;
