/* eslint-disable lingui/no-unlocalized-strings */
import { Tracker, defaultPageViewPath, defaultPageViewTitle } from './tracker';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export class GTMTracker implements Tracker {
  private eventConfig: { [key: string]: any } = {
    nonInteraction: false,
  };

  constructor(private options: { pageViewEventName?: string } = {}) {
    this.options.pageViewEventName =
      this.options.pageViewEventName ?? 'virtualPageView';
  }

  private internalTrack(params: { [key: string]: any }) {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    // use externalId as userId when present (e.g. Voya Party ID)
    const { externalId, ...rest } = params;
    const eventData = { ...rest, userId: externalId || rest.userId };

    window.dataLayer.push(eventData);
  }

  config(params: { [key: string]: any }): void {
    Object.assign(this.eventConfig, params);
  }

  pageView(params: { path?: string; title?: string; [key: string]: any }) {
    this.internalTrack({
      ...this.eventConfig,
      event: this.options.pageViewEventName,
      path: params.path ?? defaultPageViewPath(),
      title: params.title ?? defaultPageViewTitle(),
      ...params,
    });
  }

  track({
    action,
    category,
    label,
    value,
    ...rest
  }: {
    action: string;
    category: string;
    label: string;
    value?: number;
    [key: string]: any;
  }) {
    this.internalTrack({
      ...this.eventConfig,
      event: 'GAEvent',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
      ...rest,
    });
  }
}
