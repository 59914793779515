import { msg } from '@lingui/macro';
import { Typography } from '@mui/material';
import Content from 'content/Content';
import contentValues from 'content/values';
import { useMaybeLingui } from 'hooks/useMaybeLingui';
import * as React from 'react';
import { isValidElement } from 'react';
import { Nullable } from '../interfaces/common';
import Dialog, { BasicProps } from './common/Dialog/Dialog';

export interface Props extends Omit<BasicProps, 'onClose' | 'variant'> {
  title?: string;
  error?: Nullable<string> | React.ReactElement;
  onRetry?(): void;
  persistent?: boolean;
  onClose?: () => void;
  cancelButtonLabel?: string;
  preferCancel?: boolean;
}

const ErrorModal: React.FC<Props> = ({
  title,
  error,
  open,
  ariaId,
  onClose,
  onRetry,
  persistent,
  cancelButtonLabel,
  preferCancel,
}) => {
  const { _ } = useMaybeLingui();
  return (
    <Dialog
      onClose={onClose || (() => {})}
      open={open}
      title={_(title || contentValues.errors.defaultModalTitle)}
      ariaId={ariaId}
      persistent={persistent}
      variant={onRetry ? 'alert' : 'info'}
      confirmButtonLabel={onRetry ? _(msg`Retry`) : ''}
      cancelButtonLabel={cancelButtonLabel}
      preferCancel={preferCancel}
      onConfirm={onRetry || (() => {})}>
      {error ? (
        isValidElement(error) ? (
          error
        ) : (
          <Typography>{error}</Typography>
        )
      ) : (
        <Content noGutter path={(o) => o.errors.defaultModalMessage} />
      )}
    </Dialog>
  );
};

export default ErrorModal;
