import { UserData } from '../interfaces/auth';
import { SITE } from '../../site.config';
import { __legacyMakeRequest } from './common';

export async function getUserDataRequest(session_token: string) {
  return await __legacyMakeRequest<UserData>(`${SITE.API_URL}/user`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      // eslint-disable-next-line lingui/no-unlocalized-strings
      Authorization: `Bearer ${session_token}`,
    },
  });
}
