import { EventTrackingState } from 'context/eventTracking';

declare global {
  interface Window {
    tracker: Tracker;
  }
}

export interface Tracker {
  config(params: EventTrackingState['siteData']): void;

  pageView(params: { path?: string; title?: string; [key: string]: any }): void;

  track(params: {
    action: string;
    category: string;
    label: string;
    value?: number;
    [key: string]: any;
  }): void;
}

export function defaultPageViewTitle(): string {
  return document.title;
}

export function defaultPageViewPath(): string {
  return window.location.pathname;
}
