/* eslint-disable lingui/no-unlocalized-strings */
import { SelectsmartContext } from './selectsmartContext';
import { api } from 'selectsmart-api/api';

export type ErrorWrapper<TError> =
  | TError
  | { status: 'unknown'; payload: string };

export type SelectsmartFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams,
> = {
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
} & SelectsmartContext['fetcherOptions'];

export async function selectsmartFetch<
  TData,
  _TError,
  TBody extends {} | FormData | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {},
>({
  url,
  method,
  body,
  pathParams,
  queryParams,
  options,
}: SelectsmartFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
>): Promise<TData> {
  const resolvedUrl = resolveUrl(url, queryParams, pathParams);
  return api.request(
    method.toUpperCase(),
    resolvedUrl.substring(1),
    body,
    options,
  );
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {},
) => {
  let query = new URLSearchParams(queryParams).toString();
  if (query) query = `?${query}`;
  return url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query;
};
