import produce from 'immer';
import {
  ActionMap,
  BenefitItemUnion,
  CommonActionTypes,
  Dictionary,
  SerializedStore,
  StateInitialized,
} from '../interfaces/common';
import { BenefitsActionTypes } from '../helpers/stateUtil';

export enum ActionTypes {
  REMOVE_PERSON_BENEFITS = 'REMOVE_PERSON_BENEFITS',
  SET_ARE_CLIENT_BENEFITS_EDITED = 'SET_ARE_CLIENT_BENEFITS_EDITED',
  UPDATE_IS_CONSIDERED = 'UPDATE_IS_CONSIDERED',
  UPDATE_COMPARE_ALL = 'UPDATE_COMPARE_ALL',
  UPDATE_BY_ID = 'UPDATE_BY_ID',
}

export type State = StateInitialized & {
  byId: Dictionary<BenefitItemUnion>;
  areClientBenefitsEdited: boolean;
  compare_all: boolean;
};

export const initialState: State = {
  byId: {},
  initialized: false,
  areClientBenefitsEdited: false,
  compare_all: false,
};

export type Payload = {
  [CommonActionTypes.SETUP_STORE]: SerializedStore;
  [CommonActionTypes.RESET_STORE]: undefined;

  [BenefitsActionTypes.SETUP_BENEFITS]: Dictionary<BenefitItemUnion>;
  [ActionTypes.REMOVE_PERSON_BENEFITS]: string;
  [ActionTypes.SET_ARE_CLIENT_BENEFITS_EDITED]: boolean;
  [ActionTypes.UPDATE_COMPARE_ALL]: boolean;
  [ActionTypes.UPDATE_IS_CONSIDERED]: {
    id: string;
    value: boolean;
  };
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case CommonActionTypes.RESET_STORE: {
      return {
        ...initialState,
        initialized: true,
      };
    }

    case BenefitsActionTypes.SETUP_BENEFITS: {
      return {
        ...state,
        areClientBenefitsEdited: false,
      };
    }

    case ActionTypes.SET_ARE_CLIENT_BENEFITS_EDITED: {
      return produce(state, (draftState) => {
        draftState.areClientBenefitsEdited = action.payload;
      });
    }

    case ActionTypes.REMOVE_PERSON_BENEFITS: {
      return produce(state, (draftState) => {
        const userId = action.payload;
        const userBenefitIds = Object.values(draftState.byId)
          .filter((benefit) => benefit.person_id === userId)
          .map((benefit) => benefit.id);

        userBenefitIds.forEach((id) => {
          delete draftState.byId[id];
        });

        draftState.areClientBenefitsEdited = false;
      });
    }

    case ActionTypes.UPDATE_COMPARE_ALL: {
      return produce(state, (draftState) => {
        draftState.compare_all = action.payload;
      });
    }

    case ActionTypes.UPDATE_IS_CONSIDERED: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.byId[id].is_considered = value;
      });
    }
    default:
      return state;
  }
};
