import { NavigationStepConfig } from 'interfaces/navigation';
import { navigationMainSections } from 'navigationConfig';
import { groupBy } from 'lodash';

export const scrollToTop = () => {
  // document.body.scrollTop = 0;
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
};

export const initialFormStepRouteIndex = (
  userJourney: NavigationStepConfig[],
) => userJourney.findIndex(({ isInitialFormStep }) => isInitialFormStep);

export const formMainSteps = (userJourney: NavigationStepConfig[]) =>
  Object.values(groupBy(userJourney, (s) => s.name))
    .map((g) => g[0])
    // @ts-ignore
    .filter((s) => navigationMainSections.includes(s.name));

export const resultsSteps = (userJourney: NavigationStepConfig[]) =>
  userJourney.filter((step) => step.isResultStep);
