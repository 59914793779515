import { Box, IconButton, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { CloseOutlined } from '@mui/icons-material';
import { PropsWithChildren, useRef } from 'react';

export type DialogHeaderProps = {
  title?: string | React.ReactNode;
  open: boolean;
  description?: string | React.ReactNode;
  ariaId?: string;
  onClose(): void;
  persistent?: boolean;
};

const DialogHeader: React.FC<PropsWithChildren<DialogHeaderProps>> = ({
  title,
  description,
  ariaId,
  open,
  onClose,
  persistent,
  children,
}) => {
  const titleRef = useRef<HTMLHeadingElement>(null);

  React.useEffect(() => {
    // A11y: Set the focus to the title of the dialog for proper screen reading.
    if (open && titleRef?.current) {
      titleRef.current.focus();
    }
  }, [open]);

  return (
    <Box className="dialogHeader" sx={{ minHeight: '44px' }}>
      <Stack direction="row" alignItems="flex-start">
        <Stack direction="column" sx={{ flex: 1, pr: 2 }}>
          <Typography
            tabIndex={-1}
            ref={titleRef}
            component="h2"
            variant="h3"
            mb={0.5}
            minHeight={16}
            id={ariaId ? `${ariaId}-dialog-title` : undefined}>
            {title}
          </Typography>
          {!!description && (
            <Typography
              component="span"
              id={ariaId ? `${ariaId}-dialog-description` : undefined}>
              {description}
            </Typography>
          )}
        </Stack>
        {!persistent && (
          <IconButton
            data-testid="Dialog-close"
            aria-label="close"
            onClick={onClose}
            sx={{
              mr: -1,
              mt: -1,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseOutlined />
          </IconButton>
        )}
      </Stack>
      {children}
    </Box>
  );
};

export default DialogHeader;
