import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { ErrorPage } from './ErrorPage';

export const RouterErrorPage = () => {
  const routeError = useRouteError();

  useEffect(() => {
    if (routeError) {
      // eslint-disable-next-line no-console
      console.error('Caught Router Error', routeError);
      Sentry.captureException(routeError);
    }
  }, [routeError]);

  return <ErrorPage />;
};
