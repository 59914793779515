import { msg } from '@lingui/macro';

export default {
  hsaHra: msg`Although contributions are shown at the frequency of your choice, please
be advised that your employer may contribute at a different schedule. Please
consult with your HR department if you have questions regarding your
employer’s contribution schedule.`,

  contributionsIgnored: msg`Retirement contributions listed here in excess of IRS limits due to income
phase out, eligibility, or otherwise will be ignored.`,

  indemnity: msg`This is provided for illustrative purposes only. It presents an estimated
supplemental insurance benefit that reflects multiple coverages, specific
covered events and expected healthcare usage. It may consider any Wellness
Benefits or other Indemnity Insurance Payouts for which you may be eligible.
It does not confirm or guarantee eligibility for coverage, payouts, or
benefits and is used as an example only. Accident, Critical Illness and
Hospital Indemnity Insurance are limited benefit policies and provide
benefits for specific covered events that occur on or after your coverage
effective date. This coverage is not healthcare insurance and it is not a
coordinated benefit. You are not required to purchase any supplemental
insurance benefits in connection with enrolling in your healthcare
insurance. A complete description of benefits, limitations, exclusions and
termination of coverage will be provided in your policy documents. All
coverage is subject to the terms and conditions of the policy documents. If
there is any discrepancy between this and the policy documents, the policy
documents will govern.`,

  midyearSkippedSummary: msg`Premium and out-of-pocket costs are estimates based on the information provided about your household, public healthcare data sources (MEPS, KFF), and available sponsored health plans. Estimates are hypothetical and results may vary.`,

  hsaContributionLimit: msg`HSA contribution guidance assumes a maximum annual limit on HSA contributions at the family-plan level if there is more than one member in your household, a maximum annual limit on HSA contributions at the self-only plan level if there is one member in your household. Check your benefits documentation to make sure you don’t exceed the annual HSA contribution limit.`,

  taxSavings: ({
    appName,
  }: {
    appName: string;
  }) => msg`For tax-deferred accounts, you will need to pay appropriate taxes upon
distribution. ${appName} does not provide tax advice, and tax laws are
subject to change. Taxpayers are urged to consult with their tax advisor.`,

  employerContribution: msg`Employer Contributions include contributions to HSA, FSA, HRA and LPFSA
accounts as well as health & welfare credits, if applicable, that your
employer offers. We assume that you have completed or will complete any or
all activities that will earn you the maximum employer contribution to your
health account, if applicable. Employer contributions also include existing
HRA balance usage, if applicable.`,

  taxSavingsNegative: msg`Your employer’s HSA contributions generate a tax liability. Please consult
with your accountant regarding your local tax rules.`,

  contribution: msg`Contribution percentages less than 10 are rounded to the nearest
tenth-percent. Contribution percentages greater than 10 are rounded to the
nearest whole percent.`,

  outOfPocket: msg`Out-of-pocket cost is your expected out-of-pocket costs for qualified
medical expenses estimated according to our methodology based on the
healthcare usage you provided.`,

  investment: msg`Systematic investing does not ensure a profit nor guarantee against loss.
Investors should consider their financial ability to continue their
purchases through periods of low price levels. Investment options available
through a retirement plan are intended as long-term investments designed for
retirement purposes. Early withdrawals prior to age 59 ½ may be subject to a
10% premature distribution penalty tax. Money taken from plan will be taxed
as ordinary income in the year the money is distributed. Account values
fluctuate with market conditions, and when surrendered the principal may be
worth more or less than its original amount invested.`,

  systematicInvestment: msg`Systematic investing does not ensure a profit nor guarantee against loss.
Investors should consider their financial ability to continue their
purchases through periods of low price levels.. Investment options available
through a retirement plan are intended as long-term investments designed for
retirement purposes. Early withdrawals prior to age 59 ½ may be subject to a
10% premature distribution penalty tax. Money taken from plan will be taxed
as ordinary income in the year the money is distributed. Account values
fluctuate with market conditions, and when surrendered the principal may be
worth more or less than its original amount invested.`,

  illustrations: msg`The estimates, projections, and illustrations used in preparing this plan
are based upon mathematical computations and information from sources
believed to be reliable.`,
};
