import { DataSource } from './common';
import { BudgetingType } from './budgeting';

export interface Goals {
  emergencyfund: EmergencyFundType;
  retirement: RetirementGoal;
}

export type RetirementGoal = {
  age: number;
  spouse_age: number;
  monthly_spending: number;
  type: 'retirement';
  source: string;
};

export interface EmergencyFundType {
  current_monthly_saving: number;
  min_target_amount: number;
  current_balance: number;
  max_weeks_of_reserve: number;
  hasEmergencyFund?: boolean | null;
  source?: DataSource;
  type: 'emergencyfund';
  initialized: boolean;
}

export const initialRetirementGoal: RetirementGoal = {
  age: 0,
  spouse_age: 0,
  monthly_spending: 0,
  type: 'retirement',
  source: '',
};

export const initialEmergencyFundType: EmergencyFundType = {
  hasEmergencyFund: false,
  current_monthly_saving: 0,
  current_balance: 0,
  min_target_amount: 0,
  max_weeks_of_reserve: 0,
  source: undefined,
  type: 'emergencyfund',
  initialized: false,
};

export const goalsInitialState: Goals = {
  emergencyfund: initialEmergencyFundType,
  retirement: initialRetirementGoal,
};

export const budgetingInitialState: BudgetingType = {
  adjusted_budget: 0,
  starting_budget: 0,
  target_budget: 0,
  // @ts-ignore
  goals: {},
  id: '',
  initialized: false,
  periodization: {},
};
