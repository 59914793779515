import React, { useEffect } from 'react';
import WebFont from 'webfontloader';
import fonts from '../../context/theme/config/fonts';

type Props = {
  children: React.ReactNode;
};

const FontsLoader = ({ children }: Props) => {
  useEffect(() => {
    if (fonts) {
      WebFont.load(fonts);
    }
  }, []);

  return <>{children}</>;
};

export default FontsLoader;
