import { storeData, getDataSync } from './asyncStorage';

type SessionParams = {
  session_token: string;
  expires_at: string;
  renew_by: string;
} | null;

export const SESSION_TOKEN_KEY = '_session_token';

export async function saveSessionTokenInStorage(data: SessionParams) {
  return await storeData(SESSION_TOKEN_KEY, JSON.stringify(data));
}

export async function getSessionTokenData(): Promise<SessionParams> {
  return getSessionTokenDataSync();
}

export function getSessionTokenDataSync(): SessionParams {
  const data = getDataSync(SESSION_TOKEN_KEY);
  return JSON.parse(String(data));
}
