import { RouteNames, routeNameFromPath, routePath } from '../RouteNames';
import { useGlobalStore } from '../hooks/useGlobalStore';
import { Role } from '../interfaces/common';
import { useAvailableFormStepsContext } from './form/useAvailableFormStepsContext';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type RedirectRule = {
  test: () => boolean;
  redirect: RouteNames;
};

const RequiredDataRedirects = ({ children }: PropsWithChildren) => {
  const {
    globalState: {
      apiState: { people },
    },
  } = useGlobalStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { userJourney } = useAvailableFormStepsContext();
  const [loading, setLoading] = React.useState(false);

  const rules: RedirectRule[] = useMemo(
    () => [
      {
        test: () =>
          Object.values(people).every(
            (person) => person.role === Role.CLIENT || person.first_name,
          ),
        redirect: RouteNames.Household,
      },
    ],
    [people],
  );

  // On location change
  useEffect(() => {
    setLoading(true);
    const currentRouteName = routeNameFromPath(location.pathname);

    // Check redirect rules
    for (const rule of rules) {
      if (!rule.test()) {
        const thisStep = userJourney.findIndex(
          (n) => n.route === currentRouteName,
        );
        const redirectStep = userJourney.findIndex(
          (n) => n.route === rule.redirect,
        );
        if (thisStep > redirectStep) {
          navigate(routePath(rule.redirect), { replace: true });
          return;
        }
      }
    }
    setLoading(false);
  }, [location]);

  return <>{!loading && children}</>;
};

export default RequiredDataRedirects;
