import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  title: msg`Let’s take a deeper look at your household finances`,
  intro: msg`As we start to think about your financial future, it will help to breakdown your household finances.
${'_lf'}
${'_lf'}
This information will help us guide you on how much to save for emergencies and retirement.
  `,
  callout: md(
    msg`Your household's income has a direct affect on :
${'_lf'}
* How much you might need in an emergency
* How best to protect your family in a worst case scenario
* The best ways to save for retirement`,
  ),
  whyDoWeNeedThis: md(
    msg`We use your salary and state to estimate your household’s tax rate. We do our best to estimate based on the income you share, but you should really review all of your benefit documents before acting on any personalized guidance.`,
  ),
  tipsBonusesText: msg`If tips, bonuses or any additional income is not included in your paycheck, we’ll ask you about that a little later.`,
  tipsBonusesPartnerText: msg`If tips, bonuses or any additional income is not included in their paycheck, we’ll ask you about that a little later.`,
};
