export * from './hooks';
export * from './selectors';
export * from './types';
export { ApiError } from './api';

import { fromPairs } from 'lodash/fp';
import { api } from './api';
export default api;
import * as components from './sdk/selectsmartComponents';
import { saveSessionTokenInStorage } from 'services/auth';

// inject into window for testing (e.g. Playwright)
if (window) {
  // Use only fetch methods
  window.api = fromPairs(
    Object.keys(components)
      .filter((k) => k.startsWith('fetch'))
      // @ts-ignore
      .map((k) => [k.charAt(5).toLowerCase() + k.slice(6), components[k]]),
  );

  window.api.createSigninToken = async (
    last_location?: string | null,
  ): Promise<string> => {
    if (last_location !== undefined) {
      await components.fetchUpdateHousehold({ body: { last_location } });
    }
    return (await api.post('user/signin-token'))?.token;
  };

  window.api.injectSession = async (sessionData: any) => {
    saveSessionTokenInStorage(sessionData);
    api.setSession(sessionData);
  };
}
