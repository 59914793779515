import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  title: msg`Tell us about your health coverage`,
  intro: md(
    msg`Optimize your personalized guidance by providing your medical plan details.`,
  ),
  whyDoWeNeedThis: md(
    msg`The information below will help us estimate how much to budget for your healthcare needs.`,
  ),
};
