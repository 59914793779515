import { msg } from '@lingui/macro';
import {
  MedicalPlanKind,
  medicalPlanLabel,
  PlanFrequency,
} from '../interfaces/benefits';
import { HealthcareServiceUsage } from 'interfaces/healthcare';

export const MIN_ADULT_AGE = 18;
export const MAX_CHILD_AGE = 28; // TODO: Maybe not constrain dependent age but filter out for engine inputs?
export const MIN_AGE = 0;
export const MAX_AGE = 80;
export const MIN_TOBACCO_USER_AGE = 18;
export const CHILDBIRTH_ALLOWED_AGE = 18;
export const MAX_DEPENDENTS = 10;
export const DEFAULT_AGE = 40;
export const DEFAULT_AGE_DEPENDENT = 10;
export const STORAGE_KEY = '_guide_data';
export const MIN_RETIREMENT_AGE = 55;
export const MAX_RETIREMENT_AGE = 85;

export const yesOrNoQuestion = [
  {
    label: msg`No`,
    value: false,
  },
  {
    label: msg`Yes`,
    value: true,
  },
];

export const healthAreaLabels = {
  medical_care: msg`Medical Care`,
  prescription_drugs: msg`Prescription Drugs`,
  dental_care: msg`Dental Care`,
  vision_care: msg`Vision Care`,
};

export const levelValueOptionsSpecialized = {
  medical_care: [
    {
      label: msg`Routine`,
      value: HealthcareServiceUsage.LOW,
    },
    {
      label: msg`Moderate`,
      value: HealthcareServiceUsage.MODERATE,
    },
    {
      label: msg`High`,
      value: HealthcareServiceUsage.HIGH,
    },
  ],
  prescription_drugs: [
    {
      label: msg`Low`,
      value: HealthcareServiceUsage.LOW,
    },
    {
      label: msg`Moderate`,
      value: HealthcareServiceUsage.MODERATE,
    },
    {
      label: msg`High`,
      value: HealthcareServiceUsage.HIGH,
    },
  ],
  dental_care: [
    {
      label: msg`Routine`,
      value: HealthcareServiceUsage.LOW,
    },
    {
      label: msg`Moderate`,
      value: HealthcareServiceUsage.MODERATE,
    },
    {
      label: msg`High`,
      value: HealthcareServiceUsage.HIGH,
    },
  ],
  vision_care: [
    {
      label: msg`None`,
      value: HealthcareServiceUsage.LOW,
    },
    {
      label: msg`Routine`,
      value: HealthcareServiceUsage.MODERATE,
    },
    {
      label: msg`High`,
      value: HealthcareServiceUsage.HIGH,
    },
  ],
};

export const medicalPlanList = [
  {
    label: medicalPlanLabel.HMO,
    value: MedicalPlanKind.HMO,
  },
  {
    label: medicalPlanLabel.EPO,
    value: MedicalPlanKind.EPO,
  },
  {
    label: medicalPlanLabel.POS,
    value: MedicalPlanKind.POS,
  },
  {
    label: medicalPlanLabel.PPO,
    value: MedicalPlanKind.PPO,
  },
];

export const premiumFrequencyList = [
  {
    label: msg`Monthly (End of the month, 12x per year)`,
    value: PlanFrequency.MONTHLY,
  },
  {
    label: msg`Semi-monthly (Twice a month, 24x per year)`,
    value: PlanFrequency.SEMIMONTHLY,
  },
  {
    label: msg`Weekly (52x per year)`,
    value: PlanFrequency.WEEKLY,
  },
  {
    label: msg`Bi-weekly (Every other week, 26x per year)`,
    value: PlanFrequency.BIWEEKLY,
  },
];
export const premiumFrequencyListWithYearly = [
  ...premiumFrequencyList,
  {
    label: msg`Yearly`,
    value: PlanFrequency.ANNUALLY,
  },
];
