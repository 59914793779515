import {
  VerifyAuthTokenResponse,
  AuthenticationResponse,
} from '../interfaces/auth';
import { SITE } from '../../site.config';
import { __legacyMakeRequest } from './common';

interface SignUpRequestParams {
  app_id: string;
  client_id: string;
  invite_code: string;
  email?: string;
  username?: string;
  locale?: string;
}

interface SignInRequestParams {
  app_id: string;
  client_id: string;
  token: string;
}

interface SignInEmailRequestParams {
  app_id: string;
  client_id: string;
  email: string;
  reCaptchaToken: string;
}

export async function verifyAuthToken(token: string) {
  return __legacyMakeRequest<VerifyAuthTokenResponse>(
    `${SITE.API_URL}/auth/invites/${token}`,
  );
}

export async function signUpRequest({
  app_id,
  client_id,
  invite_code,
  email,
  username,
  locale,
}: SignUpRequestParams) {
  return __legacyMakeRequest<AuthenticationResponse>(
    `${SITE.API_URL}/auth/signup`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body:
        username != null && !email
          ? JSON.stringify({
              app_id,
              client_id,
              invite_code,
              username,
              locale,
            })
          : JSON.stringify({
              app_id,
              client_id,
              invite_code,
              email,
              locale,
            }),
    },
  );
}

export async function signInRequest({
  app_id,
  client_id,
  token,
}: SignInRequestParams) {
  return __legacyMakeRequest<AuthenticationResponse>(
    `${SITE.API_URL}/auth/signins`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        app_id,
        client_id,
        type: 'token',
        token,
      }),
    },
  );
}

export async function signOutRequest(session_token: string) {
  return await __legacyMakeRequest(`${SITE.API_URL}/user/session/signout`, {
    method: 'POST',
    headers: {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      Authorization: `Bearer ${session_token}`,
    },
  });
}

export async function signInViaEmailRequest({
  app_id,
  client_id,
  email,
  reCaptchaToken,
}: SignInEmailRequestParams) {
  return __legacyMakeRequest<AuthenticationResponse>(
    `${SITE.API_URL}/auth/signins`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        app_id,
        client_id,
        type: 'passwordless',
        email,
        captcha_token: reCaptchaToken,
      }),
    },
  );
}
