import { useCallback, useEffect } from 'react';
import { useAPI } from '../api/useAPI';
import { CommonActionTypes } from '../interfaces/common';
import { EmployerActionTypes } from '../helpers/stateUtil';
import { useGlobalStore } from './useGlobalStore';
import { getEmployerData } from 'api/selectsmart';

export function useFetchEmployerData() {
  const { dispatch } = useGlobalStore();
  const { data, fireRequest } = useAPI(getEmployerData);

  useEffect(() => {
    if (data) {
      const {
        employer_id,
        employer_name,
        default_state_of_residence,
        signin_url,
        signin_name,
        signin_note,
        employer_logo_url,
      } = data;
      dispatch({
        type: CommonActionTypes.SETUP_EMPLOYER_DATA,
        payload: {
          employer_id,
          employer_name,
          default_state_of_residence,
          signin_url,
          signin_name,
          signin_note,
          employer_logo_url,
        },
      });
      dispatch({
        type: EmployerActionTypes.SETUP_EMPLOYER,
        payload: data,
      });
    }
  }, [data, dispatch]);

  const fetchEmployerData = useCallback(async () => {
    const resp = await fireRequest({});
    return resp.data;
  }, [fireRequest]);

  return {
    fetchEmployerData,
  };
}
