import produce from 'immer';
import { AccountsActionTypes } from '../helpers/stateUtil';
import {
  ActionMap,
  CommonActionTypes,
  SerializedStore,
  StateInitialized,
  Dictionary,
} from 'interfaces/common';
import {
  RetirementType,
  RetirementAccount,
  PensionAccount,
  IraAccount,
  defaultRetirementAccount,
} from 'interfaces/retirement';

export enum ActionTypes {
  UPDATE_RETIREMENT = 'UPDATE_RETIREMENT',
  UPDATE_PENSION = 'UPDATE_PENSION',
  UPDATE_IRA = 'UPDATE_IRA',
  UPDATE_ADD_SPOUSE = 'UPDATE_ADD_SPOUSE',
  UPDATE_ADD_IRA = 'UPDATE_ADD_IRA',
  CLEAR_SPOUSE_ESRP = 'CLEAR_SPOUSE_ESRP',
  CLEAR_IRA = 'CLEAR_IRA',
  SET_RETIREMENT_ACCOUNT_STATUS = 'SET_RETIREMENT_ACCOUNT_STATUS',
  REMOVE_USER_ACCOUNTS = 'REMOVE_USER_ACCOUNTS',
}

export type State = StateInitialized & RetirementType;

export const initialState: State = {
  retirement: {},
  pension: {},
  ira: {
    balance_pretax: 0,
    balance_roth: 0,
    basis: 0,
    basis_roth: 0,
    contribution_pretax: 0,
    contribution_roth: 0,
    contribution_pretax_ytd: 0,
    contribution_roth_ytd: 0,
    type: 'ira',
  },
  addSpouseRetirement: false,
  addIraAccounts: false,
  source: undefined,
  initialized: false,
};

export type Payload = {
  [CommonActionTypes.SETUP_STORE]: SerializedStore;
  [CommonActionTypes.RESET_STORE]: undefined;
  [AccountsActionTypes.UPDATE_ACCOUNTS]: Dictionary<any>;
  [ActionTypes.UPDATE_RETIREMENT]: {
    person_id: string;
    id: string;
    key: keyof RetirementAccount;
    value: any;
  };
  [ActionTypes.UPDATE_PENSION]: {
    id: string;
    key: keyof PensionAccount;
    value: any;
  };
  [ActionTypes.UPDATE_IRA]: {
    key: keyof IraAccount;
    value: any;
  };
  [ActionTypes.UPDATE_ADD_SPOUSE]: boolean;
  [ActionTypes.UPDATE_ADD_IRA]: boolean;
  [ActionTypes.CLEAR_IRA]: undefined;
  [ActionTypes.CLEAR_SPOUSE_ESRP]: {
    person_id: string;
    id: string;
  };
  [ActionTypes.SET_RETIREMENT_ACCOUNT_STATUS]: {
    id: string;
    status: string;
    person_id: string;
  };

  [ActionTypes.REMOVE_USER_ACCOUNTS]: string;

};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case CommonActionTypes.SETUP_STORE: {
      const { retirement } = action.payload;

      return {
        ...state,
        ...retirement,
      };
    }

    case CommonActionTypes.RESET_STORE: {
      return {
        ...initialState,
        initialized: true,
      };
    }

    case AccountsActionTypes.UPDATE_ACCOUNTS: {

      const accounts = Object.values(action.payload);
      const esrps = accounts.filter((a) => a.type === 'esrp');
      const pensions = accounts.filter((a) => a.type === 'pension');
      const ira = accounts.find((a) => a.type === 'ira');
      return produce(state, (draftState) => {
        // const spouseEsrp = esrps.filter((acc) => !acc.employer_id);
        esrps.map((esrp) => {
          const match_tiers = esrp.match_tiers ? esrp.match_tiers : [];
          if (!draftState.retirement[esrp.person_id]) {
            draftState.retirement[esrp.person_id] = {};
          }
          draftState.retirement[esrp.person_id][esrp.id] = {
            ...esrp,
            match_tiers,
          };
        });
        pensions.map((pension) => {
          draftState.pension[pension.person_id] = pension;
        });
        draftState.ira = ira;
      });
    }

    case ActionTypes.UPDATE_RETIREMENT: {
      const { person_id, id, key, value } = action.payload;
      return produce(state, (draftState) => {
        // @ts-ignore
        draftState.retirement[person_id][id][key] = value;
      });
    }
    case ActionTypes.UPDATE_PENSION: {
      const { id, key, value } = action.payload;
      return produce(state, (draftState) => {
        // @ts-ignore
        draftState.pension[id][key] = value;
      });
    }
    case ActionTypes.UPDATE_IRA: {
      const { key, value } = action.payload;
      return produce(state, (draftState) => {
        // @ts-ignore
        draftState.ira[key] = value;
      });
    }

    case ActionTypes.UPDATE_ADD_SPOUSE: {
      return produce(state, (draftState) => {
        draftState.addSpouseRetirement = action.payload;
      });
    }
    case ActionTypes.UPDATE_ADD_IRA: {
      return produce(state, (draftState) => {
        draftState.addIraAccounts = action.payload;
      });
    }
    case ActionTypes.CLEAR_IRA: {
      return produce(state, (draftState) => {
        draftState.ira = {
          ...state.ira,
          ...initialState.ira,
        };
      });
    }
    case ActionTypes.CLEAR_SPOUSE_ESRP: {
      const { person_id, id } = action.payload;
      return produce(state, (draftState) => {
        // @ts-ignore
        draftState.retirement[person_id][id] = {
          ...state.retirement[person_id][id],
          ...defaultRetirementAccount,
          status: 'inactive',
        };
        draftState.pension[person_id] = {
          ...state.pension[person_id],
          monthly_payment: 0,
        };
      });
    }

    case ActionTypes.SET_RETIREMENT_ACCOUNT_STATUS: {
      const { person_id, id, status } = action.payload;
      return produce(state, (draftState) => {
        draftState.retirement[person_id][id] = {
          ...state.retirement[person_id][id],
          status,
        };
      });
    }

    case ActionTypes.REMOVE_USER_ACCOUNTS: {
      const person_id = action.payload;
      return produce(state, (draftState) => {
        delete draftState.retirement[person_id];
        delete draftState.pension[person_id];
      });
    }

    default:
      return state;
  }
};
