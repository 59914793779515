import produce from 'immer';
import {
  ActionMap,
  ApiStatus,
  BenefitItemUnion,
  CommonActionTypes,
  DataSource,
  Dictionary,
  EmployerDataResponse,
  OptionValue,
  PayFrequency,
  Role,
  SerializedStore,
  StateInitialized,
  StatesOfResidence,
} from '../interfaces/common';
import {
  AccountsActionTypes,
  ActionPlanActionTypes,
  BenefitsActionTypes,
  BudgetingActionTypes,
  BundlesActionTypes,
  EmployerActionTypes,
  GoalsActionTypes,
  HealthcareActionTypes,
  HouseholdActionTypes,
  IncomesActionTypes,
  PeopleActionTypes,
  PlansActionTypes,
  TobaccoUseActionTypes,
} from '../helpers/stateUtil';
import { APIState } from '../interfaces/api';
import { Account, BenefitItem } from '../interfaces/benefits';
import {
  BundleResponse,
  createDefaultPerson,
  createDefaultPersonHealthcare,
  createDefaultPersonIncome,
  createDefaultTobaccoUse,
  Household,
  householdInitialState,
  PersonIncome,
} from '../interfaces/household';
import { employerInitialState } from '../interfaces/employer';
import { bundlesInitialState } from '../interfaces/bundles';
import { ActionPlan } from '../interfaces/actionPlan';
import {
  budgetingInitialState,
  Goals,
  goalsInitialState,
  RetirementGoal,
} from '../interfaces/goals';
import { BudgetingType } from '../interfaces/budgeting';
import {
  HealthcareAreas,
  HealthcareServiceUsage,
  HealthcareSpecial,
  HealthcareSpecialDental,
  HealthcareSpecialDrugs,
  HealthcareSpecialMedical,
  HealthcareSpecialVision,
} from '../interfaces/healthcare';

export type State = APIState & StateInitialized;

export const initialState: State = {
  // @ts-ignore
  employer: employerInitialState,
  benefits: {},
  plans: {},
  accounts: {},
  people: {},
  incomes: {},
  tobaccoUse: {},
  benefitUses: {},
  // @ts-ignore
  bundles: bundlesInitialState,
  // @ts-ignore
  actionPlan: {},
  goals: goalsInitialState,
  budgeting: budgetingInitialState,
  household: householdInitialState,
  healthcare: {},
  customized: {}, // TODO: shouldn't be here since it is not "API data"
  initialized: true,
  healthbenefits_mode: 'skipped:hsa_availability_only',
};

type Payload = {
  [CommonActionTypes.SETUP_STORE]: SerializedStore;

  [EmployerActionTypes.SETUP_EMPLOYER]: EmployerDataResponse;
  [EmployerActionTypes.UPDATE_BENEFIT_SEGMENT_QUESTION_ANSWER]: {
    name: string;
    value: OptionValue;
  };

  [BenefitsActionTypes.SETUP_BENEFITS]: Dictionary<BenefitItemUnion>;
  [BenefitsActionTypes.REMOVE_BENEFIT]: {
    benefitId: string;
  };
  [BenefitsActionTypes.UPDATE_BENEFIT]: {
    id: string;
    benefit: Partial<BenefitItemUnion>;
  };

  [PlansActionTypes.ADD_PLAN]: BenefitItem;
  [PlansActionTypes.REMOVE_PLAN]: {
    planId: string;
  };
  [PlansActionTypes.UPDATE_PLANS]: Dictionary<BenefitItem>;

  // @ts-ignore
  [AccountsActionTypes.UPDATE_HRA_ACCOUNT]: {
    id: string;
    balance: number;
  };

  [AccountsActionTypes.UPDATE_ACCOUNT]: {
    id: string;
    account: Account;
  };

  [AccountsActionTypes.UPDATE_ACCOUNTS]: Dictionary<Account>;

  [PeopleActionTypes.ADD_PERSON]: {
    id: string;
    role: Role;
  };
  [PeopleActionTypes.REMOVE_PERSON]: {
    id: string;
  };
  [PeopleActionTypes.UPDATE_BIRTHDATE]: {
    id: string;
    birthdate: string;
  };
  [PeopleActionTypes.UPDATE_IS_COVERED]: {
    id: string;
    is_covered: boolean;
  };
  [PeopleActionTypes.UPDATE_HAS_MEDICARE]: {
    id: string;
    has_medicare: boolean;
    medicare_status: string;
  };
  [PeopleActionTypes.UPDATE_EDITED_BIRTHDAY]: {
    id: string;
    yearmonth_only: boolean;
  };
  [PeopleActionTypes.UPDATE_FIRST_NAME]: {
    id: string;
    first_name: string;
  };

  [IncomesActionTypes.SET_INCOMES]: Dictionary<PersonIncome>;
  [IncomesActionTypes.UPDATE_ANNUAL_PAY]: {
    id: string;
    value: number;
  };
  [IncomesActionTypes.UPDATE_PAY_FREQUENCY]: {
    id: string;
    value: PayFrequency;
  };
  [IncomesActionTypes.UPDATE_PAY_SCHEDULE]: {
    id: string;
    value: string | null;
  };
  [IncomesActionTypes.UPDATE_OTHER_INCOME]: {
    id: string;
    value: number;
  };
  [IncomesActionTypes.UPDATE_IS_OTHER_INCOME_SE]: {
    id: string;
    value: boolean;
  };
  [IncomesActionTypes.UPDATE_SPOUSE_RECEIVE_SOCIAL_SECURITY]: {
    id: string;
    value: boolean;
  };
  [IncomesActionTypes.UPDATE_MONTHLY_SOCIAL_SECURITY]: {
    id: string;
    value: number;
  };
  [IncomesActionTypes.UPDATE_AGE_SOCIAL_SECURITY]: {
    id: string;
    value: number;
  };

  [TobaccoUseActionTypes.UPDATE_TOBACCO_USER]: {
    id: string;
    value: boolean;
  };
  [TobaccoUseActionTypes.UPDATE_TOBACCO_CESSATION]: {
    id: string;
    value: boolean;
  };

  [BundlesActionTypes.UPDATE_SELECTED_BUNDLE]: string | null | undefined;
  [BundlesActionTypes.SETUP_RESULTS]: BundleResponse;
  [BundlesActionTypes.UPDATE_CUSTOMIZED]: any[];

  [ActionPlanActionTypes.SET_ACTION_PLAN]: ActionPlan;
  [ActionPlanActionTypes.TOGGLE_COMPLETED]: {
    id: string;
    value: boolean;
  };

  [GoalsActionTypes.UPDATE_RETIREMENT_GOAL]: {
    key: keyof RetirementGoal;
    value: any;
  };
  [GoalsActionTypes.UPDATE_GOAL]: Goals;
  [GoalsActionTypes.UPDATE_HAS_EMERGENCY]: boolean;
  [GoalsActionTypes.UPDATE_MONTHLY_SAVINGS]: number;
  [GoalsActionTypes.UPDATE_CURRENT_BALANCE]: number;
  [GoalsActionTypes.UPDATE_SAVINGS_AIM]: number;
  [GoalsActionTypes.UPDATE_WEEKS_COVERED]: number;

  [BudgetingActionTypes.UPDATE_ADJUSTED_BUDGET]: number;
  [BudgetingActionTypes.SET_BUDGET_DATA]: BudgetingType;

  [HouseholdActionTypes.SET_HOUSEHOLD]: Household;
  [HouseholdActionTypes.UPDATE_AGE]: {
    id: string;
    age: number;
  };
  [HouseholdActionTypes.UPDATE_STATE]: StatesOfResidence;
  [HouseholdActionTypes.UPDATE_ID]: string;
  [HouseholdActionTypes.UPDATE_SPOUSE_ID]: string;
  [HouseholdActionTypes.UPDATE_LAST_LOCATION]: string;
  [HouseholdActionTypes.UPDATE_DISCLOSURE_ACCEPTED_AT]: Date;
  [HouseholdActionTypes.UPDATE_MAX_PROGRESS]: number;
  [HouseholdActionTypes.UPDATE_ELIGIBILITY]: {
    key: string;
    value: string;
  };
  [HouseholdActionTypes.UPDATE_GUIDANCE_MODE]: string;
  [HouseholdActionTypes.UPDATE_GUIDANCE_MODE_STARTED_AT]: string;
  [HouseholdActionTypes.UPDATE_GUIDANCE_MODE_COMPLETED_AT]: string;
  [HouseholdActionTypes.UPDATE_SPOUSE_HAS_BENEFITS]: {
    value: boolean;
  };
  [HouseholdActionTypes.UPDATE_CONSIDER_SPLIT_BENEFITS]: {
    value: boolean;
  };
  [HouseholdActionTypes.UPDATE_CLAIMS_OPTIN]: {
    value: boolean | null;
  };
  [HouseholdActionTypes.UPDATE_INCOME_LEVEL]: number;
  [HealthcareActionTypes.UPDATE_TYPICAL]: {
    id: string;
    care: HealthcareAreas;
    value: HealthcareServiceUsage;
  };
  [HealthcareActionTypes.UPDATE_HAS_EXPECTED_YTD]: {
    id: string;
    value: boolean;
  };
  [HealthcareActionTypes.UPDATE_HAS_EXPECTED_DTY]: {
    id: string;
    value: boolean;
  };
  [HealthcareActionTypes.UPDATE_HAS_SPECIAL]: {
    id: string;
    value: boolean;
  };
  [HealthcareActionTypes.UPDATE_SPECIAL_CARE]: {
    id: string;
    care: keyof HealthcareSpecial;
    value: boolean;
  };
  [HealthcareActionTypes.UPDATE_HEALTHCARE_ACCOUNT]: {
    id: string;
    value: any;
  };
  [HealthcareActionTypes.UPDATE_SPECIAL_USE]: {
    id: string;
    field: string;
    care: HealthcareAreas;
    use:
      | keyof HealthcareSpecialMedical
      | keyof HealthcareSpecialDrugs
      | keyof HealthcareSpecialDental
      | keyof HealthcareSpecialVision;
    value: number;
  };
  [HealthcareActionTypes.CONVERT_SPECIAL_RELATIVE_TO_ABSOLUTE]: {
    id: string;
    field: string;
    typicalUsageValues: any;
  };

  [CommonActionTypes.RESET_STORE]: undefined;
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case CommonActionTypes.SETUP_STORE: {
      const {
        benefits,
        employer,
        people,
        incomes,
        tobaccoUse,
        bundles,
        retirement_goal,
        emergency_fund_goal,
        budgeting,
        household,
        healthcare,
        benefitUses,
      } = action.payload;

      return {
        ...state,
        benefitUses: { ...benefitUses },
        benefits: { ...benefits },
        // @ts-ignore
        employer: { ...employer },
        people: { ...people },
        incomes: { ...incomes },
        tobaccoUse: { ...tobaccoUse },
        // @ts-ignore
        bundles: { ...bundles },
        goals: {
          retirement: { ...retirement_goal },
          emergencyfund: { ...emergency_fund_goal },
        },
        budgeting: { ...budgeting },
        household: { ...household },
        healthcare: { ...healthcare },
      };
    }

    case EmployerActionTypes.SETUP_EMPLOYER: {
      return {
        ...state,
        // @ts-ignore
        employer: { ...action.payload },
      };
    }

    //Benefits
    case EmployerActionTypes.UPDATE_BENEFIT_SEGMENT_QUESTION_ANSWER: {
      const { name, value } = action.payload;

      return produce(state, (draftState) => {
        if (draftState.household.eligibility == null) {
          draftState.household.eligibility = {};
        }
        // @ts-ignore
        draftState.household.eligibility[name] = value;
      });
    }

    case BenefitsActionTypes.SETUP_BENEFITS: {
      return {
        ...state,
        benefits: action.payload,
      };
    }

    case BenefitsActionTypes.REMOVE_BENEFIT: {
      const { benefitId } = action.payload;
      return produce(state, (draftState) => {
        delete draftState.benefits[benefitId];
      });
    }

    case BenefitsActionTypes.UPDATE_BENEFIT: {
      const { id, benefit } = action.payload;
      return produce(state, (draftState) => {
        draftState.benefits[id] = benefit;
      });
    }

    //Plans
    case PlansActionTypes.ADD_PLAN: {
      return produce(state, (draftState) => {
        draftState.plans[action.payload.id] = action.payload;
      });
    }

    case PlansActionTypes.UPDATE_PLANS: {
      return produce(state, (draftState) => {
        draftState.plans = action.payload;
      });
    }

    case PlansActionTypes.REMOVE_PLAN: {
      const { planId } = action.payload;
      return produce(state, (draftState) => {
        if (draftState.plans[planId]) {
          delete draftState.plans[planId];
        }
      });
    }

    //Account
    case AccountsActionTypes.UPDATE_ACCOUNT: {
      const { id, account } = action.payload;
      return produce(state, (draftState) => {
        draftState.accounts[id] = account;
      });
    }

    case AccountsActionTypes.UPDATE_ACCOUNTS: {
      return produce(state, (draftState) => {
        draftState.accounts = action.payload;
      });
    }

    //People
    case PeopleActionTypes.ADD_PERSON: {
      return {
        ...state,
        people: {
          ...state.people,
          [action.payload.id]: createDefaultPerson(
            action.payload.id,
            action.payload.role,
          ),
        },
        tobaccoUse: {
          ...state.tobaccoUse,
          ...(action.payload.role !== Role.DEPENDENT && {
            [action.payload.id]: createDefaultTobaccoUse(action.payload.id),
          }),
        },
        incomes: {
          ...state.incomes,
          [action.payload.id]: createDefaultPersonIncome(action.payload.id),
        },
        household: {
          ...state.household,
          allIds:
            state.household.allIds.indexOf(action.payload.id) < 0
              ? [...state.household.allIds, action.payload.id]
              : state.household.allIds,
        },
        healthcare: {
          ...state.healthcare,
          [action.payload.id]: createDefaultPersonHealthcare(action.payload.id),
        },
      };
    }

    case PeopleActionTypes.REMOVE_PERSON: {
      const { id: personId } = action.payload;
      return produce(state, (draftState) => {
        draftState.household.allIds = draftState.household.allIds.filter(
          (id: any) => personId !== id,
        );
        delete draftState.healthcare[personId];
        delete draftState.people[personId];
        delete draftState.tobaccoUse[personId];
        delete draftState.incomes[personId];
      });
    }

    case PeopleActionTypes.UPDATE_BIRTHDATE: {
      const { birthdate, id } = action.payload;
      return produce(state, (draftState) => {
        draftState.people[id].birthdate = birthdate;
      });
    }

    case PeopleActionTypes.UPDATE_IS_COVERED: {
      const { is_covered, id } = action.payload;
      return produce(state, (draftState) => {
        draftState.people[id].is_covered = is_covered;
      });
    }

    case PeopleActionTypes.UPDATE_HAS_MEDICARE: {
      const { has_medicare, medicare_status, id } = action.payload;
      return produce(state, (draftState) => {
        draftState.people[id].has_medicare = has_medicare;
        draftState.people[id].medicare_status = medicare_status;
      });
    }

    case PeopleActionTypes.UPDATE_EDITED_BIRTHDAY: {
      const { yearmonth_only, id } = action.payload;
      return produce(state, (draftState) => {
        draftState.people[id].is_birthdate_yearmonth_only = yearmonth_only;
      });
    }

    case PeopleActionTypes.UPDATE_FIRST_NAME: {
      const { first_name, id } = action.payload;
      return produce(state, (draftState) => {
        draftState.people[id].first_name = first_name;
      });
    }
    //Incomes
    case IncomesActionTypes.SET_INCOMES: {
      return produce(state, (draftState) => {
        draftState.incomes = action.payload;
      });
    }

    case IncomesActionTypes.UPDATE_ANNUAL_PAY: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        // draftState.incomes = draftState.incomes ?? {};
        draftState.incomes[id] = draftState.incomes[id] ?? {};
        draftState.incomes[id].annual_pay = value;
      });
    }

    case IncomesActionTypes.UPDATE_PAY_FREQUENCY: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.incomes[id].pay_frequency = value;
      });
    }

    case IncomesActionTypes.UPDATE_PAY_SCHEDULE: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.incomes[id].pay_schedule = value;
      });
    }

    case IncomesActionTypes.UPDATE_OTHER_INCOME: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.incomes[id].other_income = value;
      });
    }

    case IncomesActionTypes.UPDATE_IS_OTHER_INCOME_SE: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.incomes[id].is_other_income_se = value;
      });
    }

    case IncomesActionTypes.UPDATE_SPOUSE_RECEIVE_SOCIAL_SECURITY: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.incomes[id].is_receiving_social_security = value;
      });
    }

    case IncomesActionTypes.UPDATE_MONTHLY_SOCIAL_SECURITY: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.incomes[id].monthly_social_security = value;
      });
    }

    case IncomesActionTypes.UPDATE_AGE_SOCIAL_SECURITY: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.incomes[id].social_security_start_age = value;
      });
    }

    //TobaccoUser
    case TobaccoUseActionTypes.UPDATE_TOBACCO_USER: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.tobaccoUse[id].is_tobacco_user = value;
      });
    }

    case TobaccoUseActionTypes.UPDATE_TOBACCO_CESSATION: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.tobaccoUse[id].is_tobacco_cessation = value;
      });
    }

    //Bundles
    case BundlesActionTypes.UPDATE_SELECTED_BUNDLE: {
      return produce(state, (draftState) => {
        draftState.bundles.selected_key = action.payload;
      });
    }

    case BundlesActionTypes.SETUP_RESULTS: {
      return produce(state, (draftState) => {
        // @ts-ignore
        draftState.bundles = action.payload;
        // Test first, causes errors
        if (draftState.bundles.status !== ApiStatus.READY) {
          draftState.bundles.status = ApiStatus.READY;
        }
      });
    }

    case BundlesActionTypes.UPDATE_CUSTOMIZED: {
      return produce(state, (draftState) => {
        draftState.customized = action.payload;
      });
    }

    //Action Plan
    case ActionPlanActionTypes.SET_ACTION_PLAN: {
      return produce(state, (draftState) => {
        draftState.actionPlan = action.payload;
      });
    }

    case ActionPlanActionTypes.TOGGLE_COMPLETED: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        // @ts-ignore
        draftState.actionPlan[id]['completed_at'] = value
          ? new Date().toUTCString()
          : null;
      });
    }

    //Goals
    case GoalsActionTypes.UPDATE_GOAL: {
      const { retirement, emergencyfund } = action.payload;
      return produce(state, (draftState) => {
        draftState.goals.retirement = retirement;
        draftState.goals.emergencyfund.current_monthly_saving =
          emergencyfund.current_monthly_saving;
        draftState.goals.emergencyfund.min_target_amount =
          emergencyfund.min_target_amount;
        draftState.goals.emergencyfund.current_balance =
          emergencyfund.current_balance;
        draftState.goals.emergencyfund.max_weeks_of_reserve =
          emergencyfund.max_weeks_of_reserve;
        draftState.goals.emergencyfund.hasEmergencyFund =
          emergencyfund.current_balance > 0;
        draftState.goals.emergencyfund.source = emergencyfund.source;
      });
    }

    case GoalsActionTypes.UPDATE_RETIREMENT_GOAL: {
      const { key, value } = action.payload;
      return produce(state, (draftState) => {
        // @ts-ignore
        draftState.goals.retirement[key] = value;
      });
    }

    case GoalsActionTypes.UPDATE_HAS_EMERGENCY: {
      return produce(state, (draftState) => {
        draftState.goals.emergencyfund.hasEmergencyFund = action.payload;
      });
    }

    case GoalsActionTypes.UPDATE_MONTHLY_SAVINGS: {
      return produce(state, (draftState) => {
        draftState.goals.emergencyfund.current_monthly_saving = action.payload;
      });
    }

    case GoalsActionTypes.UPDATE_CURRENT_BALANCE: {
      return produce(state, (draftState) => {
        draftState.goals.emergencyfund.current_balance = action.payload;
      });
    }

    case GoalsActionTypes.UPDATE_SAVINGS_AIM: {
      return produce(state, (draftState) => {
        draftState.goals.emergencyfund.min_target_amount = action.payload;
      });
    }

    case GoalsActionTypes.UPDATE_WEEKS_COVERED: {
      return produce(state, (draftState) => {
        draftState.goals.emergencyfund.max_weeks_of_reserve = action.payload;
      });
    }

    //Budgeting
    case BudgetingActionTypes.UPDATE_ADJUSTED_BUDGET: {
      return produce(state, (draftState) => {
        draftState.budgeting.adjusted_budget = action.payload;
      });
    }

    case BudgetingActionTypes.SET_BUDGET_DATA: {
      const {
        adjusted_budget,
        starting_budget,
        target_budget,
        goals,
        id,
        tiers,
        periodization,
        has_work_break,
        guidance_start,
        guidance_end,
      } = action.payload;
      return produce(state, (draftState) => {
        draftState.budgeting.adjusted_budget = adjusted_budget;
        draftState.budgeting.starting_budget = starting_budget;
        draftState.budgeting.target_budget = target_budget;
        draftState.budgeting.goals = goals;
        draftState.budgeting.id = id;
        draftState.budgeting.tiers = tiers;
        draftState.budgeting.periodization = periodization;
        draftState.budgeting.has_work_break = has_work_break;
        draftState.budgeting.guidance_start = guidance_start;
        draftState.budgeting.guidance_end = guidance_end;
      });
    }

    //Household
    case HouseholdActionTypes.SET_HOUSEHOLD: {
      return {
        ...state,
        household: { ...state.household, ...action.payload },
      };
    }

    case HouseholdActionTypes.UPDATE_AGE: {
      const { age, id } = action.payload;
      return produce(state, (draftState) => {
        draftState.household.ages[id] = age;
      });
    }

    case HouseholdActionTypes.UPDATE_STATE: {
      return produce(state, (draftState) => {
        draftState.household.state_of_residence = action.payload;
      });
    }

    case HouseholdActionTypes.UPDATE_SPOUSE_ID: {
      return produce(state, (draftState) => {
        draftState.household.spouseId = action.payload;
      });
    }

    case HouseholdActionTypes.UPDATE_ID: {
      return produce(state, (draftState) => {
        draftState.household.id = action.payload;
      });
    }

    case HouseholdActionTypes.UPDATE_LAST_LOCATION: {
      return produce(state, (draftState) => {
        // @ts-ignore
        draftState.household.last_location = action.payload;
      });
    }

    case HouseholdActionTypes.UPDATE_DISCLOSURE_ACCEPTED_AT: {
      return produce(state, (draftState) => {
        draftState.household.disclosure_accepted_at = action.payload;
      });
    }

    case HouseholdActionTypes.UPDATE_MAX_PROGRESS: {
      return produce(state, (draftState) => {
        draftState.household.max_progress = action.payload;
      });
    }

    case HouseholdActionTypes.UPDATE_ELIGIBILITY: {
      const { key, value } = action.payload;
      return produce(state, (draftState) => {
        if (draftState.household.eligibility == null) {
          draftState.household.eligibility = {};
        }
        draftState.household.eligibility[key] = value;
      });
    }

    case HouseholdActionTypes.UPDATE_GUIDANCE_MODE: {
      return produce(state, (draftState) => {
        // @ts-ignore
        draftState.household.guidance_mode = action.payload;
      });
    }

    case HouseholdActionTypes.UPDATE_GUIDANCE_MODE_STARTED_AT: {
      return produce(state, (draftState) => {
        draftState.household.guidance_mode_started_at = action.payload;
      });
    }

    case HouseholdActionTypes.UPDATE_GUIDANCE_MODE_COMPLETED_AT: {
      return produce(state, (draftState) => {
        draftState.household.guidance_mode_completed_at = action.payload;
      });
    }

    case HouseholdActionTypes.UPDATE_SPOUSE_HAS_BENEFITS: {
      const { value } = action.payload;

      return produce(state, (draftState) => {
        draftState.household.consider_spousal_benefits = value;
      });
    }

    case HouseholdActionTypes.UPDATE_CONSIDER_SPLIT_BENEFITS: {
      const { value } = action.payload;

      return produce(state, (draftState) => {
        draftState.household.consider_split_benefits = value;
      });
    }

    case HouseholdActionTypes.UPDATE_CLAIMS_OPTIN: {
      const { value } = action.payload;

      return produce(state, (draftState) => {
        draftState.household.has_claims_optin = value;
      });
    }

    case HouseholdActionTypes.UPDATE_INCOME_LEVEL: {
      return produce(state, (draftState) => {
        draftState.household.income_level = action.payload;
      });
    }

    // HEALTHCARE
    case HealthcareActionTypes.UPDATE_TYPICAL: {
      const { id, care, value } = action.payload;
      return produce(state, (draftState) => {
        // @ts-ignore
        draftState.healthcare[id].typical![care] = value;
        // @ts-ignore
        draftState.healthcare[id].typical!.source = DataSource.USER;
      });
    }

    case HealthcareActionTypes.UPDATE_HAS_EXPECTED_YTD: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.healthcare[id].has_expected_ytd = value;
      });
    }

    case HealthcareActionTypes.UPDATE_HAS_EXPECTED_DTY: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.healthcare[id].has_expected_dty = value;
      });
    }

    case HealthcareActionTypes.UPDATE_HAS_SPECIAL: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.healthcare[id].has_special = value;
      });
    }

    case HealthcareActionTypes.UPDATE_SPECIAL_CARE: {
      const { id, care, value } = action.payload;
      return produce(state, (draftState) => {
        // @ts-ignore
        draftState.healthcare[id].special![care] = value;
      });
    }

    case HealthcareActionTypes.UPDATE_SPECIAL_USE: {
      const { id, field, care, use, value } = action.payload;
      return produce(state, (draftState) => {
        //draftState.healthcare[id][field].is_special_absolute = isAbsolute;
        // @ts-ignore
        // @ts-ignore
        draftState.healthcare[id][field]![care]![use] = value;
      });
    }

    case HealthcareActionTypes.CONVERT_SPECIAL_RELATIVE_TO_ABSOLUTE: {
      const { id, field, typicalUsageValues } = action.payload;
      return produce(state, (draftState) => {
        // If it's already converted, skip
        if (
          // @ts-ignore
          draftState.healthcare[id][field]!.is_special_absolute ||
          !typicalUsageValues
        ) {
          return;
        }
        for (const care in HealthcareAreas) {
          // @ts-ignore
          const careKey = HealthcareAreas[care];
          // @ts-ignore
          const typicalUsageLevel = draftState.healthcare[id].typical[careKey];
          const serviceKeys = Object.keys(
            // @ts-ignore
            draftState.healthcare[id][field]![careKey],
          );

          for (const use of serviceKeys) {
            // Add the relative value to the typical usage preset value
            const absoluteValue =
              typicalUsageValues[id]?.[careKey]?.[typicalUsageLevel][use] +
              // @ts-ignore
              draftState.healthcare[id][field]![careKey][use];

            // Set to absolute value
            // @ts-ignore
            draftState.healthcare[id][field]![careKey][use] = absoluteValue;
          }
        }

        // @ts-ignore
        draftState.healthcare[id][field].is_special_absolute = true;
      });
    }

    case HealthcareActionTypes.UPDATE_HEALTHCARE_ACCOUNT: {
      const { id, value } = action.payload;
      return produce(state, (draftState) => {
        draftState.healthcare[id] = { ...value };
      });
    }

    case CommonActionTypes.RESET_STORE: {
      return {
        ...initialState,
        initialized: false,
      };
    }

    default:
      return state;
  }
};
