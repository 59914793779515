/* eslint-disable */
// @ts-nocheck
import { Tracker, defaultPageViewPath, defaultPageViewTitle } from './tracker';

export class HeapTracker implements Tracker {
  private eventConfig: { [key: string]: any } = {};
  private hasIdentified: boolean = false;
  private hasUserProperties: boolean = false;

  constructor(private options: { appId: string }) {
    (window.heap = window.heap || []),
      (heap.load = function (e, t) {
        (window.heap.appid = e), (window.heap.config = t = t || {});
        var r = document.createElement('script');
        (r.type = 'text/javascript'),
          (r.async = !0),
          (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js');
        var a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(r, a);
        for (
          var n = function (e) {
              return function () {
                heap.push &&
                  heap.push(
                    [e].concat(Array.prototype.slice.call(arguments, 0)),
                  );
              };
            },
            p = [
              'addEventProperties',
              'addUserProperties',
              'clearEventProperties',
              'identify',
              'resetIdentity',
              'removeEventProperty',
              'setEventProperties',
              'track',
              'unsetEventProperty',
            ],
            o = 0;
          o < p.length;
          o++
        )
          heap[p[o]] = n(p[o]);
      });
    window.heap.load(options.appId);
    this.hasIdentified = false;
    this.hasUserProperties = false;
  }

  private internalTrack(eventName: string, params: { [key: string]: any }) {
    // use externalId as userId when present (e.g. Benefitfocus memberId)
    const {
      externalId,
      userId: defaultUserId,
      ...userProperties
    } = this.eventConfig;
    const userId = externalId || defaultUserId;
    if (userId && !this.hasIdentified) {
      window.heap.identify(userId);
      this.hasIdentified = true;
    }
    if (
      userId &&
      userProperties.guidanceMode && // use field from household as a sentinel that household data loaded
      !this.hasUserProperties
    ) {
      window.heap.addUserProperties(userProperties);
      this.hasUserProperties = true;
    }
    window.heap.addEventProperties({
      'Guidance Mode': this.eventConfig.guidanceMode,
    });
    window.heap.track(eventName, params);
  }

  config(params: { [key: string]: any }): void {
    Object.assign(this.eventConfig, params);
  }

  pageView(params: { path?: string; title?: string; [key: string]: any }) {
    //console.log(`page-view`)
    this.internalTrack('page_view', {
      path: params.path ?? defaultPageViewPath(),
      title: params.title ?? defaultPageViewTitle(),
      ...params,
    });
  }

  track({
    action,
    category,
    label,
    value,
    ...rest
  }: {
    action: string;
    category: string;
    label: string;
    value?: number;
    [key: string]: any;
  }) {
    this.internalTrack('event', {
      category,
      action,
      label,
      value,
      ...rest,
    });
  }
}
