export default {
  DEPLOY_ENV: 'production',
  SENTRY: {
    ORG: 'savvi',
    PROJECT: 'myhealthwealth',
    DSN: 'https://8b115a313811440a8fcd6963fc6958d3@o138561.ingest.sentry.io/5816125',
  },
  SITE: {
    LAYOUT: 'standalone',
    COLORMODE: 'light',
    ID: 'myvoyage',
    NAME: 'Personalized Enrollment Guidance',
    EMAIL_SUPPORT: 'myhealthwealth@savvifi.com',
    API_URL: 'https://api.myvoyageguide.com/v1',
    APP_ID: '5a74bc0b-2da3-4bc9-bbac-d30eeb5b3671',
    CLIENT_ID: '5db3e45f-46e2-4ab4-8d7a-93df7e7d8e9d',
    RECAPTCHA_SITE_KEY: '6LcUJsAUAAAAAIKR8ZePV5dlVxl-EasC5tDN8x8T',
    QASE_API_TOKEN: '',
    EXTERNAL_AUTH_ONLY: false,
    LOCALES: ['en-US', 'es-US'],
    ENABLE_DEVELOPER_TOOLS: false,
  },
  FEATURES: {
    IS_DEMO_SITE: false,
    DISABLE_DEFAULT_SIGNUP_REDIRECT: false,
    DISABLE_PRIVACY_MODAL: false,
    ENABLE_CUSTOM_BUNDLES: true,
    ENABLE_RETURNING_USER: true,
    ENUMERATED_CONTENT_REVIEW: false,
    PREMIUM_DISPLAY: true,
    ENABLE_EVENT_TRACKING_DEBUG: false,
  },
  SETTINGS: {
    LOGO_ON_SUBMIT: false,
    ANALYTICS: {
      AMPLITUDE_API_KEY: '38bfd5a1c6eabaa90a9b3500c5e62fe1',
      GA4_MEASUREMENT_ID: 'G-HZGQ5YCEXB',
    },
  },
};
