import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  title: msg`Are you planning anything out of the ordinary for healthcare for the rest of this plan year?`,
  intro: md(
    msg`We know you don’t have a crystal ball, but factoring in planned
    medical expenses (like having a baby or a scheduled surgery) will help
    us determine the right combination of coverage for those specific
    needs.`,
  ),
  calloutTitle: msg`Heads up`,
  callout:
    md(msg`To protect your privacy, the numbers shown here are pre-filled based on
  statistical data associated with the ages of the members of your
  household and **usage categories** selected on the [previous screen](/average-usage), and not your actual
  claims data. Make sure to update these levels for any planned medical expenses for the rest of this plan year.`),
};
