import { msg } from '@lingui/macro';
import { md } from 'content/types';
import { formatCurrency } from 'helpers/common';

export default {
  modalHealthScreenTitle: msg`Health Benefits: Good health is wealth after all`,
  modalProtectionScreenTitle: msg`Protection Funding: Be prepared for a rainy day`,
  modalRetirementScreenTitle: msg`Retirement Savings: Grow a healthier nest egg`,

  // TODO: update type
  modalHealthScreenSubTitle: ({
    midyearBenefitsSummarySkipped,
    oop,
  }: {
    midyearBenefitsSummarySkipped: boolean;
    oop: number;
  }) =>
    midyearBenefitsSummarySkipped
      ? md(
          msg`For average healthcare usage under a statistically average health plan, we would estimate about 
**${formatCurrency(oop)}** in medical out-of-pocket costs this year`,
        )
      : md(msg`Based on what you told us, we estimated about
**${formatCurrency(oop)}** in medical out-of-pocket costs this year.`),
};
