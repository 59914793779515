import { MessageDescriptor } from '@lingui/core';
import { useLingui } from '@lingui/react';
import { isArray } from 'lodash';
import { useCallback } from 'react';

export type MaybeMessageDescriptor =
  | MessageDescriptor
  | string
  | null
  | undefined;

export type RenderMaybeMessageDescriptor = (
  message: MaybeMessageDescriptor,
) => string;

export const useMaybeLingui = () => {
  const { _ } = useLingui();

  const _maybe: RenderMaybeMessageDescriptor = useCallback(
    (message: MaybeMessageDescriptor) => {
      if (typeof message === 'string') {
        return message;
      }
      if (message) {
        return _(message);
      }
      return '';
    },
    [_],
  );

  return {
    _: _maybe,
    _map: (
      messages:
        | string
        | MessageDescriptor
        | string[]
        | MessageDescriptor[]
        | undefined
        | null,
    ) =>
      !messages
        ? messages
        : !isArray(messages)
        ? _maybe(messages)
        : messages.map(_maybe),
  };
};
