/* eslint-disable lingui/no-unlocalized-strings */
import React, { useMemo } from 'react';

const bubbleColor = '#f7f7f7';
const smallBubbleColor = '#e4e4e4';

const positions = [
  { x: 110, y: 110, r: 100 },
  { x: 260, y: 100, r: 20 },
  { x: 200, y: 170, r: 40 },
];

type Props = {
  variant?: 'small' | 'normal';
  startPosition?: number;
};

const Loading = ({ variant = 'normal', startPosition }: Props) => {
  // Set animation delay to start on mount only. CSS animation takes over from there
  const animationDelay = useMemo(
    () => (startPosition ? `animation-delay: -${startPosition}ms;` : ''),
    [],
  );

  return variant === 'normal' ? (
    <>
      <style>
        {positions.map((p, i) => {
          const states = [...positions.slice(i), ...positions.slice(0, i)];

          return `
          @keyframes loadingBubbleMove${i} {
            ${states
              .map(
                (s, j) => `
            ${(j * 100) / (states.length - 1)}% {
              cx: ${s.x}px;
              cy: ${s.y}px;
              r: ${s.r}px;
            }`,
              )
              .join('\n')}
          }
          .loadingBubble${i} {
            animation: 3600ms ease-in-out loadingBubbleMove${i};
            animation-iteration-count: infinite;
            ${animationDelay}
          }
        `;
        })}
      </style>

      <svg viewBox="0 0 335 255">
        {positions.map((p, i) => (
          <circle
            key={i}
            className={`loadingBubble${i}`}
            fill={bubbleColor}></circle>
        ))}
      </svg>
    </>
  ) : (
    <>
      <svg viewBox="0 0 75 42">
        <circle
          className="smallLoadingRing0"
          stroke={smallBubbleColor}
          strokeWidth={1}
          fill="none"></circle>
        <circle
          className="smallLoadingRing1"
          stroke={smallBubbleColor}
          strokeWidth={1}
          fill="none"></circle>
        <circle
          className="smallLoadingBubble0"
          fill={smallBubbleColor}></circle>
        <circle
          className="smallLoadingBubble1"
          fill={smallBubbleColor}></circle>
      </svg>
    </>
  );
};

export default Loading;
