import React from 'react';
import { SITE } from '../../../site.config';

// New context
export const DebugContext = React.createContext({
  options: {} as DebugOptions,
  setOptions: {} as React.Dispatch<React.SetStateAction<DebugOptions>>,
});

// Debug options
export type DebugOptions = {
  enabled: boolean;
  panelOpen: boolean;
  customOnDate: string;
  customOnDateEnabled: boolean;
};

// Provider for the context
export const DebugProvider = ({ children }: { children: React.ReactNode }) => {
  // Previous options
  const savedOptions = JSON.parse(localStorage.getItem('savvi-debug') || '{}');

  // Check if the debug flag is enabled
  const debugFlag = window.location.search.includes('debug');

  // State for the options
  const [options, setOptions] = React.useState<DebugOptions>({
    ...savedOptions,
    enabled:
      SITE.ENABLE_DEVELOPER_TOOLS && (savedOptions.enabled || debugFlag)
        ? true
        : false,
  } as DebugOptions);

  // Listen to the options and write to localstorage
  React.useEffect(() => {
    if (SITE.ENABLE_DEVELOPER_TOOLS && options.enabled) {
      localStorage.setItem('savvi-debug', JSON.stringify(options));
    } else {
      localStorage.removeItem('savvi-debug');
    }
  }, [options]);

  // Render
  return (
    <DebugContext.Provider
      value={{
        options,
        setOptions,
      }}>
      {children}
    </DebugContext.Provider>
  );
};

// Make it clear
export default DebugContext;
