import { useDebug } from 'hooks/useDebug';
import React from 'react';

const Panel = React.lazy(() => import('./panel'));

export const DeveloperTools = () => {
  const { enabled } = useDebug();
  return enabled ? <Panel /> : null;
};

export default DeveloperTools;
