import { RouteNames } from '../RouteNames';
import {
  ApiStatus,
  DataSource,
  Dictionary,
  PayFrequency,
  Role,
  StatesOfResidence,
} from './common';
import {
  HealthcareSpecific,
  HealthcareSpecial,
  HealthcareExplicit,
  HealthcareServiceUsage,
  defaultHealthcareSpecial,
} from './healthcare';
import {
  Customizer,
  SummaryBenefitItem,
  SummaryResponseBundle,
} from './summary';
import { GuidanceMode, HealthbenefitsMode } from 'selectsmart-api';

export interface Household {
  eligibility_class: string | null;
  household_id: string;
  income_level: number | null;
  id: string | undefined;
  employer_id: string | null;
  state_of_residence: StatesOfResidence | null;
  zip_code: string | null;
  eligibility: Dictionary<string> | null;
  last_location: RouteNames | null;
  disclosure_accepted_at: Date | null;
  max_progress: number | null;
  external_ids: Dictionary<string> | null;
  consider_spousal_benefits: boolean | null;
  created_at: string | null;
  updated_at: string | null;
  guidance_mode: GuidanceMode | null;
  healthbenefits_mode: HealthbenefitsMode | null;
  guidance_mode_started_at: string | null;
  guidance_mode_completed_at: string | null;
  ages: any | null;
  allIds: any | null;
  clientId: string | null;
  spouseId: string | null;
  initialized: boolean;
  consider_split_benefits: boolean;
  available_guidance_modes: GuidanceMode[];
  effective_date: string;
  has_claims_data: boolean | null;
  has_claims_optin: boolean | null;
  is_benadmin_submit_enabled: boolean | null;
  household_level: number | null;
}

export interface Person {
  created_at?: any;
  id: string;
  household_id?: string;
  employer_id?: string;
  employee_id?: string;
  source?: DataSource;
  source_id?: string | null;
  external_ids?: {
    [k: string]: string;
  } | null;
  role: Role;
  birthdate?: string;
  is_birthdate_yearmonth_only: boolean;
  is_covered?: boolean;
  has_medicare?: boolean;
  medicare_status?: string;
  is_covered_source?: DataSource;
  first_name?: string | null;
  last_name?: string | null;
}

export interface TobaccoUse {
  household_id?: string;
  person_id: string;
  source?: DataSource;
  source_id?: string | null;
  is_tobacco_user: boolean | null;
  is_tobacco_cessation?: boolean | null;
  is_tobacco_cessation_source?: DataSource;
}

export interface PersonIncome {
  household_id?: string;
  person_id: string;
  source?: DataSource;
  source_id?: string | null;
  annual_pay: number;
  pay_frequency: PayFrequency;
  pay_schedule?: string | null;
  is_parttime?: boolean;
  medical_bar?: string;
  annual_pay_source?: DataSource;
  other_income: number;
  is_other_income_se?: boolean;
  other_income_source?: DataSource;
  is_receiving_social_security?: boolean;
  monthly_social_security?: number;
  social_security_start_age?: number;
}

export interface PersonHealthcare {
  household_id?: string;
  person_id?: string;
  typical: HealthcareSpecific;
  has_typical?: boolean;
  has_expected_ytd: boolean;
  has_expected_dty: boolean;
  expected_ytd: HealthcareSpecial | null;
  expected_dty: HealthcareSpecial | null;
  has_special: boolean;
  special: HealthcareSpecial;
  has_explicit?: boolean;
  explicit?: HealthcareExplicit[] | null;
}

export interface BundleResponse {
  id?: string;
  status?: ApiStatus | null;
  selected_key: string | null;
  bundles: Dictionary<SummaryResponseBundle>;
  benefits: Dictionary<SummaryBenefitItem>;
  customizer: Customizer;
  contribution_limits: any | null;
}

export function createDefaultPerson(id: string, role: Role): Person {
  return {
    id,
    birthdate: '',
    is_covered: true,
    role,
    is_birthdate_yearmonth_only: true,
  };
}

export function createDefaultTobaccoUse(person_id: string): TobaccoUse {
  return {
    person_id,
    is_tobacco_user: null,
    is_tobacco_cessation: null,
  };
}

export function createDefaultPersonIncome(person_id: string): PersonIncome {
  return {
    person_id,
    annual_pay: 0,
    pay_frequency: PayFrequency.BIWEEKLY,
    pay_schedule: null,
    other_income: 0,
    is_other_income_se: false,
  };
}

export function createDefaultPersonHealthcare(
  person_id: string,
): PersonHealthcare {
  return {
    person_id,
    has_typical: true,
    typical: {
      medical_care: HealthcareServiceUsage.LOW,
      prescription_drugs: HealthcareServiceUsage.LOW,
      dental_care: HealthcareServiceUsage.LOW,
      vision_care: HealthcareServiceUsage.LOW,
    },
    has_expected_dty: false,
    has_expected_ytd: false,
    expected_dty: null,
    expected_ytd: null,
    has_special: false,
    special: defaultHealthcareSpecial,
    has_explicit: false,
    explicit: [],
  };
}

export const householdInitialState: Household = {
  id: '',
  employer_id: '',
  state_of_residence: null,
  eligibility: {},
  last_location: null,
  disclosure_accepted_at: null,
  max_progress: 0,
  external_ids: {},
  // @ts-ignore
  guidance_mode: '',
  guidance_mode_started_at: '',
  guidance_mode_completed_at: '',
  ages: {},
  allIds: [],
  clientId: '',
  spouseId: '',
  initialized: true,
  consider_spousal_benefits: false,
  consider_split_benefits: false,
  zip_code: null,
  created_at: null,
  updated_at: null,
  available_guidance_modes: [],
  effective_date: '',
  has_claims_data: null,
  has_claims_optin: null,
};

export type SpecialUsageType = 'expected_ytd' | 'expected_dty' | 'special';
