import { NavigationStepConfig } from 'interfaces/navigation';
import { defaultFirstStep } from 'navigationConfig';
import React from 'react';
import { RouteNames } from '../../RouteNames';

export type AvailableFormStepsContextType = {
  formSteps: RouteNames[];
  userJourney: NavigationStepConfig[];
  defaultFirstStep: RouteNames;
};

export const AvailableFormStepsContext =
  React.createContext<AvailableFormStepsContextType>({
    formSteps: [],
    userJourney: [],
    defaultFirstStep,
  });
