import { merge } from 'lodash';
import overrides from './overrides';
import guidanceMode from './guidanceMode';
import profileHousehold from './profileHousehold';
import submit from './submit';
import profileIncome from './profileIncome';
import profileMidyearPlanDetails from './profileMidyearPlanDetails';
import healthcare from './healthcare';
import healthcareSpecial from './healthcareSpecial';
import healthcareDTY from './healthcareDTY';
import healthcareYTD from './healthcareYTD';
import benefitsSpouse from './benefitsSpouse';
import benefitsProfile from './benefitsProfile';
import results from './results';
import disclaimers from './disclaimers';
import legal from './legal';
import pageTitles from './pageTitles';
import errors from './errors';
import budgeting from './budgeting';
import profileHouseholdNewHire from './profileHouseholdNewHire';
import benefitsProfileMidyearUpdate from './benefitsProfileMidyearUpdate';
import notTheToolForYou from './notTheToolForYou';
import product from './product';

export const defaults = {
  product,
  pageTitles,
  errors,
  screen: {
    guidanceMode,
    budgeting,
    profileHousehold,
    profileHouseholdNewHire,
    profileIncome,
    profileMidyearPlanDetails,
    healthcare,
    healthcareSpecial,
    healthcareYTD,
    healthcareDTY,
    benefitsSpouse,
    benefitsProfile,
    benefitsProfileMidyearUpdate,
    results,
    submit,
    notTheToolForYou,
  },
  disclaimers,
  legal,
};

export default merge(defaults, overrides);
