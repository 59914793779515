import { useContext } from 'react';

import { EventTrackingContext } from 'context/eventTracking';

export function useTrackEvent() {
  // Wire up
  const state = useContext(EventTrackingContext);

  // Return
  return {
    tracker: state?.tracker,
  };
}
