import { Box, SxProps, Theme } from '@mui/material';
import * as React from 'react';
import { PropsWithChildren } from 'react';

export interface Props {
  testID?: string;
  sx?: SxProps<Theme>;
}

export const View: React.FC<PropsWithChildren<Props>> = ({
  testID,
  sx,
  children,
}) => {
  const defaultStyles: SxProps<Theme> = {
    alignItems: 'stretch',
    // eslint-disable-next-line lingui/no-unlocalized-strings
    backgroundColor: 'rgba(0, 0, 0, 0)',
    // eslint-disable-next-line lingui/no-unlocalized-strings
    border: '0px solid black',
    boxSizing: 'border-box',
    display: 'flex',
    flexBasis: 'auto',
    flexDirection: 'column',
    flexShrink: 0,
    listStyle: 'none',
    margin: '0px',
    minHeight: '0px',
    minWidth: '0px',
    padding: '0px',
    position: 'relative',
    textDecoration: 'none',
  };

  return (
    <Box data-testid={testID} sx={{ ...defaultStyles, ...(sx || {}) }}>
      {children}
    </Box>
  );
};
