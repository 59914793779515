// System
import React from 'react';
import { isFunction, mergeWith } from 'lodash';

// Mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

// Theme giblets
import * as baseThemeOptions from './config/base';
import * as siteThemeOptions from './config/site';

// Wire up to App
import { useApp } from 'context/app';

export const mobileLayoutBreakpoint = 'md';

// Override object data
function merge(target: any, source: any) {
  const combine = (left: any, right: any) => {
    const leftIsFunc = isFunction(left);
    const rightIsFunc = isFunction(right);

    if (leftIsFunc || rightIsFunc) {
      return (...args: any[]) => {
        const l = leftIsFunc ? left(...args) : left;
        const r = rightIsFunc ? right(...args) : right;
        return merge(l, r);
      };
    }
  };

  return mergeWith(target, source, combine);
}

// Build these once
const themeLight = createTheme({
  palette: merge(baseThemeOptions.paletteLight, siteThemeOptions.paletteLight),
  typography: merge(baseThemeOptions.typography, siteThemeOptions.typography),
  components: merge(baseThemeOptions.components, siteThemeOptions.components),
});
const themeDark = createTheme({
  palette: merge(baseThemeOptions.paletteDark, siteThemeOptions.paletteDark),
  typography: merge(baseThemeOptions.typography, siteThemeOptions.typography),
  components: merge(baseThemeOptions.components, siteThemeOptions.components),
});

// Provider for our UI
export const ThemeProvider = ({ children }: any) => {
  // Wire up to app
  const app = useApp();

  // Our current choice, listening to changes upstream
  const theme = React.useMemo(
    () => (app.colorMode === 'light' ? themeLight : themeDark),
    [app.colorMode],
  );

  // Render
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

// Make it clear
export default ThemeProvider;
