import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  title: msg`Since the start of this plan year, has anything about your healthcare usage been out of the ordinary?`,
  intro: md(
    msg`Previously we asked about your healthcare usage in an average year.
    Was anything about your actual usage up until now
    **out of the ordinary** compared to a typical year?`,
  ),
  calloutTitle: msg`Heads up`,
  callout:
    md(msg`To protect your privacy, the numbers shown here are pre-filled based on
  statistical data associated with the ages of the members of your
  household and **usage categories** selected on the [previous screen](/average-usage), and not your actual
  claims data. Make sure to update these levels for any out of the
  ordinary medical expenses that happened earlier this plan year.`),
};
