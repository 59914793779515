import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import React, { PropsWithChildren, createContext } from 'react';
import { useEffect } from 'react';
import { detect, fromUrl } from '@lingui/detect-locale';
import { SITE } from '../site.config';
import { pick } from 'lodash';

const enabledLocales = SITE.LOCALES || ['en-US'];

export const locales = pick(
  {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    'en-US': 'English',
    // eslint-disable-next-line lingui/no-unlocalized-strings
    'es-US': 'Español',
    // eslint-disable-next-line lingui/no-unlocalized-strings
    'pseudo-LOCALE': 'Pseudo test',
  },
  enabledLocales,
);

export type Locale = keyof typeof locales;

export const defaultLocale = enabledLocales[0] as Locale;

const detectedLocale = detect(fromUrl('lang'), defaultLocale);

const startLocale =
  detectedLocale && Object.keys(locales).includes(detectedLocale)
    ? (detectedLocale as Locale)
    : defaultLocale;

export type CurrentLocaleContextType = {
  currentLocale: Locale;
  setLocale: (locale: Locale) => void;
  localeIsValid: (localeString: string) => localeString is keyof typeof locales;
};

export const CurrentLocaleContext =
  createContext<CurrentLocaleContextType | null>(null);

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
async function dynamicActivate(locale: string) {
  const lang = locale === 'pseudo-LOCALE' ? locale : locale.split('-')[0];
  if (!lang) {
    // eslint-disable-next-line no-console
    console.error(`Invalid locale language: ${locale}`);
    return;
  }
  const { messages } = await import(`./locales/${lang}/messages.po`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}

const I18nCustomProvider = ({ children }: PropsWithChildren) => {
  const [currentLocale, setCurrentLocale] = React.useState<Locale>(startLocale);

  const setLocale = (locale: Locale) => {
    setCurrentLocale(locale);
  };

  function localeIsValid(
    localeString: string,
  ): localeString is keyof typeof locales {
    return Object.keys(locales).includes(localeString);
  }

  useEffect(() => {
    dynamicActivate(currentLocale);
  }, [currentLocale]);

  return (
    <CurrentLocaleContext.Provider
      value={{
        currentLocale,
        setLocale,
        localeIsValid,
      }}>
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </CurrentLocaleContext.Provider>
  );
};

export default I18nCustomProvider;

export const useCurrentLocale = () => {
  const context = React.useContext(CurrentLocaleContext);
  if (!context) {
    throw new Error(
      // eslint-disable-next-line lingui/no-unlocalized-strings
      'useCurrentLocale must be used within a CurrentLocaleContext',
    );
  }
  return context;
};
