/* eslint-disable lingui/no-unlocalized-strings */
/*
    A little form library built on top of react-hook-form and yup.
*/
import React, {
  useMemo,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  PropsWithChildren,
  RefObject,
} from 'react';
import {
  useForm,
  useFormState,
  FieldError,
  Control,
  UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { get, isEqual, isNil, set } from 'lodash';
import { Box } from '@mui/material';
import PageActivityIndicator from 'components/common/PageActivityIndicator';

// re-export react-hook-form, especially convenient for Control type
// and Controller component used in writing field components
export * from 'react-hook-form';

function schemaWithDefaultLabels<
  T extends object | null | undefined = object | undefined,
  C = object,
  // @ts-ignore
>(schema: ObjectSchema<T, C>) {
  // @ts-ignore
  for (const [fieldName, fieldSchema] of Object.entries(schema.fields)) {
    // @ts-ignore
    if (!fieldSchema._label) {
      // @ts-ignore
      schema.fields[fieldName] = fieldSchema.label(`This ${fieldSchema.type}`);
    }
  }
  return schema;
}

// custom error message that can be thrown by onSubmit to indicate that the
// submit failed because it was invalid. typically, this would be used when
// a backend request fails even after the front-end validation is successful
export class ValidationError extends Error {
  fields: { [n: string]: string };
  type: string;

  constructor(
    message: string,
    fields: { [n: string]: string } = {},
    type = 'server',
  ) {
    super(message);
    this.name = 'ValidationError';
    this.fields = fields;
    this.type = type;

    // maintain proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }
  }
}

type FormState = {
  isDirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  isSubmitSuccessful: boolean;
  isLoading?: boolean;
};

interface FormManagerContextProps {
  getFormState: (formRef: any) => FormState | undefined;
  setFormState: (formRef: any, state: (s: FormState) => FormState) => void;
  removeForm: (formRef: any) => void;
}

const FormManagerContext = React.createContext<
  FormManagerContextProps | undefined
>(undefined);

export const FormManager = ({
  renderChildren,
}: {
  renderChildren: ({
    submit,
    isValid,
    isDirty,
    isSubmitting,
    isSubmitSuccessful,
    isLoading,
    triggerFormValidation,
  }: {
    submit: () => Promise<void>;
    isValid: boolean;
    isDirty: boolean;
    isSubmitting: boolean;
    isSubmitSuccessful: boolean;
    isLoading: boolean;
    triggerFormValidation: () => Promise<boolean>;
  }) => React.ReactNode;
}) => {
  const [formStates, setFormStates] = useState<Map<any, FormState>>(new Map());

  const getFormState = useCallback(
    (formRef: any) => formStates.get(formRef),
    [formStates],
  );

  const setFormState = useCallback(
    (formRef: any, state: FormState | ((s: FormState) => FormState)) => {
      setFormStates((s) => {
        const existingState = s.get(formRef);
        const newState =
          typeof state === 'function'
            ? state(
                existingState || {
                  isDirty: false,
                  isValid: false,
                  isSubmitting: false,
                  isSubmitSuccessful: false,
                  // Default to isLoading
                  isLoading: true,
                },
              )
            : state;

        if (!isEqual(newState, existingState)) {
          return new Map([...s, [formRef, newState]]);
        }
        return s;
      });
    },
    [setFormStates],
  );

  const removeForm = useCallback(
    (formRef: any) => {
      setFormStates(
        (s) =>
          new Map(Array.from(s.entries()).filter(([key]) => key !== formRef)),
      );
    },
    [setFormStates],
  );

  const submit = useCallback(async () => {
    for (const [formRef, formInfo] of formStates.entries()) {
      // submit each form in sequence, one form at a time
      // throw/abort on first exception
      await formRef.current.submit({
        isDirty: formInfo.isDirty,
      });
    }
  }, [formStates]);

  const isValid = useMemo(
    () =>
      Array.from(formStates.values()).every(
        (state: FormState) => state.isValid,
      ),
    [formStates],
  );

  const isDirty = useMemo(
    () =>
      Array.from(formStates.values()).some((state: FormState) => state.isDirty),
    [formStates],
  );

  const isSubmitting = useMemo(
    () =>
      Array.from(formStates.values()).some(
        (state: FormState) => state.isSubmitting,
      ),
    [formStates],
  );

  const isSubmitSuccessful = useMemo(
    () =>
      Array.from(formStates.values()).some(
        (state: FormState) => state.isSubmitSuccessful,
      ),
    [formStates],
  );

  const isLoading = useMemo(
    () =>
      // If no forms are initialized, the form is still loading
      formStates.size === 0 ||
      Array.from(formStates.values()).some(
        (state: FormState) => state.isLoading,
      ),
    [formStates],
  );

  const triggerFormValidation = useCallback(async () => {
    const isValid = (
      await Promise.all(
        Array.from(formStates.keys()).map((ref) => ref.current.validateForm()),
      )
    ).every((result) => result);
    return isValid;
  }, [formStates]);

  return (
    <FormManagerContext.Provider
      value={{ setFormState, getFormState, removeForm }}>
      {renderChildren({
        submit,
        isValid,
        isDirty,
        isLoading,
        isSubmitting,
        isSubmitSuccessful,
        triggerFormValidation,
      })}
    </FormManagerContext.Provider>
  );
};

export type Form = Omit<UseFormReturn, 'handleSubmit'> & {
  submit: () => Promise<void>;
};

export type FormOptions = {
  // These options control which fields are submitted.
  // all - all form fields are submitted
  // dirty-only - only fields that have been modified are submitted
  // root-level-dirty - only submits top level fields/objects that are dirty,
  //       but will ignore dirty flags within the object. i.e. full object is submitted
  //       if anything within it is dirty
  validateDefaultValues?: boolean;
  dirtySubmissionMode?: 'all' | 'dirty-only' | 'root-level-dirty';
  mode?: 'onSubmit' | 'onChange' | 'onBlur' | 'onTouched' | 'all';
  defaultValues?: { [key: string]: any };
  defaultValuesShouldDirty?: boolean;
};

const dirtyFieldsToPropsList = (path: string[], root: any): any => {
  return Object.keys(root).reduce((accum: string[], key) => {
    if (typeof root[key] === 'object') {
      return [...accum, ...dirtyFieldsToPropsList([...path, key], root[key])];
    } else if (root[key] === true) {
      return [...accum, [...path, key].join('.')];
    }
    return accum;
  }, []);
};

type FormList = (
  schema: any,
  initialState: any,
  onSubmit: (data: any) => Promise<void>,
  options: FormOptions,
) => [RefObject<any>, Form & { submit: () => Promise<void> }];

export const useFormalist: FormList = (
  schema,
  initialState,
  onSubmit,
  {
    dirtySubmissionMode = 'root-level-dirty',
    mode = 'onChange',
    defaultValues,
    defaultValuesShouldDirty = false,
  } = {},
) => {
  const [initialized, setInitialized] = useState(!isNil(initialState));
  const formRef = useRef({
    submit: ({ isDirty }: { isDirty: boolean }) =>
      new Promise(() => (isDirty ? undefined : undefined)),
    validateForm: async () => false,
  });

  const formSetContext = useContext(FormManagerContext);

  const resolver = useMemo(
    () => (schema ? yupResolver(schemaWithDefaultLabels(schema)) : undefined),
    [schema],
  );
  const { handleSubmit, ...form } = useForm({
    resolver,
    mode,
    defaultValues: initialState,
    delayError: 500,
  });

  const { isValid, isDirty, isSubmitting, isSubmitSuccessful, dirtyFields } =
    useFormState({
      control: form.control,
    });

  // Initialize form. This should happen only once when initialState is set
  // default values could possibly be undefined, and then update to a value if
  // it is controlled by react-query. Once a non-nil value is set, we should
  // set the form for the first time and not update it again.
  useEffect(() => {
    if (!isNil(initialState) && !initialized) {
      form.reset(initialState);
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState, initialized]);

  // Default values
  useEffect(() => {
    // Handle the default values
    if (initialized && defaultValues) {
      Object.keys(defaultValues).forEach((key) => {
        if (isNil(form.getValues(key))) {
          form.setValue(key, defaultValues[key], {
            shouldDirty: defaultValuesShouldDirty,
          });
        }
      });
    }
    // Don't include form or defaultValues in dependencies
    // These can cause infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, defaultValuesShouldDirty]);

  const triggerValidation = form.trigger;

  const submit = useCallback(async () => {
    if (isDirty) {
      try {
        await handleSubmit((data) => {
          if (dirtySubmissionMode === 'dirty-only') {
            const dirtyFieldsList = dirtyFieldsToPropsList([], dirtyFields);
            data = dirtyFieldsList.reduce(
              (obj: { [k: string]: any }, key: string) => {
                set(obj, key, get(data, key));
                return obj;
              },
              {},
            );
          } else if (dirtySubmissionMode === 'root-level-dirty') {
            data = Object.keys(dirtyFields).reduce(
              (obj: { [k: string]: any }, key: string) => {
                obj[key] = data[key];
                return obj;
              },
              {},
            );
          }
          return onSubmit(data).then(() => form.reset(() => data));
        })();
      } catch (error) {
        if (error instanceof ValidationError) {
          // special handling of ValidationError to allow onSubmit to report
          // async validation errors like from a server response. this requires
          // cooperation from call site to check the response and to prevent
          // default action, for example
          const error_type = error.type;
          Object.entries(error.fields).forEach(([name, message]) =>
            form.setError(name, { type: error_type, message }),
          );
          throw error;
        } else {
          // all other errors bubble up
          throw error;
        }
      }
    }
  }, [
    handleSubmit,
    onSubmit,
    dirtyFields,
    form,
    dirtySubmissionMode,
    form.setError,
  ]);

  useEffect(() => {
    formSetContext?.setFormState(formRef, (s) => ({
      ...s,
      isValid,
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
    }));
    return () => {
      formSetContext?.removeForm(formRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef]); // do not include formSetContext

  useEffect(() => {
    formRef.current.submit = submit;
    formRef.current.validateForm = triggerValidation;
    formSetContext?.setFormState(formRef, (s) => ({
      ...s,
      isValid,
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
    }));
  }, [
    triggerValidation,
    submit,
    isValid,
    isDirty,
    isSubmitting,
    isSubmitSuccessful,
    formSetContext?.setFormState,
  ]);

  return [formRef, { ...form, submit }];
};

interface FormControlContextProps {
  isLoading?: boolean;
  control?: Control;
  testID?: string | undefined;
}

type FormProps = {
  formRef: RefObject<any>;
  fieldLevelLoadingIndicator?: boolean;
} & FormControlContextProps;

const FormControlContext = React.createContext<FormControlContextProps>({});

export const Form: React.FC<PropsWithChildren<FormProps>> = ({
  formRef,
  isLoading,
  control,
  testID,
  fieldLevelLoadingIndicator,
  children,
}) => {
  const formSetContext = useContext(FormManagerContext);

  useEffect(() => {
    if (formRef) {
      formSetContext?.setFormState(formRef, (s) => {
        return { ...s, isLoading };
      });
    }
  }, [isLoading]);

  return (
    <FormControlContext.Provider value={{ control, testID, isLoading }}>
      {isLoading && !fieldLevelLoadingIndicator ? (
        <Box height="100%" minHeight="200px" position={'relative'}>
          <PageActivityIndicator />
        </Box>
      ) : (
        children
      )}
    </FormControlContext.Provider>
  );
};

export function useFormField(
  name: string,
  control: Control | undefined = undefined,
): {
  control: Control;
  error: FieldError | undefined;
  testID: string;
  isLoading: boolean;
} {
  const controlContext = useContext(FormControlContext);
  const fieldControl = control || controlContext.control;
  const { errors } = useFormState({ control: fieldControl, name });
  if (!fieldControl)
    throw Error('no FormFields parent or control argument to useFormField');
  const testID = controlContext.testID
    ? `${controlContext.testID}-${name}`
    : name;

  const error = get(errors, name) as FieldError | undefined;
  return {
    control: fieldControl,
    error,
    testID,
    isLoading: !!controlContext?.isLoading,
  };
}

/*
Here's an example of how to write an input field component:

export const TextInputField: React.FC<{
  name: string;
  control?: Control | undefined;
  [key: string]: any;
}> = ({ name, control, ...inputProps }) => {
  const field = useFormField(name, control);
  return (
    <Controller // see react-hook-form
      control={field.control}
      render={({
        field: { onChange, onBlur, value },
        formState: { isSubmitting },
      }) => (
        <TextInput
          {...{
            testID: field.testID,
            error: field.error?.message,
            disabled: isSubmitting,
            ...inputProps,
          }}
          onChange={onChange}
          onBlur={onBlur}
          value={`${value}`}
        />
      )}
      name={name}
    />
  );
};
*/
