import produce from 'immer';
import merge from 'lodash/merge';
import {
  ActionMap,
  CommonActionTypes,
  Nullable,
  SerializedStore,
  StateInitialized,
  StatesOfResidence,
} from '../interfaces/common';
import { AuthState, UserData, VerifyAuthMetadata } from '../interfaces/auth';
import { SITE } from '../../site.config';

export enum ActionTypes {
  SETUP_USER_DATA = 'SETUP_USER_DATA',
  CLEAR_INVITE_CODE = 'CLEAR_INVITE_CODE',
}

export type State = AuthState &
  StateInitialized & { app_id: string; client_id: string };

export const initialState: State = {
  email: null,
  invite_code: null,
  metadata: {
    employer_id: '',
    employer_name: '',
    email: '',
    employer_logo_url: '',
  },
  visitor_id: null,
  user: null,
  employer_logo_url: '',
  employer_name: '',
  signin_url: null,
  signin_name: null,
  signin_note: null,
  initialized: false,
  app_id: SITE.APP_ID,
  client_id: SITE.CLIENT_ID,
};

type Payload = {
  [CommonActionTypes.RESET_STORE]: undefined;
  [CommonActionTypes.SETUP_STORE]: Partial<SerializedStore>;
  [CommonActionTypes.SETUP_EMPLOYER_DATA]: {
    employer_id: string;
    employer_name: string;
    default_state_of_residence: StatesOfResidence;
    signin_url: string | null;
    signin_name: string | null;
    signin_note: string | null;
    employer_logo_url?: string;
  };
  [ActionTypes.SETUP_USER_DATA]: {
    user?: Nullable<UserData>;
    invite_code?: string | null;
    metadata?: VerifyAuthMetadata;
    email?: string | null;
    visitor_id?: string | null;
  };
  [ActionTypes.CLEAR_INVITE_CODE]: undefined;
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case CommonActionTypes.SETUP_STORE: {
      const { auth } = action.payload;
      return {
        ...state,
        ...auth,
        initialized: true,
      };
    }

    case CommonActionTypes.RESET_STORE: {
      return {
        ...initialState,
        initialized: true,
      };
    }

    case ActionTypes.SETUP_USER_DATA: {
      // Need to use merge here because of the nested structure of both the state and the action.payload.
      // Otherwise data may get overwritten
      // (e.g. when setting employer_id in metadata from <SignUp />, employer_name was overwritten).
      // action.payload.user.email = null;
      return produce(state, (draftState) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        draftState = { ...merge(draftState, action.payload) };
      });
    }

    case ActionTypes.CLEAR_INVITE_CODE: {
      return produce(state, (draftState) => {
        draftState.invite_code = null;
      });
    }

    case CommonActionTypes.SETUP_EMPLOYER_DATA: {
      return produce(state, (draftState) => {
        const {
          employer_name,
          default_state_of_residence,
          employer_id,
          employer_logo_url,
          signin_url,
          signin_name,
          signin_note,
        } = action.payload;

        draftState.metadata.employer_id = employer_id;
        draftState.metadata.employer_name = employer_name;
        draftState.metadata.default_state_of_residence =
          default_state_of_residence;
        draftState.employer_name = employer_name;
        draftState.employer_logo_url = employer_logo_url;
        draftState.signin_url = signin_url;
        draftState.signin_name = signin_name;
        draftState.signin_note = signin_note;
      });
    }

    default:
      return state;
  }
};
