import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '../src/App';
import { SENTRY } from '../site.config';
import { environment } from '../site.config';
import * as Sentry from '@sentry/react';
import { release } from '../.release.js';

// Only enable for non-local environments
if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: SENTRY.DSN,
    environment,
    debug: false,
    release,
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
