import React, { MouseEventHandler, forwardRef } from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { useTrackEvent } from 'hooks/useTrackEvent';

type Props = MuiButtonProps & {
  a11yPressed?: boolean;
  newWindow?: boolean;
  testID?: string;
  trackingID?: string;
  trackingConfig?: Record<string, string | number | undefined>;
};

export const Button = forwardRef(function Button(
  {
    newWindow,
    a11yPressed,
    testID,
    trackingID,
    trackingConfig,
    onClick,
    ...props
  }: Props,
  ref: React.Ref<HTMLButtonElement>,
) {
  const { tracker } = useTrackEvent();

  const newWindowProps = props.href && newWindow ? { target: '_blank' } : {};

  const a11yProps =
    a11yPressed !== undefined ? { 'aria-pressed': a11yPressed } : {};

  const trackedClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (trackingID) {
      tracker?.track({
        category: 'button',
        action: 'click',
        label: trackingID,
        ...(trackingConfig ?? {}),
      });
    }
    onClick?.(event);
  };

  return (
    <MuiButton
      ref={ref}
      {...newWindowProps}
      {...a11yProps}
      {...props}
      onClick={trackedClick}
      data-testid={testID}
    />
  );
});
