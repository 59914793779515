import { Variant } from '@mui/material/styles/createTypography';
import { MessageDescriptor } from '@lingui/core';

export type RenderOptions = {
  inline?: boolean;
  isTitle?: boolean;
  typographyVariant?: Variant;
  allowRawHtml?: boolean;
  noGutter?: boolean;
};

export type MarkdownContent = {
  value: MessageDescriptor;
  type: 'markdown';
};

export type ContentLeaf = MarkdownContent | MessageDescriptor;

export const md: (value: MessageDescriptor) => MarkdownContent = (value) => ({
  value,
  type: 'markdown',
});

// Data structures
export type SpouseLabelData = {
  spouseLabel: string;
};
