import { msg } from '@lingui/macro';
import { md } from 'content/types';
import { MessageDescriptor } from '@lingui/core';

export default {
  defaultMessage: ({
    customMessage = msg`Error submitting data`,
  }: {
    customMessage?: string | MessageDescriptor;
  }) =>
    md(msg`${customMessage}.
${'_lf'}
Please contact your employer’s benefits team`),

  defaultModalTitle: msg`Something went wrong`,
  defaultModalMessage: md(
    msg`Something went wrong, but we're on it. Please try again later.`,
  ),
  integrationsModalTitle: msg`No member info available`,
  integrationsModalMessage:
    md(msg`For your convenience, we try to pre-populate your guidance with data from your benefits providers. Unfortunately, we were unable to do so this time. 
${'_lf'}
Do you want to retry loading this data, or continue by entering your info manually?`),
};
