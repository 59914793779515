import {
  BenefitSegmentChoiceOption,
  BenefitSegmentQuestionType,
} from './benefits';
import {
  Dictionary,
  Nullable,
  OptionValue,
  PayFrequency,
  StatesOfResidence,
} from './common';
import { SegmentationInfo } from 'selectsmart-api';

export interface EmployerState {
  start_day: number;
  employer_id: string;
  plan_year: number;
  plan_year_label?: string;
  start_month: number;
  enrollment_url?: string;
  enrollment_end_date?: string;
  enrollment_start_date?: string;
  pay_frequencies: PayFrequency[] | null;
  pay_schedules: any;
  benadmin_url?: string | null;
  benadmin_name?: string | null;
  retirementplan_name?: string | null;
  retirementplan_url?: string | null;
  emergencyfund_url?: string;
  emergencyfund_name?: string;
  signin_url: string | null;
  signin_name: string | null;
  signin_note: string | null;
  benefits_disclosure?: string;
  default_state_of_residence: StatesOfResidence | null;
  enable_spousal_benefits: boolean;
  enable_split_benefits: boolean;
  has_domestic_partner_benefits: boolean;
  domestic_partner_disclosure?: string;
  has_tobacco_use: boolean;
  segmentation: BenefitSegmentQuestion[];
  enabled_integrations: Dictionary<string> | null;
  created_at: string;
  updated_at: string;
  employer_name: string;
  employer_description: string;
  employer_logo_url: string;
  custom_content?: EmployerCustomizationContent;
  segmentation_info: SegmentationInfo;
  custom_data?: {
    benefitfocus?: {
      type: 'benefitfocus';
      bf_cancel_url?: string;
      bf_error_url?: string;
      bf_keepalive_url?: string;
      bf_return_url?: string;
    };
  };
}

export interface EmployerCustomizationContent {
  welcome_message: string;
  input_notes: {
    consider_spousal_benefits: string;
    guidance_mode: string;
    covered_people: string;
    client_income: string;
    special_healthcare: string;
    spousal_benefits: string;
    tobacco_use: string;
    health_accounts: string;
    retirement_plan: string;
  };
  supplemental_benefits: {
    benefit_types: {
      accident_indemnity: EmployerCustomizationBasicText;
      illness_indemnity: EmployerCustomizationBasicText;
      hospital_indemnity: EmployerCustomizationBasicText;
    };
    title: string;
    description: string;
    extras: EmployerCustomizationBasicText[];
  };
  health_accounts: {
    title: string;
    description: string;
    benefit_types: {
      hsa: EmployerCustomizationBasicText;
      hra: EmployerCustomizationBasicText;
      fsa: EmployerCustomizationBasicText;
      lpfsa: EmployerCustomizationBasicText;
    };
    extras: EmployerCustomizationBasicText[];
  };
  bundles_notes: {
    bundle_disclosures: string[];
    estimated_cost_disclosures: string[];
  };
  extra_life_insurances: EmployerCustomizationBasicText[];
  extra_ltd_insurances: EmployerCustomizationBasicText[];
  extra_std_insurances: EmployerCustomizationBasicText[];
  actionplan_notes: {
    benefits_reminder: string;
    retirement_reminder: string;
  };
}

export interface EmployerCustomizationBasicText {
  title: string;
  description: string;
}

export interface BenefitSegmentQuestion {
  name: string;
  question: string;
  choices: BenefitSegmentChoiceOption[];
  description?: Nullable<string>;
  only_if_married?: boolean;
  default_value?: string;
  type: BenefitSegmentQuestionType;
}

export type BenefitsSegmentsAnswers = Dictionary<Nullable<OptionValue>>;

export const employerInitialState = {
  employer_id: '',
  plan_year: 0,
  plan_year_label: '0',
  start_month: 0,
  enrollment_url: '',
  enrollment_end_date: '',
  enrollment_start_date: '',
  pay_frequencies: null,
  benadmin_url: '',
  benadmin_name: '',
  retirementplan_name: '',
  retirementplan_url: '',
  emergencyfund_url: '',
  emergencyfund_name: '',
  signin_url: '',
  signin_name: '',
  signin_note: '',
  benefits_disclosure: '',
  default_state_of_residence: null,
  enable_spousal_benefits: false,
  has_domestic_partner_benefits: false,
  domestic_partner_disclosure: '',
  has_tobacco_use: false,
  segmentation: {
    questions: [],
    answers: {},
  },
  enabled_integrations: null,
  created_at: '',
  updated_at: '',
  employer_name: '',
  employer_description: '',
  employer_logo_url: '',
  initialized: true,
  custom_content: {},
};
