import { EventTrackingState } from 'context/eventTracking';
import { Tracker } from './tracker';

export class MulticastTracker implements Tracker {
  constructor(
    private trackers: Tracker[],
    siteData: EventTrackingState['siteData'],
  ) {
    this.trackers = trackers;
    this.config(siteData);
  }

  config(siteData: EventTrackingState['siteData']): void {
    this.trackers.forEach((tracker) => tracker.config(siteData));
  }

  pageView(params: {
    path?: string;
    title?: string;
    [key: string]: unknown;
  }): void {
    this.trackers.forEach((tracker) => tracker.pageView(params));
  }

  track(params: {
    action: string;
    category: string;
    label: string;
    value?: number;
    [key: string]: unknown;
  }) {
    this.trackers.forEach((tracker) => tracker.track(params));
  }
}
