import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  title: ({ count }: { count: number }) =>
    msg`Your result${
      count > 1 ? 's' : ''
    }: ${count} personalized options to guide your decision`,

  intro: ({ canSubmitToCart: _ }: { canSubmitToCart: boolean }) =>
    md(msg`These benefit combinations were matched to you based on the information you provided.
    Explore and compare your personalized options below, or customize your own.
${'_lf'}
Once you've made a selection, we'll factor it in as we complete your Action
Plan. **Remember, you cannot enroll through this tool.**`),
};
