/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useReducer, useEffect } from 'react';
import { Nullable } from '../interfaces/common';
import { APIResponse } from './common';

type RequestState<ResponseData> = {
  isLoading: boolean;
  error: Nullable<string>;
  success: Nullable<boolean>;
  data: Nullable<ResponseData>;
};

type RequestAction<ResponseData> =
  | { type: 'request' }
  | { type: 'success'; data: ResponseData }
  | { type: 'failure'; error: string };

const requestReducerFactory =
  <ResponseData>() =>
  (state: RequestState<ResponseData>, action: RequestAction<ResponseData>) => {
    switch (action.type) {
      case 'request':
        return { isLoading: true, error: null, data: null, success: null };
      case 'success':
        return {
          isLoading: false,
          error: null,
          data: action.data,
          success: true,
        };
      case 'failure':
        return {
          isLoading: false,
          error: action.error,
          data: null,
          success: false,
        };
    }
  };

type Options = {
  defaultErrorMessage?: string;
};

export const useAPI = <ResponseData, Params>(
  requestFn: (params: Params) => Promise<APIResponse<ResponseData>>,
  options?: Options,
  onLoad = false,
  onSuccess?: (data: any) => void,
  initParams: any = {},
) => {
  const requestReducer = requestReducerFactory<ResponseData>();
  const [{ isLoading, data, error, success }, dispatch] = useReducer(
    requestReducer,
    {
      isLoading: false,
      error: null,
      data: null,
      success: null,
    },
  );

  useEffect(() => {
    if (onLoad) {
      fireRequest(initParams);
    }
  }, []);

  const fireRequest = useCallback(
    async (params: Params) => {
      dispatch({ type: 'request' });
      const res = await requestFn(params);
      if (res.ok) {
        dispatch({ type: 'success', data: res.data });
        if (onSuccess) {
          onSuccess(res.data);
        }
        return res;
      } else {
        const error = options?.defaultErrorMessage || res.error;
        dispatch({
          type: 'failure',
          error,
        });
        return res;
      }
    },
    [options?.defaultErrorMessage, requestFn],
  );

  return {
    isLoading,
    error,
    data,
    success,
    fireRequest,
  };
};
