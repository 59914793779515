import {
  BenefitItemType,
  BenefitValidationItem,
  PlanFrequency,
} from '../../interfaces/benefits';

export const getBenefitHasMatchLimit = (
  benefit: BenefitValidationItem,
): boolean => {
  switch (benefit.type) {
    case BenefitItemType.MEDICAL_PLAN:
    case BenefitItemType.VISION_PLAN:
    case BenefitItemType.DENTAL_PLAN:
    case BenefitItemType.HRA:
    case BenefitItemType.ACCIDENT_INDEMNITY:
    case BenefitItemType.ILLNESS_INDEMNITY:
    case BenefitItemType.HOSPITAL_INDEMNITY: {
      return false;
    }
    case BenefitItemType.FSA:
    case BenefitItemType.LPFSA:
    case BenefitItemType.HSA: {
      return (
        benefit.employer_match_limit !== null &&
        benefit.employer_match_limit > 0
      );
    }
  }
};

export const getBenefitCost = (benefit: BenefitValidationItem): number => {
  switch (benefit.type) {
    case BenefitItemType.MEDICAL_PLAN:
    case BenefitItemType.VISION_PLAN:
    case BenefitItemType.DENTAL_PLAN:
    case BenefitItemType.ACCIDENT_INDEMNITY:
    case BenefitItemType.ILLNESS_INDEMNITY:
    case BenefitItemType.HOSPITAL_INDEMNITY: {
      return benefit.premiums_individual;
    }
    case BenefitItemType.FSA:
    case BenefitItemType.LPFSA:
    case BenefitItemType.HSA: {
      return (
        benefit.employer_match_limit ||
        benefit.employer_contributions_individual
      );
    }
    case BenefitItemType.HRA: {
      return benefit.employer_contributions_individual;
    }
  }
};

export const getBenefitFrequency = (
  benefit: BenefitValidationItem,
): PlanFrequency => {
  switch (benefit.type) {
    case BenefitItemType.ACCIDENT_INDEMNITY:
    case BenefitItemType.ILLNESS_INDEMNITY:
    case BenefitItemType.HOSPITAL_INDEMNITY:
    case BenefitItemType.MEDICAL_PLAN:
    case BenefitItemType.VISION_PLAN:
    case BenefitItemType.DENTAL_PLAN: {
      return benefit.premium_frequency;
    }
    case BenefitItemType.HRA:
    case BenefitItemType.HSA:
    case BenefitItemType.FSA:
    case BenefitItemType.LPFSA: {
      return benefit.contribution_frequency;
    }
  }
};
