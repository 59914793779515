import { isNil } from 'lodash';
import React, { PropsWithChildren, useMemo } from 'react';

import { useGlobalStore } from '../../hooks/useGlobalStore';
import { RouteNames } from '../../RouteNames';
import { AvailableFormStepsContext } from './AvailableFormStepsContext';
import { useBenefitTypes } from 'selectsmart-api';
import { defaultFirstStep, navigationConfig } from 'navigationConfig';
import { initialFormStepRouteIndex } from 'helpers/navigation';
import { BenefitItemType } from 'interfaces/benefits';

export const AvailableFormStepsProvider = ({ children }: PropsWithChildren) => {
  const {
    globalState,
    globalState: {
      apiState: {
        household: { guidance_mode, healthbenefits_mode },
      },
    },
  } = useGlobalStore();

  const { data: benefitTypes } = useBenefitTypes();

  const userJourney = useMemo(
    () =>
      navigationConfig.filter(
        (s) =>
          !s.omit?.({
            guidanceMode: guidance_mode,
            healthbenefitsMode: healthbenefits_mode,
            benefitTypes:
              benefitTypes?.map(
                (b) => BenefitItemType[b as keyof typeof BenefitItemType],
              ) || [],
          }),
      ),
    [guidance_mode, healthbenefits_mode, benefitTypes],
  );

  const formSteps = useMemo(() => {
    const getFormStepsListFromState = (): RouteNames[] => {
      const stepsList = [
        userJourney[initialFormStepRouteIndex(userJourney)].route,
      ];

      const findNextStep = (index: number) => {
        const nextStepRouteName = userJourney[index].nextStep?.(
          globalState,
          (benefitTypes || []) as BenefitItemType[],
        );
        if (!isNil(nextStepRouteName)) {
          const nextIndex = userJourney.findIndex(
            (configEntry) => configEntry.route === nextStepRouteName,
          );
          if (nextIndex > -1) {
            stepsList.push(userJourney[nextIndex].route);
            findNextStep(nextIndex);
          }
        }
      };
      findNextStep(initialFormStepRouteIndex(userJourney));
      return stepsList;
    };

    return getFormStepsListFromState();
  }, [globalState, benefitTypes, userJourney]);

  return (
    <AvailableFormStepsContext.Provider
      value={{
        formSteps,
        userJourney,
        defaultFirstStep,
      }}>
      {children}
    </AvailableFormStepsContext.Provider>
  );
};
