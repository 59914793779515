import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  title: msg`Sit tight while we crunch those numbers.`,
  callout: md(msg`
**Healthcare is one side of the coin**
${'_lf'}
We know we just asked for a lot of info, but don’t forget—savings are up next. Once you select a plan for your physical health, we’ll review a savings strategy that will help your financial health, too.`),
  readyCalloutTitle: msg`Finished Crunching`,
  readyCalloutBody: msg`We calculated a few health plan selections for you to compare. You can review them here, but you’ll still need to finish the enrollment process to sign up.`,
};
