import { Link as LinkMui, SxProps, Theme } from '@mui/material';
import { IsMobileAppEmbeddedContext } from 'context/IsMobileAppEmbeddedProvider';
import * as React from 'react';
import { useContext } from 'react';
export interface Props {
  href: string;
  external?: boolean;
  sx?: SxProps<Theme>;
}

export const Link: React.FC<React.PropsWithChildren<Props>> = ({
  href,
  external,
  children,
  sx,
}) => {
  const isMobileAppEmbedded = useContext(IsMobileAppEmbeddedContext);

  const externalProps = external
    ? {
        // Checking isMobile flag to determine whether to call the system's web browser when embedding this app in a native app:
        // https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-inappbrowser/index.html
        target: isMobileAppEmbedded ? undefined : '_blank',
        // When is embedded, post a message to the parent app to open the link in the native mobile browser
        onClick: isMobileAppEmbedded
          ? (e: any) => {
              e.preventDefault();
              if (
                // @ts-ignore
                typeof webkit !== 'undefined' &&
                // @ts-ignore
                webkit?.messageHandlers?.cordova_iab?.postMessage
              ) {
                // @ts-ignore
                webkit.messageHandlers.cordova_iab.postMessage(
                  JSON.stringify({
                    message: 'EX',
                    url:
                      // Ensure url is absolute in this case
                      new URL(href, document.baseURI).href,
                  }),
                );
              } else {
                // eslint-disable-next-line no-console
                console.log('Cordova postmessage not available');
              }
            }
          : undefined,
        rel: 'noopener',
      }
    : {};

  return (
    <LinkMui sx={sx} href={href} {...externalProps}>
      {children}
    </LinkMui>
  );
};
