import { List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import rehypeRaw from 'rehype-raw';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { RenderOptions } from 'content/types';
import { Link } from 'components/common/Link';

type Props = {
  className?: string;
  testID?: string;
} & RenderOptions;

export function Markdown({
  children,
  className,
  isTitle = false,
  inline,
  typographyVariant,
  allowRawHtml,
  noGutter,
  testID,
}: PropsWithChildren<Props>) {
  // prettier-ignore
  // @ts-ignore
  return ( <ReactMarkdown
      className={className}
      rehypePlugins={allowRawHtml ? [rehypeRaw] : []}
      components={{
        a: (props: any) => {
          const isOrigin =
            new URL(document.baseURI).origin ===
            new URL(props.href, document.baseURI).origin;
          return (
            <Link external={!isOrigin} href={props.href || ''}>
              {props.children}
            </Link>
          );
        },
        p: (props) => {
          if (inline || isTitle) {
            // Assume one line
            return <>{props.children}</>;
          } else {
            // Return nestable, styled box
            const gutterSx = noGutter
              ? { mb: 2, '&:last-of-type': { mb: 0 } }
              : { mb: 2 };

            return (
              //<Box component={'span'} display={'block'} mb={3} {...props}></Box>
              <Typography
                data-testid={testID}
                variant={typographyVariant || 'body1'}
                {...props}
                sx={gutterSx}
                gutterBottom={!noGutter}></Typography>
            );
          }
        },
        /* Renders portions of bold title text as the primary color on specified pages */
        strong: (props) => (
          <span className={`${isTitle ? 'callOut' : 'bold'}`} {...props}></span>
        ),
        ul: (props) => <List sx={{ mb: 2 }}>{props.children}</List>,
        li: (props) => (
          <ListItem>
            <ListItemText>
              <Typography
                data-testid={testID}
                component="span"
                variant={typographyVariant || 'body1'}>
                {props.children}
              </Typography>
            </ListItemText>
          </ListItem>
        ),
      }}
      linkTarget="_blank">
      {String(children)}
    </ReactMarkdown>
  );
}
