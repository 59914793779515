import produce from 'immer';
import { ActionPlan, ActionPlanStatus } from '../interfaces/actionPlan';
import { ActionPlanActionTypes } from '../helpers/stateUtil';
import { storeData } from 'services/asyncStorage';
import {
  ActionMap,
  CommonActionTypes,
  SerializedStore,
  StateInitialized,
} from 'interfaces/common';
import { SettingsType, USER_SETTINGS } from 'interfaces/settings';

export enum ActionTypes {
  SET_FREQUENCY = 'SET_FREQUENCY',
}

export type State = StateInitialized & SettingsType;

export const initialState: State = {
  frequency: 'annually',
  isPaycheck: false,
  initialized: false,
  actionPlanStatus: ActionPlanStatus.EMPTY,
};

export type Payload = {
  [CommonActionTypes.SETUP_STORE]: SerializedStore;
  [ActionTypes.SET_FREQUENCY]: {
    frequency: string;
    isPaycheck: boolean;
  };
  [ActionPlanActionTypes.SET_ACTION_PLAN]: ActionPlan;
  [ActionPlanActionTypes.SET_STATUS]: ActionPlanStatus;
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case CommonActionTypes.SETUP_STORE: {
      const { settings } = action.payload;

      return {
        ...state,
        ...settings,
      };
    }

    case ActionTypes.SET_FREQUENCY: {
      const { frequency, isPaycheck } = action.payload;
      storeData(USER_SETTINGS, {
        frequency,
        isPaycheck,
      });
      return produce(state, (draftState) => {
        draftState.frequency = frequency;
        draftState.isPaycheck = isPaycheck;
      });
    }

    case ActionPlanActionTypes.SET_ACTION_PLAN: {
      return produce(state, (draftState) => {
        draftState.actionPlanStatus = ActionPlanStatus.COMPLETE;
      });
    }

    case ActionPlanActionTypes.SET_STATUS: {
      return produce(state, (draftState) => {
        draftState.actionPlanStatus = action.payload;
      });
    }

    default:
      return state;
  }
};
