import { CachedOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import { Button } from './components/common/Button';
import { View } from './components/common/View';
import { RouteNames } from './RouteNames';
import { Trans } from '@lingui/macro';

// svg - full
const svgFull = (
  <svg width="250" height="180" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m131.062 52.625 17.754 30.75c2.694 4.667-.674 10.5-6.062 10.5h-35.508c-5.388 0-8.756-5.833-6.062-10.5l17.754-30.75c2.694-4.667 9.43-4.667 12.124 0Z"
      stroke="#444"
      strokeWidth="6"
    />
    <rect x="123" y="59.125" width="5" height="19" rx="2.5" fill="#444" />
    <rect x="123" y="83.125" width="5" height="5" rx="2.5" fill="#444" />
    <rect
      x="80"
      y="118.875"
      width="5"
      height="90"
      rx="2.5"
      transform="rotate(-90 80 118.875)"
      fill="#444"
    />
    <rect
      x="94"
      y="131.875"
      width="5"
      height="62"
      rx="2.5"
      transform="rotate(-90 94 131.875)"
      fill="#444"
    />
    <circle cx="21" cy="22" r="5" fill="#444" />
    <circle cx="36" cy="22" r="5" fill="#444" />
    <circle cx="51" cy="22" r="5" fill="#444" />
    <rect
      x="3"
      y="3"
      width="244"
      height="174"
      rx="9"
      stroke="#444"
      strokeWidth="6"
    />
  </svg>
);

export const ErrorPage = () => {
  const refreshApp = () => {
    window.location.href = `/${RouteNames.Welcome}`;
  };

  // Layout
  const muiStyles = useMuiStyles();

  // Clear the user session
  const handleSignOut = () => {
    localStorage.removeItem('_session_token');
    window.location.href = `/${RouteNames.Welcome}`;
  };

  return (
    <View sx={muiStyles.wrapper}>
      <View sx={muiStyles.panel}>
        <View sx={muiStyles.panelBody}>
          <View sx={muiStyles.svg}>{svgFull}</View>

          <Typography mt={2} sx={muiStyles.title} align="center">
            <Trans>Please try again</Trans>
          </Typography>

          <Typography sx={muiStyles.text}>
            <Trans>Something went wrong, but we’re on it.</Trans>
          </Typography>

          <Typography gutterBottom sx={muiStyles.text}>
            <Trans>
              If this problem persists,
              <Button
                variant="text"
                sx={muiStyles.text}
                color="primary"
                onClick={handleSignOut}>
                sign out
              </Button>
              and log back in.
            </Trans>
          </Typography>

          <View sx={muiStyles.buttonWrapper}>
            <Button
              size="large"
              startIcon={<CachedOutlined />}
              onClick={() => refreshApp()}>
              <Trans>Try again</Trans>
            </Button>
          </View>
        </View>

        <View sx={muiStyles.panelBg}>
          <View
            sx={{
              ...muiStyles.bubble,
              ...muiStyles.bubble1,
            }}></View>
          <View
            sx={{
              ...muiStyles.bubble,
              ...muiStyles.bubble2,
            }}></View>
          <View
            sx={{
              ...muiStyles.bubble,
              ...muiStyles.bubble3,
            }}></View>
        </View>
      </View>
    </View>
  );
};

const useMuiStyles = () => ({
  wrapper: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  panel: {},

  panelBody: {
    position: 'relative',
    zIndex: 1,
    alignItems: 'center',
  },

  svg: {
    marginTop: '40px',
    alignItems: 'center',
  },

  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    marginTop: '50px',
    marginBottom: '10px',
  },

  text: {
    fontSize: '21px',
    textAlign: 'center',
    px: '10px',
  },

  buttonWrapper: {
    alignItems: 'center',
    marginTop: '25px',
  },

  button: {
    padding: '5px',
    width: 250,
  },

  buttonLabel: {
    fontWeight: 'bold',
    fontSize: '20px',
  },

  panelBg: {
    zIndex: 0,
    top: 0,
    left: 0,
    width: '100%',
    position: 'absolute',
  },

  bubble: {
    position: 'absolute',
    borderRadius: 200,
    flex: 1,
    py: '14px',
    px: '14px',
    alignItems: 'center',
    justifyContent: 'center',
  },

  bubble1: { left: -87, top: 45, width: 410, minHeight: 410 },
  bubble2: { left: 220, top: 300, width: 200, minHeight: 200 },
  bubble3: { left: 375, top: 128, width: 106, minHeight: 106 },
});
