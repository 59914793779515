import { MessageDescriptor } from '@lingui/core';
import { DataSource, Dictionary } from './common';
import { msg } from '@lingui/macro';
import { SourceType } from 'selectsmart-api/sdk/selectsmartSchemas';

export enum HealthcareServiceUsage {
  NONE = 'none',
  MODERATE = 'moderate',
  HIGH = 'high',
  LOW = 'low',
}

export enum HealthcareAreas {
  MEDICAL = 'medical_care',
  DRUGS = 'prescription_drugs',
  DENTAL = 'dental_care',
  VISION = 'vision_care',
}
export interface HealthcareSpecific {
  [HealthcareAreas.MEDICAL]: HealthcareServiceUsage;
  [HealthcareAreas.DRUGS]: HealthcareServiceUsage;
  [HealthcareAreas.DENTAL]: HealthcareServiceUsage;
  [HealthcareAreas.VISION]: HealthcareServiceUsage;
  source?: DataSource;
  source_id?: string;
}

export type SpecialHealthCare =
  | HealthcareSpecialMedical
  | HealthcareSpecialDrugs
  | HealthcareSpecialDental
  | HealthcareSpecialVision;

type SpecialNeedConfig = {
  title: MessageDescriptor;
  icon: string;
  details?: MessageDescriptor;
  maxValue?: number;
};

export const specialNeeds: Dictionary<SpecialNeedConfig> = {
  // MEDICAL
  primary_care: {
    title: msg`Primary Care Visits`,
    icon: 'user-md-chat-regular',
    details: msg`Please select the number of additional planned visits to your Primary Care Physician.`,
  },
  childbirth: {
    title: msg`Childbirth`,
    icon: 'baby-carriage-regular',
    maxValue: 1,
    details: msg`Mark if you expect to give birth, use 1 instance even if delivering multiples. We ask about childbirth because a pregnancy/childbirth event can increase your total healthcare costs. You are not required to answer this question to enroll in your healthcare plans or obtain other medical benefits.`,
  },
  home_care: {
    title: msg`Home care`,
    icon: 'clinic-medical-regular',
    details: msg`Please select the number of additional planned visits to your home by a nurse or home care specialist.`,
  },
  inpatient_procedures: {
    title: msg`Hospital Stay (e.g., surgery)`,
    icon: 'procedures-regular',
    details: msg`Please select the number of additional planned hospital admissions for any inpatient treatment, including surgery.`,
  },
  diagnostic_tests: {
    title: msg`Diagnostic/ Lab Tests`,
    icon: 'vial-regular',
    details: msg`Please select the number of additional planned visits to a lab for planned diagnostics including blood tests or x-rays.`,
  },
  outpatient_procedures: {
    title: msg`Outpatient Treatment`,
    icon: 'syringe-regular',
    details: msg`Please select the number of additional planned surgeries that won’t require a hospital stay, e.g., herniated disk surgery or muscle/ tendon repair.`,
  },
  outpatient_mental_health: {
    title: msg`Outpatient Mental Health/ Counseling Visits`,
    icon: 'syringe-regular',
    details: msg`Please select the number of additional planned visits to a Mental Health Professional.`,
  },
  pt_and_ot: {
    title: msg`Physical Therapy`,
    icon: 'procedures-regular',
    details: msg`Please select the number of additional planned visits to a Physical or Occupational Therapist.`,
  },
  specialist: {
    title: msg`Specialist Visits`,
    icon: 'stethoscope-regular',
    details: msg`Please select the number of additional planned visits to a specialist, such as a dermatologist, allergist, gynecologist, orthopedist or other.`,
  },
  imaging: {
    title: msg`Imaging, (e.g. MRI, CT)`,
    icon: 'x-ray-regular',
    details: msg`Please select the number of additional planned visits for imaging needs, e.g. MRI or CT. Do not include X-rays, which are considered diagnostic tests.`,
  },
  chiropractic_care: {
    title: msg`Chiropractic Visits`,
    icon: 'procedures-regular',
    details: msg`Please select the number of additional planned visits to a Chiropractor.`,
  },
  telemedicine: {
    title: msg`Telemedicine`,
    icon: 'telemedicine',
    details: msg`Please select the number of virtual visits you plan to have with healthcare specialists.`,
  },
  equipment: {
    title: msg`Medical Equipment`,
    icon: 'medical-equipment',
    details: msg`Please select the number of pieces of medical equipment that you plan on purchasing next year.`,
  },
  // DRUGS
  drugs_generic: {
    title: msg`Generic Drugs`,
    icon: 'icon-additional-prescription-drugs',
    details: msg`Please select the number of additional planned Generic Drug refills you may need. Generic Drugs are usually the lowest cost tier of prescription medications. Not sure which category your prescription falls into? Please refer to plan’s prescription drug formulary list.`,
  },
  drugs_preferred: {
    title: msg`Preferred Drug Prescriptions`,
    icon: 'icon-preferred-drugs',
    details: msg`Please select the number of additional planned Preferred Drug refills you may need. Preferred Drugs are typically common brand name prescription medications without a generic alternative. Not sure which category your prescription falls into? Please refer to plan’s prescription drug formulary list.`,
  },
  drugs_non_preferred: {
    title: msg`Non-Preferred Drug Prescriptions`,
    icon: 'icon-additional-prescription-drugs',
    details: msg`Please select the number of additional planned Non-Preferred Drug refills you may need. Non-Preferred Drugs are typically brand name prescription medications that have generic alternatives. Not sure which category your prescription falls into? Please refer to plan’s prescription drug formulary list.`,
  },
  drugs_specialty: {
    title: msg`Specialty Drugs`,
    icon: 'mortar-pestle-regular',
    details: msg`Please select the number of additional planned Specialty Drug refills you may need. Specialty Drugs are high-cost prescription medications dispensed in specialty pharmacies for treating complex, chronic, or rare conditions. Not sure which category your prescription falls into? Please refer to plan’s prescription drug formulary list.`,
  },
  drugs_preventive: {
    title: msg`Preventive Drugs`,
    icon: 'icon-additional-prescription-drugs',
    details: msg`Please select the number of additional planned Preventive Drug refills you may need. Preventive drugs are prescribed to help avoid disease or to maintain health. Some insurance plans cover their entire cost or require only a copay. Not sure which category your prescription falls into? Please refer to your employer’s prescription drug formulary list.`,
  },
  // DENTAL
  dental_care: {
    title: msg`Dentist Visits`,
    icon: 'tooth-regular',
    details: msg`Please select the number of additional planned diagnostic or cleaning visits to a dentist.`,
  },
  fillings: {
    title: msg`Dental fillings`,
    icon: 'icon-cavity-fillings',
    details: msg`Please select the number of additional planned visits to a dentist for fillings.`,
  },
  endodontics: {
    title: msg`Endodontics`,
    icon: 'icon-endodontics',
    details: msg`Please select the number of additional planned endodontics procedures, such as a root canal or dental implant.`,
  },
  orthodontics: {
    title: msg`Orthodontics`,
    icon: 'icon-orthodontics',
    maxValue: 1,
    details: msg`Please select 1 if you have planned an orthodontics procedure.`,
  },
  // VISION
  routine_eye_care: {
    title: msg`Eye Exam`,
    icon: 'eye-exam',
    maxValue: 9,
    details: msg`Please select 1 if you plan on getting an eye exam.`,
  },
  glasses: {
    title: msg`Glasses`,
    icon: 'icon-glasses-contacts',
    maxValue: 1,
    details: msg`Please select 1 if you plan to replace or start wearing glasses.`,
  },
  contact_lens_fitting: {
    title: msg`Contacts`,
    icon: 'icon-glasses-contacts',
    maxValue: 1,
    details: msg`Please select 1 if you plan to replace or start wearing contact lenses.`,
  },
  vision_procedure: {
    title: msg`Corrective Surgery (e.g., cataracts, LASIK)`,
    icon: 'icon-corrective-eye-surgery',
    details: msg`Please select the number of additional planned corrective surgeries.`,
  },
};

export interface HealthcareSpecial {
  has_special_medical: boolean;
  [HealthcareAreas.MEDICAL]: HealthcareSpecialMedical;
  has_special_drugs: boolean;
  [HealthcareAreas.DRUGS]: HealthcareSpecialDrugs;
  has_special_dental: boolean;
  [HealthcareAreas.DENTAL]: HealthcareSpecialDental;
  has_special_vision: boolean;
  [HealthcareAreas.VISION]: HealthcareSpecialVision;
  source?: SourceType;
  source_id?: string;
}

export interface HealthcareSpecialMedical {
  primary_care?: number;
  outpatient_mental_health?: number;
  specialist?: number;
  diagnostic_tests?: number;
  imaging?: number;
  outpatient_procedures?: number;
  inpatient_procedures?: number;
  pt_and_ot?: number;
  chiropractic_care?: number;
  home_care?: number;
  childbirth?: number;
}
export interface HealthcareSpecialDrugs {
  drugs_preferred?: number;
  drugs_non_preferred?: number;
  drugs_specialty?: number;
  drugs_generic?: number;
}
export interface HealthcareSpecialDental {
  dental_care?: number;
  fillings?: number;
  endodontics?: number;
  orthodontics?: number;
}
export interface HealthcareSpecialVision {
  glasses?: number;
  contact_lens_fitting?: number;
  vision_procedure?: number;
}
export interface HealthcareExplicit {
  service: string;
  num_usages: number;
  cost_per_usage: number;
  indemnity_payouts?: string[] | null;
  is_typical?: boolean;
}

export const defaultSpecialMedical: HealthcareSpecialMedical = {
  primary_care: 0,
  outpatient_mental_health: 0,
  specialist: 0,
  diagnostic_tests: 0,
  imaging: 0,
  outpatient_procedures: 0,
  inpatient_procedures: 0,
  pt_and_ot: 0,
  chiropractic_care: 0,
  home_care: 0,
  childbirth: 0,
};

export const defaultSpecialDrugs: HealthcareSpecialDrugs = {
  drugs_preferred: 0,
  drugs_non_preferred: 0,
  drugs_specialty: 0,
  drugs_generic: 0,
};

export const defaultSpecialDental: HealthcareSpecialDental = {
  dental_care: 0,
  fillings: 0,
  endodontics: 0,
  orthodontics: 0,
};

export const defaultSpecialVision: HealthcareSpecialVision = {
  glasses: 0,
  contact_lens_fitting: 0,
  vision_procedure: 0,
};

export const defaultHealthcareSpecial: HealthcareSpecial = {
  has_special_medical: false,
  medical_care: defaultSpecialMedical,
  has_special_drugs: false,
  prescription_drugs: defaultSpecialDrugs,
  has_special_dental: false,
  dental_care: defaultSpecialDental,
  has_special_vision: false,
  vision_care: defaultSpecialVision,
};

export type AreaClaimsData = {
  source: string | null;
  oop_paid: number | null;
  plan_paid: number | null;
  source_id: string | null;
  has_claims: boolean;
  num_claims: number | null;
  period_end: string | null;
  total_paid: number | null;
  claims_usage: string;
  period_start: string | null;
};

export type ClaimsData = {
  [person: string]: {
    medical_care: AreaClaimsData;
    prescription_drugs: AreaClaimsData;
    dental_care: AreaClaimsData;
    vision_care: AreaClaimsData;
  };
};
