import * as React from 'react';
import isEmpty from 'lodash/isEmpty';

import { RouteNames, NavigationSteps } from 'RouteNames';
import { RootState } from 'components/store/Store';
import { Role } from 'interfaces/common';
import {
  checkAvailableProtection,
  filterSegmentsQuestions,
} from 'helpers/benefits';
import content from 'content/values';
import { NavigationStepConfig } from 'interfaces/navigation';

import { isMidYearJourney } from 'helpers/common';
import { msg } from '@lingui/macro';

const Welcome = React.lazy(() => import('screens/welcome'));
const SignIn = React.lazy(() => import('screens/SignIn'));
const SignUp = React.lazy(() => import('screens/SignUp'));
const Go = React.lazy(() => import('screens/Go'));
const WhatBringsYouHere = React.lazy(
  () => import('screens/profile/WhatBringsYouHere'),
);
const Household = React.lazy(() => import('screens/profile/ProfileHousehold'));
const NotTheToolForYou = React.lazy(
  () => import('screens/profile/NotTheToolForYou'),
);
const ProfileEmployment = React.lazy(
  () => import('screens/profile/ProfileEmployment'),
);
const ProfileIncome = React.lazy(() => import('screens/profile/ProfileIncome'));
const ProfileMidyearPlanDetails = React.lazy(
  () => import('screens/profile/ProfileMidyearPlanDetails'),
);
const Results = React.lazy(() => import('screens/results/Results'));
const BundleSummary = React.lazy(() => import('screens/results/BundleSummary'));

const Healthcare = React.lazy(() => import('screens/healthcare/Healthcare'));
const HealthcareSpecial = React.lazy(
  () => import('screens/healthcare/HealthcareSpecial'),
);
const HealthcareTobacco = React.lazy(
  () => import('screens/healthcare/HealthcareTobacco'),
);
const HealthcareYTD = React.lazy(
  () => import('screens/healthcare/HealthcareYTD'),
);
const HealthcareDTY = React.lazy(
  () => import('screens/healthcare/HealthcareDTY'),
);
const HealthSupplementalBenefits = React.lazy(
  () => import('screens/healthcare/HealthcareSupplementalBenefits'),
);
const Submit = React.lazy(() => import('screens/Submit'));
const BenefitsProfile = React.lazy(
  () => import('screens/benefits/BenefitsProfile'),
);
const BenefitsSpouse = React.lazy(
  () => import('screens/benefits/BenefitsSpouse'),
);
const CoverageLife = React.lazy(
  () => import('screens/protection/CoverageLife'),
);
const CoverageADD = React.lazy(() => import('screens/protection/CoverageADD'));
const CoverageDisability = React.lazy(
  () => import('screens/protection/CoverageDisability'),
);
const EmergencyFund = React.lazy(() => import('screens/EmergencyFund'));
const RetirementAccounts = React.lazy(
  () => import('screens/retirement/RetirementAccounts'),
);
const RetirementOtherAccounts = React.lazy(
  () => import('screens/retirement/RetirementOtherAccounts'),
);
const RetirementGoals = React.lazy(
  () => import('screens/retirement/RetirementGoals'),
);
const Review = React.lazy(() => import('screens/budgeting/Review'));
const Budgeting = React.lazy(() => import('screens/budgeting/Budgeting'));
const ActionPlan = React.lazy(() => import('screens/actionPlan'));
const EngineData = React.lazy(() => import('screens/EngineData'));
const Terms = React.lazy(() => import('screens/Terms'));
const EnumeratedContentReview = React.lazy(
  () => import('screens/budgeting/EnumeratedContentReview'),
);

const { pageTitles } = content;

export const navigationMainSections = [
  NavigationSteps.PROFILE,
  NavigationSteps.HEALTHCARE,
  NavigationSteps.PROTECTION,
  NavigationSteps.RETIREMENT,
  NavigationSteps.BUDGETING,
  NavigationSteps.ACTION_PLAN,
];

export const navigationConfig: NavigationStepConfig[] = [
  // Credentials steps
  // shape: {
  //  name: string;
  //  route: RouteNames;
  //  component: React.ComponentType<any>;
  //}
  {
    name: RouteNames.Go,
    route: RouteNames.Go,
    component: Go,
    milestones: [],
  },
  {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    name: 'Sign up',
    route: RouteNames.SignUp,
    component: SignUp,
    milestones: [],
  },
  {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    name: 'Sign in',
    route: RouteNames.SignIn,
    component: SignIn,
    milestones: [],
  },
  {
    name: RouteNames.Welcome,
    route: RouteNames.Welcome,
    component: Welcome,
    pageTitle: pageTitles.welcome,
    milestones: [
      {
        name: 'guidance',
        status: 'started',
      },
    ],
  },
  {
    name: RouteNames.EngineData,
    route: RouteNames.EngineData,
    component: EngineData,
    milestones: [],
  },
  {
    name: RouteNames.Terms,
    route: RouteNames.Terms,
    component: Terms,
    milestones: [],
  },
  {
    name: RouteNames.EnumeratedContentReview,
    route: RouteNames.EnumeratedContentReview,
    component: EnumeratedContentReview,
    milestones: [],
  },
  {
    name: RouteNames.BundleSummary,
    route: RouteNames.BundleSummary,
    component: BundleSummary,
    pageTitle: msg`Your benefit guidance results`,
    milestones: [],
  },
  // Form steps
  // shape: {
  //  name: string;
  //  route: RouteNames;
  //  isInitialFormStep: boolean; // can be used only in one screen, used to calculate available form steps
  //  component: React.ComponentType<any>;
  //  nextStep: callback calculating next step based on state, returning RouteName or null (if it's a last step in the form)
  //}

  // Profile
  {
    name: NavigationSteps.PROFILE,
    route: RouteNames.WhatBringsYouHere,
    pageTitle: pageTitles.guidanceMode,
    isInitialFormStep: true,
    component: WhatBringsYouHere,
    useProgressBar: true,
    nextStep: (_state: RootState) => RouteNames.Household,
    milestones: [],
  },
  {
    name: NavigationSteps.PROFILE,
    route: RouteNames.Household,
    pageTitle: pageTitles.profileHousehold,
    component: Household,
    useProgressBar: false,
    nextStep: (state: RootState) => {
      const {
        apiState: {
          household: { guidance_mode, healthbenefits_mode },
        },
      } = state;
      // Local
      const client = Object.values(state.apiState.people).filter(
        (person) => person.role === Role.CLIENT,
      )[0];

      // Allow the user to load fully before processing all of these routes
      if (!client) {
        return null;
      }

      if (client.has_medicare && !client.is_covered) {
        return RouteNames.NotTheToolForYou;
      } else {
        if (!isEmpty(state.apiState?.people)) {
          const spouse = Object.values(state.apiState.people).find(
            ({ role }) => role === Role.SPOUSE,
          );
          if (
            guidance_mode === 'midyear-update' &&
            spouse &&
            spouse.has_medicare &&
            !spouse.is_covered
          ) {
            return RouteNames.NotTheToolForYou;
          }

          const questions = filterSegmentsQuestions(
            state.apiState.employer.segmentation,
            {
              isMarried: !!spouse,
            },
          );

          const collectIncomeWithSegmentation =
            state.apiState.employer.segmentation.find(
              (segment) => segment.type === 'salary-band',
            ) && state.apiState.incomes[client.id]?.source_id === null;

          if (questions.length > 0 || collectIncomeWithSegmentation) {
            return RouteNames.ProfileEmployment;
          }
        }

        const isMidyearUpdate = isMidYearJourney(state);

        // Go to benefit summary page if no benadmin integration for midyear update
        if (isMidyearUpdate) {
          return healthbenefits_mode !== 'current_benadmin'
            ? RouteNames.MidyearPlanDetails
            : RouteNames.BenefitsProfile;
        }
        return RouteNames.HealthcareUsage;
      }
    },
    milestones: [
      {
        name: 'people',
        status: 'completed',
      },
    ],
  },
  {
    name: NavigationSteps.PROFILE,
    route: RouteNames.NotTheToolForYou,
    component: NotTheToolForYou,
    useProgressBar: false,
    nextStep: (_state: RootState) => RouteNames.Welcome,
    milestones: [],
  },
  {
    name: NavigationSteps.PROFILE,
    route: RouteNames.ProfileEmployment,
    pageTitle: pageTitles.profileEmployment,
    component: ProfileEmployment,
    useProgressBar: true,
    nextStep: (state: RootState) => {
      const {
        apiState: {
          household: { healthbenefits_mode },
        },
      } = state;
      const isMidyearUpdate = isMidYearJourney(state);

      // Go to benefit summary page if no benadmin integration for midyear update
      if (isMidyearUpdate) {
        return healthbenefits_mode !== 'current_benadmin'
          ? RouteNames.MidyearPlanDetails
          : RouteNames.BenefitsProfile;
      }
      return RouteNames.HealthcareUsage;
    },
    milestones: [],
  },

  // Healthcare
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.HealthcareUsage,
    pageTitle: pageTitles.healthcareAverageUsage,
    component: Healthcare,
    useProgressBar: true,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (state: RootState) => {
      // Local
      const healthcare = state.apiState.healthcare;
      const household = state.apiState.household;
      const employer = state.apiState.employer;

      // Do we expect ytd changes?
      if (Object.values(healthcare).some((p: any) => p.has_expected_ytd)) {
        return RouteNames.HealthcareYTD;
      }

      // Do we expect dty changes?
      if (Object.values(healthcare).some((p: any) => p.has_expected_dty)) {
        return RouteNames.HealthcareDTY;
      }

      // Next year changes?
      if (Object.values(healthcare).some((p: any) => p.has_special)) {
        return RouteNames.HealthcareSpecial;
      }

      // Include spouse before moving on?
      if (
        household.consider_spousal_benefits ||
        household.last_location === RouteNames.BenefitsSpouse
      ) {
        return RouteNames.BenefitsSpouse;
      }

      // Do we need to consider tobacco?
      if (employer.has_tobacco_use) {
        return RouteNames.TobaccoUsage;
      }

      // Move to next step
      return RouteNames.BenefitsProfile;
    },
    milestones: [
      {
        name: 'healthcare',
        status: 'completed',
      },
    ],
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.HealthcareSpecial,
    pageTitle: pageTitles.healthcareSpecialUsage,
    component: HealthcareSpecial,
    useProgressBar: true,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (state: RootState) => {
      // Local
      const healthcare = state.apiState.healthcare;
      const household = state.apiState.household;
      const employer = state.apiState.employer;

      // Do we expect ytd changes?
      if (Object.values(healthcare).some((p: any) => p.has_expected_ytd)) {
        return RouteNames.HealthcareYTD;
      }

      // Do we expect dty changes?
      if (Object.values(healthcare).some((p: any) => p.has_expected_dty)) {
        return RouteNames.HealthcareDTY;
      }

      // Include spouse before moving on?
      if (
        household.consider_spousal_benefits ||
        household.last_location === RouteNames.BenefitsSpouse
      ) {
        return RouteNames.BenefitsSpouse;
      }

      // Do we need to consider tobacco?
      if (employer.has_tobacco_use) {
        return RouteNames.TobaccoUsage;
      }

      // Move to next step
      return RouteNames.BenefitsProfile;
    },
    milestones: [],
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.HealthcareYTD,
    pageTitle: pageTitles.healthcareSpecialUsage,
    component: HealthcareYTD,
    useProgressBar: true,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (state: RootState) => {
      // Local
      const employer = state.apiState.employer;
      const household = state.apiState.household;

      // Do we expect dty changes?
      if (
        Object.values(state.apiState.healthcare).some(
          (p: any) => p.has_expected_dty,
        )
      ) {
        return RouteNames.HealthcareDTY;
      }

      // Include spouse before moving on?
      if (
        household.consider_spousal_benefits ||
        household.last_location === RouteNames.BenefitsSpouse
      ) {
        return RouteNames.BenefitsSpouse;
      }

      // Do we need to consider tobacco?
      if (employer.has_tobacco_use) {
        return RouteNames.TobaccoUsage;
      }

      // Move to next step
      return RouteNames.BenefitsProfile;
    },
    milestones: [],
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.HealthcareDTY,
    pageTitle: pageTitles.healthcareSpecialUsage,
    component: HealthcareDTY,
    useProgressBar: true,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (state: RootState) => {
      // Local
      const employer = state.apiState.employer;
      const household = state.apiState.household;

      // Include spouse before moving on?
      if (
        household.consider_spousal_benefits ||
        household.last_location === RouteNames.BenefitsSpouse
      ) {
        return RouteNames.BenefitsSpouse;
      }

      // Do we need to consider tobacco?
      if (employer.has_tobacco_use) {
        return RouteNames.TobaccoUsage;
      }

      // Move to next step
      return RouteNames.BenefitsProfile;
    },
    milestones: [],
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.BenefitsSpouse,
    pageTitle: pageTitles.partnerBenefits,
    component: BenefitsSpouse,
    useProgressBar: true,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (state: RootState) => {
      // Local
      const employer = state.apiState.employer;

      // Do we need to consider tobacco?
      if (employer.has_tobacco_use) {
        return RouteNames.TobaccoUsage;
      }

      // Move to next step
      return RouteNames.BenefitsProfile;
    },
    milestones: [
      {
        name: 'spousal-benefits',
        status: 'completed',
      },
    ],
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.TobaccoUsage,
    pageTitle: pageTitles.tobaccoUsage,
    component: HealthcareTobacco,
    useProgressBar: true,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (_state: RootState) => RouteNames.BenefitsProfile,
    milestones: [],
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.MidyearPlanDetails,
    pageTitle: pageTitles.profileMidyearPlanDetails,
    component: ProfileMidyearPlanDetails,
    useProgressBar: true,
    omit: ({ guidanceMode, healthbenefitsMode }) =>
      guidanceMode !== 'midyear-update' ||
      healthbenefitsMode === 'current_benadmin',
    nextStep: (_state: RootState) => RouteNames.BenefitsProfile,
    milestones: [],
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.BenefitsProfile,
    pageTitle: pageTitles.benefitsProfile,
    component: BenefitsProfile,
    useProgressBar: true,
    nextStep: (state: RootState) => {
      const isMidyearUpdate = isMidYearJourney(state);

      if (isMidyearUpdate) {
        return RouteNames.ProfileIncome;
      } else {
        return RouteNames.HealthcareSupplementalBenefits;
      }
    },
    milestones: [
      {
        name: 'health-accounts',
        status: 'completed',
      },
      {
        name: 'profile',
        status: 'completed',
      },
    ],
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.HealthcareSupplementalBenefits,
    pageTitle: pageTitles.supplementalBenefits,
    component: HealthSupplementalBenefits,
    useProgressBar: true,
    nextStep: (_state) => RouteNames.Submit,
    milestones: [
      {
        name: 'health',
        status: 'completed',
      },
    ],
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.Submit,
    pageTitle: pageTitles.submit,
    useProgressBar: false,
    component: Submit,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (_state: RootState) => RouteNames.Results,
    milestones: [
      {
        name: 'bundles',
        status: 'started',
      },
    ],
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.Results,
    pageTitle: pageTitles.results,
    useProgressBar: false,
    component: Results,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (_state) => RouteNames.ProfileIncome,
    milestones: [
      {
        name: 'bundles',
        status: 'completed',
      },
      {
        name: 'nextdollar',
        status: 'started',
      },
    ],
  },

  // Protection
  {
    name: NavigationSteps.PROTECTION,
    route: RouteNames.ProfileIncome,
    pageTitle: pageTitles.profileIncome,
    component: ProfileIncome,
    useProgressBar: true,
    nextStep: (state, benefitTypes) => {
      const isMidyearUpdate = isMidYearJourney(state);
      if (isMidyearUpdate) {
        return RouteNames.EmergencyFund;
      }

      const { hasLife, hasADD, hasLTD, hasSTD } =
        checkAvailableProtection(benefitTypes);
      if (hasLife) return RouteNames.CoverageLife;
      if (hasADD) return RouteNames.CoverageADD;
      if (hasLTD || hasSTD) return RouteNames.CoverageDisability;
      return RouteNames.EmergencyFund;
    },
    milestones: [
      {
        name: 'income',
        status: 'completed',
      },
    ],
  },
  {
    name: NavigationSteps.PROTECTION,
    route: RouteNames.CoverageLife,
    pageTitle: pageTitles.coverageLife,
    component: CoverageLife,
    useProgressBar: false,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (_state, benefitTypes) => {
      const { hasADD, hasLTD, hasSTD } = checkAvailableProtection(benefitTypes);
      if (hasADD) return RouteNames.CoverageADD;
      if (hasLTD || hasSTD) return RouteNames.CoverageDisability;
      return RouteNames.EmergencyFund;
    },
    milestones: [],
  },
  {
    name: NavigationSteps.PROTECTION,
    route: RouteNames.CoverageADD,
    pageTitle: pageTitles.coverageAdd,
    component: CoverageADD,
    useProgressBar: false,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (_state, benefitTypes) => {
      const { hasLTD, hasSTD } = checkAvailableProtection(benefitTypes);
      if (hasLTD || hasSTD) return RouteNames.CoverageDisability;
      return RouteNames.EmergencyFund;
    },
    milestones: [],
  },
  {
    name: NavigationSteps.PROTECTION,
    route: RouteNames.CoverageDisability,
    pageTitle: pageTitles.coverageDisability,
    component: CoverageDisability,
    useProgressBar: false,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: () => RouteNames.EmergencyFund,
    milestones: [],
  },
  {
    name: NavigationSteps.PROTECTION,
    route: RouteNames.EmergencyFund,
    pageTitle: pageTitles.emergencyFund,
    component: EmergencyFund,
    useProgressBar: false,
    nextStep: () => RouteNames.ClientRetirement,
    milestones: [
      {
        name: 'emergencyfund-goal',
        status: 'completed',
      },
    ],
  },

  // Retirement
  {
    name: NavigationSteps.RETIREMENT,
    route: RouteNames.ClientRetirement,
    pageTitle: pageTitles.retirementAccounts,
    component: RetirementAccounts,
    useProgressBar: true,
    nextStep: (state: RootState) => {
      if (
        Object.values(state.apiState.people).find(
          (el) => el.role === Role.SPOUSE,
        ) &&
        state.retirement.addSpouseRetirement
      ) {
        return RouteNames.SpouseRetirement;
      } else if (state.retirement.addIraAccounts) {
        return RouteNames.RetirementOtherAccounts;
      } else {
        return RouteNames.RetirementGoals;
      }
    },
    milestones: [
      {
        name: 'retirement-accounts',
        status: 'completed',
      },
    ],
  },
  {
    name: NavigationSteps.RETIREMENT,
    route: RouteNames.SpouseRetirement,
    pageTitle: pageTitles.retirementAccountsPartner,
    component: RetirementAccounts,
    useProgressBar: true,
    nextStep: (state: RootState) => {
      if (state.retirement.addIraAccounts) {
        return RouteNames.RetirementOtherAccounts;
      } else {
        return RouteNames.RetirementGoals;
      }
    },
    milestones: [],
  },
  {
    name: NavigationSteps.RETIREMENT,
    route: RouteNames.RetirementOtherAccounts,
    pageTitle: pageTitles.retirementAccountsOther,
    component: RetirementOtherAccounts,
    useProgressBar: true,
    nextStep: () => RouteNames.RetirementGoals,
    milestones: [],
  },
  {
    name: NavigationSteps.RETIREMENT,
    route: RouteNames.RetirementGoals,
    pageTitle: pageTitles.retirementGoals,
    component: RetirementGoals,
    useProgressBar: true,
    nextStep: () => {
      return RouteNames.Review;
    },
    milestones: [
      {
        name: 'retirement-goal',
        status: 'completed',
      },
    ],
  },

  // Budgeting
  {
    name: NavigationSteps.BUDGETING,
    route: RouteNames.Review,
    pageTitle: pageTitles.review,
    component: Review,
    useProgressBar: true,
    nextStep: () => RouteNames.Budgeting,
    milestones: [
      {
        name: 'wealth',
        status: 'completed',
      },
      {
        name: 'nextdollar',
        status: 'completed',
      },
    ],
  },
  {
    name: NavigationSteps.BUDGETING,
    route: RouteNames.Budgeting,
    pageTitle: pageTitles.budgeting,
    component: Budgeting,
    useProgressBar: true,
    nextStep: () => RouteNames.ActionPlan,
    milestones: [
      {
        name: 'actionplan',
        status: 'created',
      },
    ],
  },

  // Action plan
  {
    name: NavigationSteps.ACTION_PLAN,
    route: RouteNames.ActionPlan,
    pageTitle: pageTitles.actionPlan,
    component: ActionPlan,
    useProgressBar: true,
    nextStep: () => null,
    milestones: [],
  },
];

// Set a default welcome screen for the app to default to
export const defaultFirstStep = RouteNames.WhatBringsYouHere;
