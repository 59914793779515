import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

export const IsMobileAppEmbeddedContext = createContext<boolean>(
  // @ts-ignore
  !!window?.savvi?.isMobile,
);

export const IsMobileAppEmbeddedProvider = ({
  children,
}: PropsWithChildren) => {
  const [isMobileAppEmbedded, setIsMobileAppEmbedded] = useState(
    // @ts-ignore
    !!window?.savvi?.isMobile,
  );

  useEffect(() => {
    const handler = () => {
      setIsMobileAppEmbedded(true);
    };

    window.addEventListener('savvi.ismobile', handler);

    return function () {
      window.removeEventListener('savvi.ismobile', handler);
    };
  }, []);

  return (
    <IsMobileAppEmbeddedContext.Provider value={isMobileAppEmbedded}>
      {children}
    </IsMobileAppEmbeddedContext.Provider>
  );
};

export default IsMobileAppEmbeddedProvider;
