export enum EmployerActionTypes {
  SETUP_EMPLOYER = 'SETUP_EMPLOYER',
  UPDATE_BENEFIT_SEGMENT_QUESTION_ANSWER = 'UPDATE_BENEFIT_SEGMENT_QUESTION_ANSWER',
}

export enum BenefitsActionTypes {
  SETUP_BENEFITS = 'SETUP_BENEFITS',
  REMOVE_BENEFIT = 'REMOVE_BENEFIT',
  UPDATE_BENEFIT = 'UPDATE_BENEFIT',
}

export enum PlansActionTypes {
  ADD_PLAN = 'ADD_PLAN',
  REMOVE_PLAN = 'REMOVE_PLAN',
  UPDATE_PLANS = 'UPDATE_PLANS',
}

export enum AccountsActionTypes {
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
  UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS',
}

export enum PeopleActionTypes {
  ADD_PERSON = 'ADD_PERSON',
  REMOVE_PERSON = 'REMOVE_PERSON',
  UPDATE_BIRTHDATE = 'UPDATE_BIRTHDATE',
  UPDATE_IS_COVERED = 'UPDATE_IS_COVERED',
  UPDATE_HAS_MEDICARE = 'UPDATE_HAS_MEDICARE',
  UPDATE_EDITED_BIRTHDAY = 'UPDATE_EDITED_BIRTHDAY',
  UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME',
}

export enum IncomesActionTypes {
  SET_INCOMES = 'SET_INCOMES',
  UPDATE_ANNUAL_PAY = 'UPDATE_ANNUAL_PAY',
  UPDATE_PAY_FREQUENCY = 'UPDATE_PAY_FREQUENCY',
  UPDATE_PAY_SCHEDULE = 'UPDATE_PAY_SCHEDULE',
  UPDATE_OTHER_INCOME = 'UPDATE_OTHER_INCOME',
  UPDATE_IS_OTHER_INCOME_SE = 'UPDATE_IS_OTHER_INCOME_SE',
  UPDATE_SPOUSE_RECEIVE_SOCIAL_SECURITY = 'UPDATE_SPOUSE_RECEIVE_SOCIAL_SECURITY',
  UPDATE_MONTHLY_SOCIAL_SECURITY = 'UPDATE_MONTHLY_SOCIAL_SECURITY',
  UPDATE_AGE_SOCIAL_SECURITY = 'UPDATE_AGE_SOCIAL_SECURITY',
}

export enum TobaccoUseActionTypes {
  UPDATE_TOBACCO_USER = 'UPDATE_TOBACCO_USER',
  UPDATE_TOBACCO_CESSATION = 'UPDATE_TOBACCO_CESSATION',
}

export enum BundlesActionTypes {
  SETUP_RESULTS = 'SETUP_RESULTS',
  UPDATE_CUSTOMIZED = 'UPDATE_CUSTOMIZED',
  UPDATE_SELECTED_BUNDLE = 'UPDATE_SELECTED_BUNDLE',
}

export enum ActionPlanActionTypes {
  SET_ACTION_PLAN = 'SET_ACTION_PLAN',
  TOGGLE_COMPLETED = 'TOGGLE_COMPLETED',
  SET_STATUS = 'SET_STATUS',
}

export enum GoalsActionTypes {
  UPDATE_GOAL = 'UPDATE_GOAL',
  UPDATE_RETIREMENT_GOAL = 'UPDATE_RETIREMENT_GOAL',
  UPDATE_HAS_EMERGENCY = 'UPDATE_HAS_EMERGENCY',
  UPDATE_MONTHLY_SAVINGS = 'UPDATE_MONTHLY_SAVINGS',
  UPDATE_CURRENT_BALANCE = 'UPDATE_CURRENT_BALANCE',
  UPDATE_SAVINGS_AIM = 'UPDATE_SAVINGS_AIM',
  UPDATE_WEEKS_COVERED = 'UPDATE_WEEKS_COVERED',
}

export enum BudgetingActionTypes {
  UPDATE_ADJUSTED_BUDGET = 'UPDATE_ADJUSTED_BUDGET',
  SET_BUDGET_DATA = 'SET_BUDGET_DATA',
}

export enum HouseholdActionTypes {
  SET_HOUSEHOLD = 'SET_HOUSEHOLD',
  UPDATE_AGE = 'UPDATE_AGE',
  UPDATE_STATE = 'UPDATE_STATE',
  UPDATE_ID = 'UPDATE_ID',
  UPDATE_SPOUSE_ID = 'UPDATE_SPOUSE_ID',
  UPDATE_LAST_LOCATION = 'UPDATE_LAST_LOCATION',
  UPDATE_MAX_PROGRESS = 'UPDATE_MAX_PROGRESS',
  UPDATE_DISCLOSURE_ACCEPTED_AT = 'UPDATE_DISCLOSURE_ACCEPTED_AT',
  UPDATE_ELIGIBILITY = 'UPDATE_ELIGIBILITY',
  UPDATE_GUIDANCE_MODE = 'UPDATE_GUIDANCE_MODE',
  UPDATE_GUIDANCE_MODE_STARTED_AT = 'UPDATE_GUIDANCE_MODE_STARTED_AT',
  UPDATE_GUIDANCE_MODE_COMPLETED_AT = 'UPDATE_GUIDANCE_MODE_COMPLETED_AT',
  UPDATE_SPOUSE_HAS_BENEFITS = 'UPDATE_SPOUSE_HAS_BENEFITS',
  UPDATE_CONSIDER_SPLIT_BENEFITS = 'UPDATE_CONSIDER_SPLIT_BENEFITS',
  UPDATE_CLAIMS_OPTIN = 'UPDATE_CLAIMS_OPTIN',
  UPDATE_INCOME_LEVEL = 'UPDATE_INCOME_LEVEL',
}

export enum HealthcareActionTypes {
  UPDATE_TYPICAL = 'UPDATE_TYPICAL',
  UPDATE_HAS_EXPECTED_YTD = 'UPDATE_HAS_EXPECTED_YTD',
  UPDATE_HAS_EXPECTED_DTY = 'UPDATE_HAS_EXPECTED_DTY',
  UPDATE_HAS_SPECIAL = 'UPDATE_HAS_SPECIAL',
  UPDATE_SPECIAL_CARE = 'UPDATE_SPECIAL_CARE',
  UPDATE_SPECIAL_USE = 'UPDATE_SPECIAL_USE',
  UPDATE_HEALTHCARE_ACCOUNT = 'UPDATE_HEALTHCARE_ACCOUNT',
  CONVERT_SPECIAL_RELATIVE_TO_ABSOLUTE = 'CONVERT_SPECIAL_RELATIVE_TO_ABSOLUTE',
}
