import React, { PropsWithChildren } from 'react';
import { useGlobalStore } from 'hooks/useGlobalStore';

// Types
type State = {
  isMidyearUpdate: boolean;
  isReturningUser: boolean;
  hasViewedGreetings: boolean;
};

// Type Action
type Actions = {
  SET_IS_MIDYEAR_UPDATE: 'SET_IS_MIDYEAR_UPDATE';
  SET_IS_RETURNING_USER: 'SET_IS_RETURNING_USER';
  SET_HAS_VIEWED_GREETING: 'SET_HAS_VIEWED_GREETING';
};

// For the values
export const MidyearUpdateContext = React.createContext<State | null>(null);

// For the dispatch
export const MidyearUpdateDispatchContext = React.createContext<React.Dispatch<{
  type: keyof Actions;
  payload: boolean;
}> | null>(null);

// Reducer
const reducer = (
  state: State,
  action: { type: keyof Actions; payload: boolean },
) => {
  switch (action.type) {
    case 'SET_IS_RETURNING_USER':
      return {
        ...state,
        isReturningUser: action.payload,
      };
    case 'SET_HAS_VIEWED_GREETING':
      return {
        ...state,
        hasViewedGreetings: action.payload,
      };
    case 'SET_IS_MIDYEAR_UPDATE':
      return {
        ...state,
        isMidyearUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const MidyearUpdateProvider = ({ children }: PropsWithChildren) => {
  const {
    globalState: {
      apiState: {
        household: { guidance_mode },
      },
    },
  } = useGlobalStore();

  const [values, dispatch] = React.useReducer(reducer, {
    isMidyearUpdate: false,
    isReturningUser: false,
    hasViewedGreetings: false,
  });

  // Listen to guidance_mode
  React.useEffect(() => {
    dispatch({
      type: 'SET_IS_MIDYEAR_UPDATE',
      payload: guidance_mode === 'midyear-update',
    });
  }, [guidance_mode]);

  return (
    <MidyearUpdateContext.Provider value={values}>
      <MidyearUpdateDispatchContext.Provider value={dispatch}>
        {children}
      </MidyearUpdateDispatchContext.Provider>
    </MidyearUpdateContext.Provider>
  );
};
