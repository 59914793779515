import { msg } from '@lingui/macro';
import { md, SpouseLabelData } from 'content/types';

export default {
  title: ({ spouseLabel }: SpouseLabelData) =>
    md(msg`Compare ${spouseLabel}’s benefits and find the best fit`),

  intro: ({ spouseLabel }: SpouseLabelData) =>
    md(
      msg`Super charge your personalized guidance by providing ${spouseLabel}’s plan details to easily compare your options.`,
    ),

  whyDoWeNeedThis: ({ spouseLabel }: SpouseLabelData) =>
    md(
      msg`It’s time to share ${spouseLabel}’s health benefit plan
details so we can help choose between different medical, dental,
and vision plans. Gather this information from ${spouseLabel}’s 
employer in a benefits guide, a summary of benefits coverage, or other benefits documentation:
${'_lf'}
* Premiums
* Co-pay and co-insurance
* Individual and family deductibles
* Individual and family out-of-pocket limits
* If the medical plan is an HSA-eligible high-deductible plan
${'_lf'}
Pause to collect this info any time. We’ll save your progress, so
you can always pick up where you left off.`,
    ),

  callout: md(msg`
We’ll save your progress if you need to gather your partner’s benefits information so you can always pick up where you left off.
${'_lf'}
**Partners aren’t always covered.**
${'_lf'}
Domestic partners or civil union partners may be defined specifically in policy or plan documents. Employers may have different requirements based on state law or other guidance. Check with your employer or benefits team if you have any questions.`),
};
