import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  title: () =>
    msg`Just a little more information will help us tailor personalized guidance just for you.`,
  intro: () =>
    msg`The more accurate your estimates are on this screen, the better we can personalize our guidance for your needs.`,

  whyDoWeNeedThis: md(
    msg`Providing this information helps us show how a plan option would perform in a difficult year. It also helps us estimate tax savings across different benefit combinations.`,
  ),

  callout: md(msg`
  HSA accounts can be a great way to save for future healthcare use and your retirement,  providing three types of tax advantages:
* If you’re eligible to contribute to an HSA, contributions may be pre-tax and could save you money by lowering your taxable income.
* HSA balances, if invested, grow tax-free.
* There are no taxes or penalties when using HSA funds to pay for qualified medical expenses — even after retirement.`),

  balanceDescription:
    md(msg`Estimate how much of your HSA will be available by the start of this coming
plan year (i.e. if you have $1,000 now, but plan to use $500 before the end 
of this plan year, enter $500).`),
};
