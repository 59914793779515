import React, { Dispatch, ReducerAction, useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';

import {
  Actions as BenefitsActions,
  initialState as benefitsInitialState,
  reducer as benefitsReducer,
  State as BenefitsState,
} from '../../reducers/benefits';

import {
  Actions as AuthActions,
  initialState as authInitialState,
  reducer as authReducer,
  State as AuthState,
} from '../../reducers/auth';

import { STORAGE_KEY } from '../../helpers/constants';
import {
  CommonActionTypes,
  Dictionary,
  SerializedStore,
} from '../../interfaces/common';
import {
  Actions as ApiActions,
  initialState as apiInitialState,
  reducer as apiReducer,
  State as ApiState,
} from '../../reducers/api';
import { getDataSync, saveStateToStorage } from '../../services/asyncStorage';
import { GlobalContext } from './GlobalContext';
import { Contribution, ContributionType } from 'interfaces/retirement';
import { defaultFrequencyValues, Role } from 'interfaces/common';
import { getAge, getAgeInMonths } from 'helpers/common';

import {
  Actions as RetirementActions,
  initialState as retirementInitialState,
  reducer as retirementReducer,
  State as RetirementState,
} from 'reducers/retirement';

import {
  Actions as SettingsActions,
  initialState as settingsInitialState,
  reducer as settingsReducer,
  State as SettingsState,
} from 'reducers/settings';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

declare global {
  interface Window {
    savviDebug?: boolean;
    store: RootState;
    dispatch: Dispatch<ReducerAction<typeof mainReducer>>;
  }
}

export interface RootState {
  benefits: BenefitsState;
  auth: AuthState;
  retirement: RetirementState;
  settings: SettingsState;
  apiState: ApiState;
}

export const initialState: RootState = {
  benefits: benefitsInitialState,
  auth: authInitialState,
  retirement: retirementInitialState,
  settings: settingsInitialState,
  apiState: apiInitialState,
};

export type MergedActions =
  | BenefitsActions
  | AuthActions
  | RetirementActions
  | SettingsActions
  | ApiActions;

const mainReducer = (state: RootState, action: MergedActions) => ({
  benefits: benefitsReducer(state.benefits, action as BenefitsActions),
  auth: authReducer(state.auth, action as AuthActions),
  retirement: retirementReducer(state.retirement, action as RetirementActions),
  settings: settingsReducer(state.settings, action as SettingsActions),
  apiState: apiReducer(state.apiState, action as ApiActions),
});

export const Store = ({ children }: React.PropsWithChildren) => {
  const [globalState, dispatch] = React.useReducer(mainReducer, initialState);
  Sentry.setContext('user', {
    user_id: globalState.apiState.household.clientId,
    household_id: globalState.apiState.household.id,
    bundles_id: globalState.apiState.bundles.id,
    selected_bundle: globalState.apiState.bundles.selected_key,
    nextdollar_id: globalState.apiState.budgeting.id,
    budget: globalState.apiState.budgeting.adjusted_budget,
  });
  const { people } = globalState.apiState;

  const { _ } = useLingui();

  const initialized =
    globalState.benefits.initialized &&
    globalState.apiState.household.initialized;

  const sortedPeopleIds = useMemo(() => {
    let sortedPeopleIds: string[] = [];
    const peopleIds = Object.keys(people).map((id) => id) ?? [];
    if (peopleIds != undefined) {
      sortedPeopleIds = peopleIds.sort((a, b) => {
        if (
          (people[a].role === Role.DEPENDENT &&
            people[b].role === Role.CLIENT) ||
          (people[a].role === Role.DEPENDENT &&
            people[b].role === Role.SPOUSE) ||
          (people[a].role === Role.SPOUSE && people[b].role === Role.CLIENT)
        ) {
          return 1;
        } else if (
          people[a].role === Role.DEPENDENT &&
          people[b].role === Role.DEPENDENT
        ) {
          return (
            getAgeInMonths(people[b].birthdate) -
            getAgeInMonths(people[a].birthdate)
          );
        } else {
          return -1;
        }
      });
    }
    return sortedPeopleIds;
  }, [people]);

  const spouseId = useMemo(
    () => Object.values(people).find((p) => p.role === Role.SPOUSE)?.id,
    [people],
  );

  // These are used in the case where we wait or need to display a generic label
  // for the person instead of their name
  const genericPeopleDisplayNames = useMemo(() => {
    const labelCounts: { [k: string]: number } = {};
    return sortedPeopleIds?.reduce((peopleLabels, personId, currentIndex) => {
      const { role, birthdate } = people[personId];
      if (role === Role.CLIENT) {
        peopleLabels[personId] = _(msg`myself`);
      } else if (role === Role.SPOUSE) {
        // Always use partner as a fallback display name
        peopleLabels[personId] = _(msg`partner`);
      } else {
        const age = getAge(birthdate);
        let personLabel;
        if (age != 0) {
          personLabel = _(msg`${age} year-old`);
        } else {
          personLabel = _(msg`${getAgeInMonths(birthdate)} month-old`);
        }
        if (!labelCounts?.[personLabel]) {
          labelCounts[personLabel] = 1;
        } else {
          if (labelCounts[personLabel] === 1) {
            peopleLabels[sortedPeopleIds[currentIndex - 1]] += ' #1';
          }
          labelCounts[personLabel] += 1;
          personLabel += ` #${labelCounts[personLabel]}`;
        }
        peopleLabels[personId] = personLabel;
      }
      return peopleLabels;
    }, {} as Dictionary<string>);
  }, [sortedPeopleIds, people, _]);

  const spouseGenericLabel = useMemo(
    () =>
      globalState.apiState.employer.has_domestic_partner_benefits
        ? _(msg`partner`)
        : _(msg`spouse`),
    [_, globalState.apiState.employer.has_domestic_partner_benefits],
  );

  const personLabel = (
    personId: string | null | undefined,
    options?: { useGenericLabel?: boolean },
  ) => {
    if (!personId) return '';

    const { useGenericLabel } = options || {};
    const { role, first_name } = people[personId];

    if (role !== Role.CLIENT && first_name && !useGenericLabel) {
      return first_name;
    } else if (role === Role.CLIENT) {
      return genericPeopleDisplayNames[personId];
    } else {
      return _(msg`your ${genericPeopleDisplayNames[personId]}`);
    }
  };

  const getContribution = useCallback(
    (id: string, contribution: Contribution) => {
      const { type, rate, frequency } = contribution;
      return type === ContributionType.PERCENT
        ? (rate / 100) * (globalState.apiState.incomes[id]?.annual_pay || 0)
        : rate * defaultFrequencyValues[frequency];
    },
    [globalState.apiState.incomes],
  );

  const resetStorage = useCallback(async () => {
    dispatch({
      type: CommonActionTypes.RESET_STORE,
    });
    await saveStateToStorage({
      ...initialState,
      auth: {
        ...initialState.auth,
        invite_code: globalState.auth.invite_code,
        visitor_id: globalState.auth.visitor_id,
      },
    });
  }, [globalState.auth]);

  const setupStoreFromStorage = useCallback(async () => {
    try {
      const data = getDataSync(STORAGE_KEY);
      if (data) {
        const parsedData: SerializedStore = JSON.parse(data);
        if (parsedData) {
          // every reducer should listen on this action and assign proper data into the state
          dispatch({
            type: CommonActionTypes.SETUP_STORE,
            payload: parsedData,
          });
        } else {
          dispatch({
            type: CommonActionTypes.RESET_STORE,
          });
        }
      } else {
        resetStorage();
      }
    } catch {
      resetStorage();
    }
  }, [resetStorage]);

  React.useEffect(() => {
    const isDebug = localStorage.getItem('savvi-debug');

    window.savviDebug = isDebug ? true : false;
  }, []);

  const globalStateRef = React.useRef(globalState);
  globalStateRef.current = globalState;

  if (window) {
    window.store = globalState;
    window.dispatch = dispatch;
  }

  return (
    <GlobalContext.Provider
      value={{
        globalState,
        sortedPeopleIds,
        personLabel,
        spouseGenericLabel,
        setupStoreFromStorage,
        initialized,
        getContribution,
        spouseId,
        dispatch: dispatch,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};
