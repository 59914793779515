import { Account, BenefitItem } from '../interfaces/benefits';
import { Goals } from '../interfaces/goals';
import { ActionPlan } from '../interfaces/actionPlan';
import { BudgetingType } from '../interfaces/budgeting';
import { SITE } from '../../site.config';
import { makeRequest } from './common';
import {
  BenefitItemUnion,
  Dictionary,
  EmployerDataResponse,
  Role,
} from 'interfaces/common';
import {
  BundleResponse,
  Person,
  PersonHealthcare,
  PersonIncome,
  TobaccoUse,
} from 'interfaces/household';
import { excludedPlanKeys, excludedBenefitKeys } from 'helpers/benefits';
import { Household } from 'selectsmart-api/sdk/selectsmartSchemas';

export async function getUser() {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/user`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function createSigninToken(last_location?: string | null) {
  if (last_location !== undefined) {
    await updateHousehold({ last_location });
  }
  return await makeRequest<any>({
    url: `${SITE.API_URL}/user/signin-token`,
    method: 'POST',
    body: null,
    includeAuthToken: true,
  });
}

// Developer tools
export async function getEngineDataNextDollar() {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/debug/nextdollar`,
    method: 'GET',
    includeAuthToken: true,
  });
}

// Developer tools
export async function getEngineDataBundles() {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/debug/bundles`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function getData() {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/data`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function getEmployerData() {
  return await makeRequest<EmployerDataResponse>({
    url: `${SITE.API_URL}/selectsmart/employer?plan_year=active`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function getHousehold() {
  return await makeRequest<Household>({
    url: `${SITE.API_URL}/selectsmart/household`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function updateHousehold(household: any) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/household`,
    method: 'PATCH',
    body: household,
    includeAuthToken: true,
  });
}

export async function createPerson(person: Omit<Person, 'id'>) {
  return await makeRequest<Person>({
    url: `${SITE.API_URL}/selectsmart/people`,
    method: 'POST',
    body: person,
    includeAuthToken: true,
  });
}
export async function updatePerson(person: Person) {
  const { id, ...rest } = person;
  return await makeRequest<Person>({
    url: `${SITE.API_URL}/selectsmart/people/${id}`,
    method: 'PATCH',
    body: rest,
    includeAuthToken: true,
  });
}
export async function deletePerson(person_id: string) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/people/${person_id}`,
    method: 'DELETE',
    body: {},
    includeAuthToken: true,
  });
}

export async function updateIncome(
  income: PersonIncome,
  role: Role = Role.CLIENT,
  isNew = false,
) {
  const { person_id, ...rest } = income;
  const is_receiving_social_security =
    rest.annual_pay == 0 ? rest.is_receiving_social_security : false;
  return await makeRequest<PersonIncome>({
    url: `${SITE.API_URL}/selectsmart/incomes/${person_id}`,
    method: isNew ? 'PUT' : 'PATCH',
    body:
      role === Role.CLIENT
        ? {
            annual_pay: rest.annual_pay,
            pay_frequency: rest.pay_frequency,
            pay_schedule: rest.pay_schedule,
            other_income: rest.other_income,
          }
        : {
            annual_pay: rest.annual_pay,
            pay_frequency: rest.pay_frequency,
            is_receiving_social_security: is_receiving_social_security,
            monthly_social_security:
              rest.annual_pay == 0 ? rest.monthly_social_security : null,
            social_security_start_age:
              rest.annual_pay == 0 && !is_receiving_social_security
                ? rest.social_security_start_age
                : null,
          },
    includeAuthToken: true,
  });
}

export async function updateTobaccoUse(tobaccoUse: TobaccoUse, isNew = false) {
  return await makeRequest<TobaccoUse>({
    url: `${SITE.API_URL}/selectsmart/tobacco-use/${tobaccoUse.person_id}`,
    method: isNew ? 'PUT' : 'PATCH',
    body: {
      is_tobacco_user: tobaccoUse.is_tobacco_user || false,
      is_tobacco_cessation: tobaccoUse.is_tobacco_cessation || false,
    },
    includeAuthToken: true,
  });
}

export async function updateHealthcare(healthcare: PersonHealthcare) {
  const excludedKeys = [
    'household_id',
    'person_id',
    'has_typical',
    'updated_at',
    'created_at',
  ];
  const person_id = healthcare.person_id;
  const body = Object.fromEntries(
    Object.entries(healthcare).filter(
      ([key, _]) => excludedKeys.indexOf(key) < 0,
    ),
  );
  return await makeRequest<PersonHealthcare>({
    url: `${SITE.API_URL}/selectsmart/healthcare/${person_id}`,
    method: 'PATCH',
    body,
    includeAuthToken: true,
  });
}

export async function requestBundles(body: any = {}) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/bundles`,
    method: 'POST',
    body,
    includeAuthToken: true,
  });
}

export async function getBundles(bundles_id: string) {
  return await makeRequest<BundleResponse>({
    url: `${SITE.API_URL}/selectsmart/bundles/${bundles_id}`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function selectBundle(bundles_id: string, bundle_key: string) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/bundles/${bundles_id}/${bundle_key}`,
    method: 'POST',
    body: {},
    includeAuthToken: true,
  });
}

export async function getAccounts() {
  const results = await makeRequest<Dictionary<Account>>({
    url: `${SITE.API_URL}/selectsmart/accounts`,
    method: 'GET',
    includeAuthToken: true,
  });

  return results;
}

export async function updateAccounts(id: string, inputBody: any) {
  const excludedKeys = ['benefit_id'];
  const body = Object.fromEntries(
    Object.entries(inputBody).filter(
      ([key, _]) => excludedKeys.indexOf(key) < 0,
    ),
  );
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/accounts/${id}`,
    method: 'PATCH',
    body,
    includeAuthToken: true,
  });
}

export async function refreshGoals() {
  return await makeRequest({
    url: `${SITE.API_URL}/selectsmart/goals`,
    method: 'PATCH',
    includeAuthToken: true,
    body: {},
  });
}

export async function getGoals() {
  return await makeRequest<Goals>({
    url: `${SITE.API_URL}/selectsmart/goals`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function updateGoal(type: string, inputBody: any) {
  const excludedKeys = ['updated_at', 'household_id', 'created_at'];
  const body = Object.fromEntries(
    Object.entries(inputBody).filter(
      ([key, _]) => excludedKeys.indexOf(key) < 0,
    ),
  );
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/goals/${type}`,
    method: 'PATCH',
    body,
    includeAuthToken: true,
  });
}

export async function getPlans() {
  return await makeRequest<Dictionary<BenefitItem>>({
    url: `${SITE.API_URL}/selectsmart/plans`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function createPlans(body: any) {
  return await makeRequest<BenefitItem>({
    url: `${SITE.API_URL}/selectsmart/plans`,
    method: 'POST',
    body,
    includeAuthToken: true,
  });
}

export async function savePlan(id: string, body: any) {
  return await makeRequest<BenefitItem>({
    url: `${SITE.API_URL}/selectsmart/plans/${id}`,
    method: 'PATCH',
    body: Object.fromEntries(
      Object.entries(body).filter(
        ([key, _]) => excludedPlanKeys(body.type).indexOf(key) < 0,
      ),
    ),
    includeAuthToken: true,
  });
}

export async function deletePlan(id: string) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/plans/${id}`,
    method: 'DELETE',
    body: {},
    includeAuthToken: true,
  });
}

export async function getBenefits() {
  return await makeRequest<Dictionary<BenefitItemUnion>>({
    url: `${SITE.API_URL}/selectsmart/benefits`,
    method: 'GET',
    includeAuthToken: true,
  });
}

// TODO: Remove. Replaced by selectsmart-api/api.ts
//export async function getBenefitTypes() {
//return await makeRequest<Dictionary<BenefitItemUnion>>({
//url: `${SITE.API_URL}/selectsmart/benefit-types`,
//method: 'GET',
//includeAuthToken: true,
//});
//}

export async function createBenefit(body: any) {
  return await makeRequest<Partial<BenefitItemUnion>>({
    url: `${SITE.API_URL}/selectsmart/benefits`,
    method: 'POST',
    body: Object.fromEntries(
      Object.entries(body).filter(
        ([key, _]) => excludedBenefitKeys(body.type).indexOf(key) < 0,
      ),
    ),
    includeAuthToken: true,
  });
}

export async function saveBenefit(id: string, body: any) {
  return await makeRequest<Partial<BenefitItemUnion>>({
    url: `${SITE.API_URL}/selectsmart/benefits/${id}`,
    method: 'PATCH',
    body: Object.fromEntries(
      Object.entries(body).filter(
        ([key, _]) => excludedBenefitKeys(body.type).indexOf(key) < 0,
      ),
    ),
    includeAuthToken: true,
  });
}

export async function deleteBenefit(id: string) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/benefits/${id}`,
    method: 'DELETE',
    body: {},
    includeAuthToken: true,
  });
}

export async function requestNextDollar(body: any = {}) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/nextdollar`,
    method: 'POST',
    body,
    includeAuthToken: true,
  });
}

export async function getNextDollar(id: string) {
  return await makeRequest<BudgetingType>({
    url: `${SITE.API_URL}/selectsmart/nextdollar/${id}`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function getPreviousNextDollar() {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/nextdollar`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function updateNextDollar(id: string, budget: number) {
  return await makeRequest<BudgetingType>({
    url: `${SITE.API_URL}/selectsmart/nextdollar/${id}`,
    body: { budget },
    method: 'PATCH',
    includeAuthToken: true,
  });
}

export async function getUsageLevels() {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/assumptions/usage-levels`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function requestActionPlan(body: any) {
  return await makeRequest<ActionPlan>({
    url: `${SITE.API_URL}/selectsmart/actionplan`,
    body,
    method: 'PATCH',
    includeAuthToken: true,
  });
}

export async function getActionPlan() {
  return await makeRequest<ActionPlan>({
    url: `${SITE.API_URL}/selectsmart/actionplan`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function sendActionPlanEmail() {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/actionplan/send-email`,
    method: 'PATCH',
    body: {},
    includeAuthToken: true,
  });
}

export async function setActionGroupCompletion(
  actionplan_id: string,
  actions_group: string,
  isCompleted: boolean,
) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/actionplans/${actionplan_id}/${actions_group}`,
    body: { is_completed: isCompleted },
    method: 'PATCH',
    includeAuthToken: true,
  });
}

export async function integrations() {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/integrations`,
    body: {},
    method: 'POST',
    includeAuthToken: true,
  });
}

export async function voyaIntegrations(body: any) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/integrations/voya/contribution-change`,
    body,
    method: 'POST',
    includeAuthToken: true,
  });
}

export async function voyaEnrollNudge(body: any) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/integrations/voya/enroll-nudge`,
    body,
    method: 'POST',
    includeAuthToken: true,
  });
}

export async function getEngineData() {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/engine-inputs`,
    method: 'GET',
    includeAuthToken: true,
  });
}

export async function setBundleLifeInsurance(body: any) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/bundles/${body.bundles_id}/life-insurance`,
    body: body.plans,
    method: 'PUT',
    includeAuthToken: true,
  });
}

export async function setBundleADDInsurance(body: any) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/bundles/${body.bundles_id}/add-insurance`,
    body: body.plans,
    method: 'PUT',
    includeAuthToken: true,
  });
}

export async function setBundleDisabilityInsurance(body: any) {
  return await makeRequest<any>({
    url: `${SITE.API_URL}/selectsmart/bundles/${body.bundles_id}/disability-insurance`,
    body: body.plans,
    method: 'PUT',
    includeAuthToken: true,
  });
}
