import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { fromPairs } from 'lodash';
import { createSearchParams, generatePath, matchPath } from 'react-router-dom';

export enum RouteNames {
  Go = 'go',
  SignUp = 'signup',
  SignIn = 'signin',
  Home = 'home',
  Welcome = 'welcome',
  Household = 'household-profile',
  ProfileEmployment = 'employment-profile',
  ProfileIncome = 'income-profile', // rename to household-income
  HealthcareUsage = 'average-usage', // rename to healthcare-typical
  HealthcareYTD = 'healthcare-ytd',
  HealthcareDTY = 'healthcare-dty',
  HealthcareSpecial = 'special-usage', // rename to healthcare-special
  HealthcareSupplementalBenefits = 'supplemental-benefits',
  TobaccoUsage = 'tobacco-usage',
  BenefitsProfile = 'benefits-profile',
  BenefitsSpouse = 'spousal-benefits',
  Submit = 'submit',
  Results = 'results',
  PlanDetails = 'bundle-details',
  HowToUseYourHSA = 'how-to-use-your-hsa',
  EmergencyFund = 'emergency-fund',
  ClientRetirement = 'retirement-accounts',
  SpouseRetirement = 'retirement-accounts/spouse',
  RetirementOtherAccounts = 'retirement-other-accounts',
  RetirementGoals = 'retirement-goals',
  Review = 'review',
  Budgeting = 'budgeting',
  ActionPlan = 'action-plan',
  EngineData = 'engine-data',
  Terms = 'terms',
  EnumeratedContentReview = 'enumerated-content-review',
  MidyearPlanDetails = 'midyear-benefits',
  WhatBringsYouHere = 'guidance-mode',
  NotTheToolForYou = 'not-the-tool-for-you',
  CoverageLife = 'coverage-life',
  CoverageDisability = 'coverage-disability',
  CoverageADD = 'coverage-add',
  BundleSummary = 'guidance-results',
}

// Map of route names to route configurations
export const routes: { [key: string]: string } = {
  // Most are simply in the format /${route}
  ...fromPairs(Object.values(RouteNames).map((route) => [route, `/${route}`])),
  // Override specifics
  [RouteNames.Go]: `/${RouteNames.Go}/:token`,
  [RouteNames.SignUp]: `/${RouteNames.SignUp}/:token`,
  [RouteNames.SignIn]: `/${RouteNames.SignIn}/:token`,
  [RouteNames.BundleSummary]: `/${RouteNames.BundleSummary}/:bundlesid/:bundlekey`,
};

export const routePath = (
  name: RouteNames,
  pathParameters?: any,
  queryParameters?: any,
) => {
  const basePath = generatePath(
    routes[name] ?? routes[RouteNames.Welcome],
    pathParameters || {},
  );
  return queryParameters
    ? `${basePath}?${createSearchParams(queryParameters)}`
    : basePath;
};

// All routes are just the first part of the path
export const routeNameFromPath = (path: string) =>
  Object.entries(routes).find(([_, v]) => matchPath(path, v))?.[0];

export enum NavigationSteps {
  PROFILE = 'Profile',
  HEALTHCARE = 'Healthcare',
  PROTECTION = 'Protection',
  RETIREMENT = 'Retirement',
  BUDGETING = 'Budgeting',
  ACTION_PLAN = 'Action Plan',
  RESULTS = 'Results',
}

export const navigationStepLabels: { [key: string]: MessageDescriptor } = {
  [NavigationSteps.PROFILE]: msg`Profile`,
  [NavigationSteps.HEALTHCARE]: msg`Healthcare`,
  [NavigationSteps.PROTECTION]: msg`Protection`,
  [NavigationSteps.RETIREMENT]: msg`Retirement`,
  [NavigationSteps.BUDGETING]: msg`Budgeting`,
  [NavigationSteps.ACTION_PLAN]: msg`Action Plan`,
  [NavigationSteps.RESULTS]: msg`Results`,
};
